import { getContinentByCountry } from "../survey/surveyDetails/components/helperData";

const baseValueByContinent = {
  Europe: {
    B2C: 4.14,
    B2B: 17.76,
  },
  NorthAmerica: {
    B2C: 3.68,
    B2B: 14.72,
  },
  SouthAmerica: {
    B2C: 3.6,
    B2B: 14.4,
  },
  Africa: {
    B2C: 3.22,
    B2B: 12.88,
  },
  APAC: {
    B2C: 3.72,
    B2B: 14.88,
  },
  MENA: {
    B2C: 4.25,
    B2B: 17,
  },
  Antarctica: {
    B2C: 5.8,
    B2B: 20,
  },
};

export function calculateValue(country, loi, ir, type = 1) {
  const baseIR = 50;
  const baseLOI = 15;
  ir = Math.ceil(ir / 5) * 5;
  loi = Math.ceil(loi / 5) * 5;
  let continent = getContinentByCountry(country);

  let baseValue =
    baseValueByContinent[continent][type == 1 ? "B2C" : "B2B"] || 3;
  //   console.log({ country, loi, ir, type });
  //   type 1 = B2C, type 2=B2B
  const incrementsAbove = {
    45: type == 1 ? 1.0 : 3.0,
    40: type == 1 ? 1.2 : 3.6,
    35: type == 1 ? 1.4 : 4.2,
    30: type == 1 ? 1.6 : 4.8,
    25: type == 1 ? 1.8 : 5.4,
    20: type == 1 ? 2.0 : 6.0,
    15: type == 1 ? 2.2 : 6.6,
    10: type == 1 ? 2.4 : 7.2,
    5: type == 1 ? 2.6 : 7.8,
  };

  const decrementsBelow = {
    55: type == 1 ? 1.0 : 3.0,
    60: type == 1 ? 1.2 : 3.6,
    65: type == 1 ? 1.4 : 4.2,
    70: type == 1 ? 1.6 : 4.8,
    75: type == 1 ? 1.8 : 5.4,
    80: type == 1 ? 2.0 : 6.0,
    85: type == 1 ? 2.2 : 6.6,
    90: type == 1 ? 2.4 : 7.2,
    95: type == 1 ? 2.6 : 7.8,
    100: type == 1 ? 2.8 : 8.4,
  };

  let value;
  const horizontalMultiplier = 5;
  if (ir === baseIR && loi === baseLOI) {
    value = baseValue;
  } else if (loi === baseLOI) {
    if (ir < baseIR) {
      value = baseValue;
      for (let currentIR = baseIR - 5; currentIR >= ir; currentIR -= 5) {
        value += (incrementsAbove[currentIR] / 100) * value;
        value = parseFloat(value.toFixed(4));
      }
    } else {
      value = baseValue;
      for (let currentIR = baseIR + 5; currentIR <= ir; currentIR += 5) {
        value -= (decrementsBelow[currentIR] / 100) * value;
        value = parseFloat(value.toFixed(4));
      }
    }
  } else {
    let value15Min;
    if (ir < baseIR) {
      value15Min = baseValue;
      for (let currentIR = baseIR - 5; currentIR >= ir; currentIR -= 5) {
        value15Min += (incrementsAbove[currentIR] / 100) * value15Min;
        value15Min = parseFloat(value15Min.toFixed(4));
      }
    } else if (ir > baseIR) {
      value15Min = baseValue;
      for (let currentIR = baseIR + 5; currentIR <= ir; currentIR += 5) {
        value15Min -= (decrementsBelow[currentIR] / 100) * value15Min;
        value15Min = parseFloat(value15Min.toFixed(4));
      }
    } else {
      value15Min = baseValue;
    }

    if (loi > baseLOI) {
      value = value15Min;
      for (let currentLOI = baseLOI + 5; currentLOI <= loi; currentLOI += 5) {
        value += (horizontalMultiplier / 100) * value;
        value = parseFloat(value.toFixed(4));
      }
    } else {
      let value10Min = value15Min - (horizontalMultiplier / 100) * value15Min;
      value10Min = parseFloat(value10Min.toFixed(4));

      let value5Min = value10Min - (horizontalMultiplier / 100) * value10Min;
      value5Min = parseFloat(value5Min.toFixed(4));

      if (loi === 10) {
        value = value10Min;
      } else if (loi === 5) {
        value = value5Min;
      }
    }
  }
  return parseFloat(value.toFixed(2));
}

// Example usage:
// console.log(calculateValue("United States", 15, 100, 2));
