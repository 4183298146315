import React, { useEffect, useState } from "react";
import ProgressBar from "../components/ProgressBar";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
// import Hashids from "hashids";
import LoaderBTN from "../components/LoaderBTN";
import { BASE_URL } from "../Utlils";
// const hashids = new Hashids("My Project");
const GDPR = () => {
  const { sId, supId, orgId } = useParams();
  const [spinnerLoader, setSpinnerLoader] = useState(false);
  const [survey, setSurvey] = useState({});
  const { encryptedID } = useParams();

  const gamma = localStorage.getItem("gamma");
  let sessionId = localStorage.getItem("session_id");
  let ref_id = localStorage.getItem("ref_id");
  const getCurrentSurvey = async (surveyId) => {
    let data = await axios.get(`${BASE_URL}/survey/one?id=${surveyId}`);
    setSurvey(data.data);
  };
  useEffect(() => {
    getCurrentSurvey(sId);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpinnerLoader(true);
    await axios
      .put(`${BASE_URL}/session?id=${sessionId}`, {
        GDPRConsent: true,
        client_status: -1,
        mirats_status: 1,
      })
      .then((data) => {
        // alert("redirect to Qualification");
        window.location.href = `${window.location.origin}/DIY/${sId}/${supId}/${orgId}/questions`;
        setSpinnerLoader(false);
        // navigate(`/blaze/${encryptedID}/questions`);
      })
      .catch((er) => console.log(er));
  };
  const handleCancel = async (e) => {
    // console.log("gdpr fail");
    e.preventDefault();
    await axios
      .put(`${BASE_URL}/session?id=${sessionId}`, {
        mirats_status: 236,
        client_status: -1,
      })
      .then((data) => {
        // alert("redirect to home page");
        window.location.href = `${
          window.location.origin
        }/7e08091a73b14e034889265e41ba796f91c766ad/${encodeURIComponent(
          ref_id
        )}/20`;
        // navigate("/survey/end/${ref_id}/20");
      })
      .catch((er) => console.log(er));
  };

  return (
    <div className="h-[100dvh] ipad:overflow-y-auto">
      <ProgressBar progress={20} />
      <div className="h-[95%] ipad:text-[12px] ipad:overflow-y-auto">
        <div className="flex justify-center items-center">
          <section className="w-[718px] mx-auto my-[45px]  ipad:my-4 ipad:mx-6">
            <img
              src="https://esomar.mirats.in/unnamed.png"
              alt="logo"
              className="w-[160px]"
            />
            <h1 className="mt-[33px] mb-[18px] ipad:mb-2">
              Greetings ! A warm Namaste to you !
            </h1>
            <p className="text-[13px] font-[300] mb-[18px] ipad:mb-2">
              You've been handpicked for a special mission by us, a renowned
              market research entity. Our quest? To understand your world a
              little better! Your task? To spare a few{" "}
              <span className="text-[#0226be] font-[600]">
                {" "}
                {survey?.expectedMatrix?.expectedCompletionLOI
                  ? parseInt(survey?.expectedMatrix?.expectedCompletionLOI)
                  : 0}{" "}
                minutes
              </span>{" "}
              of your time.
            </p>
            <p className="text-[13px] font-[300] mb-[18px] ipad:mb-2">
              You'll embark on this once-only journey, sharing your views
              without revealing your identity. And yes, some questions with a
              star *) aren't just for decor, they're mandatory!
            </p>
            <p className="text-[13px] font-[300] mb-[18px] ipad:mb-2">
              As explorers of information, we'll be collecting some data: your
              IP address, Cookie IDs, device IDs, and tidbits about your
              lifestyle and preferences.
            </p>
            <p className="text-[13px] font-[300] mb-[18px] ipad:mb-2">
              Before we set sail, we need you to agree to our map:
            </p>
            <ul className="text-[13px] list-disc ml-4 font-[300] mb-[18px] ipad:mb-2">
              <li className="mb-1">
                <a
                  href="https://consumerinsights.miratsoneservices.com/privacy-policy"
                  className="text-primary underline"
                >
                  Privacy Policy
                </a>{" "}
                <span className="font-[300]">
                  {" "}
                  - Your data, our treasure. handled with utmost care.
                </span>
              </li>
              <li className="mb-1">
                <a
                  href="https://consumerinsights.miratsoneservices.com/terms-of-service"
                  className="text-primary underline"
                >
                  Terms of Use
                </a>{" "}
                <span> - Your compass to navigate this survey.</span>
              </li>
              <li className="mb-1">
                <a href="#" className="text-primary underline">
                  Cookie Policy
                </a>{" "}
                <span> - The tracks we follow.</span>
              </li>
            </ul>

            <p className="text-[13px] font-[300] mb-[18px] ipad:mb-2">
              By agreeing, you accept that your data will take a secure trip to
              our servers in the US, India, and the UK.
            </p>
            <p className="text-[13px] font-[300] mb-[18px] ipad:mb-2">
              Got questions? Feel free to send an owl, or just email your panel
              administrator.
            </p>
            <p className="text-[13px] font-[300] mb-[18px] ipad:mb-2">
              Your contribution will add a star to our sky. Thank you!
            </p>
            <div className="pt-[40px] ipad:pt-3 ipad:pb-2 flex gap-4 items-center">
              {spinnerLoader ? (
                <LoaderBTN />
              ) : (
                <div
                  onClick={handleSubmit}
                  className="w-[118px] text-center bg-[#0226BE] px-4 py-3 text-white text-[12px] font-[600] rounded border border-transparent ipad:w-[90px] ipad:py-2 cursor-pointer "
                >
                  Agree
                </div>
              )}
              <div
                onClick={handleCancel}
                className="w-[118px] text-center bg-white px-4 py-3 text-primary border border-primary text-[12px] font-[600] rounded ipad:w-[90px] ipad:py-2 cursor-pointer"
              >
                Disagree
              </div>
            </div>
          </section>
        </div>
      </div>
      <footer className="p-[17px_16px_17px_16px] mt-5 flex items-center justify-between bg-[#F3F3F3] w-[100%] text-[#333] absolute bottom-0">
        <p className="text-[13px] ipad:text-[6px]">
          ©2023 Mirats Quanto Private Limited.
        </p>
        <ul className="text-[12px] ipad:text-[6px] list-none font-[300] flex items-center gap-[32px] ipad:gap-1">
          <li>
            <a
              href="https://consumerinsights.miratsoneservices.com/privacy-policy"
              className=""
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              href="https://consumerinsights.miratsoneservices.com/gdpr"
              className=""
            >
              GDPR Policy
            </a>
          </li>
          <li>
            <a
              href="https://consumerinsights.miratsoneservices.com/ccpa"
              className=""
            >
              CCPA Policy
            </a>
          </li>
          <li>
            <a
              href="https://consumerinsights.miratsoneservices.com/terms-of-service"
              className=""
            >
              Terms of Use
            </a>
          </li>
        </ul>
      </footer>
    </div>
  );
};

export default GDPR;
