import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useGlobalContext } from "../../../Contexts/GlobalContext";
import axios from "axios";
import { BASE_URL } from "../../../Utlils";
import Required from "../../../components/Required";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
};
const Finance = ({ user, setUser }) => {
  const {
    taxIdTypes,
    currencies,
    organization,
    setSnackbar,
    getCountries,
    getAllCountryStates,
    getAllCities,
    getOrganization,
  } = useGlobalContext();
  const [inputData, setInputData] = useState({});
  const [addressType, setAddressType] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [countryState, setCountryState] = useState({
    country: [],
    countryLoading: true,
    states: [],
    statsLoading: false,
    cities: [],
    cityLoading: false,
  });
  const handleAddressChange = (e, type) => {
    let { name, value } = e.target;
    setInputData((prev) => ({
      ...prev,
      addresses: {
        ...prev?.addresses,
        [type]: {
          ...prev?.addresses?.[type],
          [name]: value,
        },
      },
    }));
  };
  const handleAddressesSubmit = async (e, type) => {
    try {
      await axios.put(`${BASE_URL}/company/${organization?.id}`, {
        ...organization,
        addresses: {
          ...organization?.addresses,
          [type]: inputData?.addresses?.[type],
        },
      });
      setSnackbar({
        open: true,
        msg: `${
          type == "billingAddress" ? "Billing " : "Shipping"
        } address updated successfully !`,
        severity: "success",
      });
      setModalOpen(false);
      getOrganization(organization?.id);
    } catch (error) {
      setSnackbar({
        open: true,
        msg: "Something went wrong !",
        severity: "error",
      });
      setModalOpen(false);
    }
  };
  const renderAddressComp = (type = 0) => {
    let addressType = type == 0 ? "billingAddress" : "shippingAddress";
    return (
      <form
        className=" w-full   h-[550px] md:h-[530px]  p-[15px_25px] space-y-6 flex flex-col  "
        onSubmit={(e) => {
          e.preventDefault();
          handleAddressesSubmit(e, addressType);
        }}
      >
        <h1 className="text-[24px] font-[500] text-[#333]">
          {type == 0 ? "Billing Address" : "Shipping Address"}
        </h1>
        <div className="space-y-4  h-[75%] overflow-y-auto containerScroll ">
          <div className="space-y-2 flex flex-col">
            <label
              htmlFor="street1"
              className="text-[#333] text-[15px] font-[500]"
            >
              Street 1 <Required />
            </label>
            <input
              type="text"
              required
              onChange={(e) => handleAddressChange(e, addressType)}
              value={inputData?.addresses?.[addressType]?.street1}
              name="street1"
              disabled={!user?.role === "admin"}
              style={
                user?.role === "admin"
                  ? { cursor: "auto" }
                  : { cursor: "not-allowed" }
              }
              className="border border-[#333] py-[0.6rem] px-4 rounded-[8px] text-[#333] font-[500] capitalize text-[14px]"
            />
          </div>
          <div className="space-y-2 flex flex-col">
            <label
              htmlFor="street2"
              className="text-[#333] text-[15px] font-[500]"
            >
              Street 2 <Required />
            </label>
            <input
              type="text"
              required
              onChange={(e) => handleAddressChange(e, addressType)}
              value={inputData?.addresses?.[addressType]?.street2}
              name="street2"
              disabled={!user?.role === "admin"}
              style={
                user?.role === "admin"
                  ? { cursor: "auto" }
                  : { cursor: "not-allowed" }
              }
              className="border border-[#333] py-[0.6rem] px-4 rounded-[8px] text-[#333] font-[500] capitalize text-[14px]"
            />
          </div>
          <div className="grid grid-cols-2 gap-4 ">
            <div className="space-y-2 flex flex-col">
              <label
                htmlFor="Country"
                className="text-[#333] text-[15px] font-[500]"
              >
                Country <Required />
              </label>
              <select
                name="country"
                id="Country"
                required
                disabled={!user?.role === "admin"}
                style={
                  user?.role === "admin"
                    ? { cursor: "auto" }
                    : { cursor: "not-allowed" }
                }
                value={inputData?.addresses?.[addressType]?.countryCode}
                onChange={(e) => {
                  if (e.target.value !== "") {
                    setCountryState((prev) => ({
                      ...prev,
                      city: [],
                      state: [],
                    }));
                    setInputData((prev) => ({
                      ...prev,
                      addresses: {
                        ...prev?.addresses,
                        [addressType]: {
                          ...prev?.addresses?.[addressType],
                          country:
                            e.target.options[e.target.selectedIndex].text,
                          countryCode: e.target.value,
                          state: "",
                          stateCode: "",
                          city: "",
                          zipCode: "",
                        },
                      },
                    }));
                    getAllCountryStates(
                      e.target.value,
                      inputData?.addresses?.[addressType]?.countryCode
                    );
                  }
                }}
                className="border border-[#333] py-[0.6rem] px-4 rounded-[8px] text-[#333] font-[500] capitalize text-[14px]"
              >
                <option value="">Select Country</option>
                {countryState?.countryLoading ? (
                  <option value="" disabled>
                    Loading Country..
                  </option>
                ) : (
                  countryState?.country?.map((d, i) => (
                    <option value={d?.iso2} key={i}>
                      {d?.name}
                    </option>
                  ))
                )}
              </select>
            </div>
            <div className="space-y-2 flex flex-col">
              <label
                htmlFor="state"
                className="text-[#333] text-[15px] font-[500]"
              >
                State <Required />
              </label>
              <select
                name="state"
                id="state"
                disabled={!user?.role === "admin"}
                style={
                  user?.role === "admin"
                    ? { cursor: "auto" }
                    : { cursor: "not-allowed" }
                }
                value={inputData?.addresses?.[addressType]?.stateCode}
                required
                onChange={(e) => {
                  if (e.target.value !== "") {
                    setInputData((prev) => ({
                      ...prev,
                      addresses: {
                        ...prev?.addresses,
                        [addressType]: {
                          ...prev?.addresses?.[addressType],
                          state: e.target.options[e.target.selectedIndex].text,
                          stateCode: e.target.value,
                          city: "",
                          zipCode: "",
                        },
                      },
                    }));
                    getAllCities(e.target.value);
                  }
                }}
                className="border border-[#333] py-[0.6rem] px-4 rounded-[8px] text-[#333] font-[500] capitalize text-[14px]"
              >
                <option value="">Select State</option>
                {countryState.statsLoading ? (
                  <option value="" disabled>
                    Loading States..
                  </option>
                ) : countryState?.states?.length ? (
                  countryState?.states?.map((d, i) => (
                    <option value={d?.iso2} key={i}>
                      {d?.name}
                    </option>
                  ))
                ) : (
                  <option value="" disabled>
                    No States found..
                  </option>
                )}
              </select>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 ">
            <div className="space-y-2 flex flex-col">
              <label
                htmlFor="city"
                className="text-[#333] text-[15px] font-[500]"
              >
                City <Required />
              </label>
              <select
                name="city"
                id="city"
                required
                disabled={!user?.role === "admin"}
                style={
                  user?.role === "admin"
                    ? { cursor: "auto" }
                    : { cursor: "not-allowed" }
                }
                value={inputData?.addresses?.[addressType]?.city}
                onChange={(e) => {
                  if (e.target.value !== "") {
                    setInputData((prev) => ({
                      ...prev,
                      addresses: {
                        ...prev?.addresses,
                        [addressType]: {
                          ...prev?.addresses?.[addressType],
                          city: e.target.options[e.target.selectedIndex].text,
                        },
                      },
                    }));
                  }
                }}
                className="border border-[#333] py-[0.6rem] px-4 rounded-[8px] text-[#333] font-[500] capitalize text-[14px]"
              >
                <option value="">Select City</option>
                {countryState?.cityLoading ? (
                  <option value="" disabled>
                    Loading Cities..
                  </option>
                ) : countryState?.cities?.length ? (
                  countryState?.cities?.map((d, i) => (
                    <option value={d?.name} key={i}>
                      {d?.name}
                    </option>
                  ))
                ) : (
                  <option value="" disabled>
                    No City found..
                  </option>
                )}
              </select>
            </div>
            <div className="space-y-2 flex flex-col">
              <label
                htmlFor="zipCode"
                className="text-[#333] text-[15px] font-[500]"
              >
                Zip Code <Required />
              </label>
              <input
                type="text"
                required
                name="zipCode"
                disabled={!user?.role === "admin"}
                style={
                  user?.role === "admin"
                    ? { cursor: "auto" }
                    : { cursor: "not-allowed" }
                }
                onChange={(e) => handleAddressChange(e, addressType)}
                value={inputData?.addresses?.[addressType]?.zipCode}
                // onChange={handleChange}
                className="border border-[#333] py-[0.6rem] px-4 rounded-[8px] text-[#333] font-[500] capitalize text-[14px]"
              />
            </div>
          </div>
          {type == 1 ? (
            <div className="flex items-center gap-[10px] py-2 ">
              <input
                type="checkbox"
                name="sameAsBilling"
                id="sameAsBilling"
                className="w-[18px] h-[18px]"
                disabled={!user?.role === "admin"}
                style={
                  user?.role === "admin"
                    ? { cursor: "auto" }
                    : { cursor: "not-allowed" }
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    setInputData((prev) => ({
                      ...prev,
                      addresses: {
                        ...prev?.addresses,
                        shippingAddress: inputData?.addresses?.billingAddress,
                      },
                    }));
                  } else {
                    setInputData((prev) => ({
                      ...prev,
                      addresses: {
                        ...prev?.addresses,
                        shippingAddress: {
                          countryCode: "",
                          state: "",
                          country: "",
                          city: "",
                          street1: "",
                          street2: "",
                          stateCode: "",
                          zipCode: "",
                        },
                      },
                    }));
                  }
                  console.log(e.target.checked);
                }}
              />
              <label
                htmlFor="sameAsBilling"
                disabled={!user?.role === "admin"}
                style={
                  user?.role === "admin"
                    ? { cursor: "auto" }
                    : { cursor: "not-allowed" }
                }
                className="text-[#666] text-[15px] font-[500]"
              >
                Same as billing address
              </label>
            </div>
          ) : (
            ""
          )}
        </div>
        <footer className="w-full  flex items-center justify-end">
          <button
            type="submit"
            disabled={!user?.role === "admin"}
            style={
              user?.role === "admin"
                ? { cursor: "auto" }
                : { cursor: "not-allowed" }
            }
            className="bg-primary p-[8px_20px] rounded-lg text-[#fff]"
          >
            Submit
          </button>
        </footer>
      </form>
    );
  };
  const handleFinanceData = (e) => {
    let { name, value } = e.target;
    setInputData((prev) => ({
      ...prev,
      finance: { ...prev?.finance, [name]: value },
    }));
  };
  const handleFinanceSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${BASE_URL}/company/${organization?.id}`, {
        finance: inputData?.finance,
      });
      setSnackbar({
        open: true,
        msg: "Finance details updated successfully !",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        msg: "Something went wrong !",
        severity: "error",
      });
    }
  };
  const getCountry = async () => {
    let { countryCode = "", stateCode = "" } =
      inputData?.addresses?.billingAddress || {};
    try {
      let data = await getCountries();
      setCountryState((prev) => ({
        ...prev,
        country: data,
        countryLoading: false,
        statsLoading: true,
      }));
      if (countryCode) {
        let states = await getAllCountryStates(countryCode);
        setCountryState((prev) => ({
          ...prev,
          states: states,
          statsLoading: false,
          cityLoading: true,
        }));
      }
      if (stateCode) {
        let cities = await getAllCities(countryCode, stateCode);
        setCountryState((prev) => ({
          ...prev,
          cities: cities,
          cityLoading: false,
        }));
      }
    } catch (error) {
      setCountryState((prev) => ({
        ...prev,
        countryLoading: false,
        cityLoading: false,
        statsLoading: false,
      }));
    }
  };

  useEffect(() => {
    setInputData(organization);
  }, [Object?.keys(organization)?.length]);
  useEffect(() => {
    getCountry();
  }, [
    inputData?.addresses?.billingAddress?.countryCode,
    inputData?.addresses?.billingAddress?.stateCode,
  ]);
  // console.log("organization=>", organization);
  // console.log("inputData=>", inputData);
  // console.log("countryState=>", countryState);

  return (
    <div className="w-full flex flex-col gap-[15px] h-full py-5">
      <div className="pb-10 flex items-center justify-between  border-b border-[#E0E0E0] ">
        <div className="flex flex-col gap-[15px] justify-center">
          <h1 className="text-[#333333] text-[28px] font-[500]">
            Finance Information
          </h1>
          <p>This address will be used for invoice generation</p>
        </div>
      </div>
      <div className=" w-full pb-5 space-y-3 border-b border-[#E0E0E0]">
        <div className="flex items-center justify-between py-5 px-4 rounded-lg border">
          <div className="space-y-2">
            <h1 className="font-[500] ">Companies Billing Address :</h1>
            <section className="text-[14px]">
              <p> {inputData?.name || "-"}</p>

              <p>{inputData?.addresses?.billingAddress?.street1 || "-"} </p>
              <p>{inputData?.addresses?.billingAddress?.street2 || "-"} </p>
              <p>
                {inputData?.addresses?.billingAddress?.city}{" "}
                {inputData?.addresses?.billingAddress?.state}{" "}
                {inputData?.addresses?.billingAddress?.zipCode}
              </p>
            </section>
          </div>
          <div>
            <button
              type="button"
              onClick={(e) => {
                setAddressType(0);
                setModalOpen(true);
              }}
              className="border-2 py-2 px-4 rounded-lg"
            >
              View
            </button>
          </div>
        </div>
        <div className="flex items-center justify-between py-5 px-4 rounded-lg border">
          <div className="space-y-2">
            <h1 className="font-[500] ">Companies Shipping Address :</h1>
            <section className="text-[14px]">
              <p> {inputData?.name || "-"}</p>

              <p>{inputData?.addresses?.shippingAddress?.street1 || "-"} </p>
              <p>{inputData?.addresses?.shippingAddress?.street2 || "-"} </p>
              <p>
                {inputData?.addresses?.shippingAddress?.city}{" "}
                {inputData?.addresses?.shippingAddress?.state}{" "}
                {inputData?.addresses?.shippingAddress?.zipCode}
              </p>
            </section>
          </div>
          <div>
            <button
              type="button"
              onClick={(e) => {
                setAddressType(1);
                setModalOpen(true);
              }}
              className="border-2 py-2 px-4 rounded-lg"
            >
              View
            </button>
          </div>
        </div>
      </div>
      <form
        className="py-5 px-4 rounded-lg border  space-y-5"
        onSubmit={handleFinanceSubmit}
      >
        <div className="grid grid-cols-4 gap-[16px]">
          <div className="flex flex-col w-full gap-[10px]">
            <label htmlFor="taxIDType" className="text-[14px] font-[600]">
              Tax ID Types
            </label>
            <select
              name="taxIDType"
              id="taxIDType"
              onChange={handleFinanceData}
              value={inputData?.finance?.taxIDType}
              required
              className="border py-2 px-2 rounded-[8px] text-[#666] text-[12px]"
              disabled={!user?.role === "admin"}
              style={
                user?.role === "admin"
                  ? { cursor: "auto" }
                  : { cursor: "not-allowed" }
              }
            >
              <option value="">Select Tax ID Types</option>
              {taxIdTypes?.map((d, i) => (
                <option value={d} key={i}>
                  {d}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col w-full gap-[10px]">
            <label htmlFor="taxID" className="text-[14px] font-[600]">
              Tax ID
            </label>
            <input
              type="text"
              name="taxID"
              onChange={handleFinanceData}
              value={inputData?.finance?.taxID}
              disabled={!user?.role === "admin"}
              style={
                user?.role === "admin"
                  ? { cursor: "auto" }
                  : { cursor: "not-allowed" }
              }
              required
              className="border py-2 px-4 rounded-[8px] text-[#666] text-[12px]"
            />
          </div>
          <div className="flex flex-col w-full gap-[10px]">
            <label htmlFor="timeZone" className="text-[14px] font-[600]">
              Time Zone
            </label>
            <select
              name="timeZone"
              id="timeZone"
              className="border py-2 px-4 rounded-[8px] text-[#666] text-[12px]"
              disabled={!user?.role === "admin"}
              onChange={handleFinanceData}
              value={inputData?.finance?.timeZone}
              required
              style={
                user?.role === "admin"
                  ? { cursor: "auto" }
                  : { cursor: "not-allowed" }
              }
            >
              <option value="">Select Time Zone</option>
              {Intl.supportedValuesOf("timeZone")?.map((d, i) => (
                <option value={d} key={i}>
                  {d}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col w-full gap-[10px]">
            <label htmlFor="currency" className="text-[14px] font-[600]">
              Currency
            </label>
            <select
              name="currency"
              id="currency"
              onChange={handleFinanceData}
              value={inputData?.finance?.currency}
              required
              className="border py-2 px-2 rounded-[8px] text-[#666] text-[12px]"
              disabled={!user?.role === "admin"}
              style={
                user?.role === "admin"
                  ? { cursor: "auto" }
                  : { cursor: "not-allowed" }
              }
            >
              <option value="">Select Currency</option>
              {currencies?.map((d, i) => (
                <option value={d?.value}>{d?.label}</option>
              ))}
            </select>
          </div>
        </div>
        {user?.role === "admin" ? (
          <div className="flex items-center justify-end">
            <button
              type="submit"
              className="bg-[#0226BE] text-white py-2 px-4 rounded-lg text-[14px]"
            >
              Update
            </button>
          </div>
        ) : (
          ""
        )}
      </form>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setInputData(organization);
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpen}>
          <Box sx={{ ...style }}>{renderAddressComp(addressType)}</Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default Finance;
