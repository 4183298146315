import React, { useState, useEffect } from "react";
import { useGlobalContext } from "../Contexts/GlobalContext";
import { useNavigate } from "react-router-dom";
import { hideNav } from "../Utlils";

const WarningBar = () => {
  const navigate = useNavigate();
  const { user, setSnackbar } = useGlobalContext();
  const [shouldDisplay, setShouldDisplay] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!user.organizationName) {
        setShouldDisplay(true);
      } else {
        setShouldDisplay(false);
      }
    }, 1500);

    return () => clearTimeout(timer);
  }, [user?.organizationName]);
  if (
    hideNav.some((data) => window.location.href.includes(data)) ||
    !shouldDisplay
  ) {
    return null;
  }
  // console.log("user=>", user);

  return (
    <div
      className={`w-full py-2  px-4 bg-red-200 my-3 flex items-center justify-between rounded-md ${
        hideNav?.some((data) => window.location.href?.includes(data))
          ? "hidden"
          : ""
      }`}
    >
      <h1 className="text text-gray-700 text-[14px] font-semibold">
        Organization details is not set
      </h1>
      <button
        className="border-2 border-red-500 px-4 py-1 text-red-500 font-bold text-[12px] rounded-md"
        onClick={() => {
          if (user?.role === "admin") {
            navigate("/profile/my-profile");
          } else {
            setSnackbar({
              open: true,
              msg: "Only admin can add this details",
              severity: "warning",
            });
          }
        }}
      >
        FIX
      </button>
    </div>
  );
};

export default WarningBar;
