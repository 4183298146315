import React from "react";
import milogo from "../assets/mi_logo.svg";
import status2 from "../assets/status2.svg";
const ResponseRecordedErrorPage = () => {
  return (
    <div className="pl-[97px] tablet:pl-[30px] pr-[88px] tablet:pr-[30px] py-[20px] pb-5 bg-[#F6F5F4] h-[100dvh]">
      <img
        src={milogo}
        className="block w-full h-[40px] tablet:h-[25px]"
        alt=""
      />

      <h1 className="text-center pt-[60px] tablet:pt-10 text-[50px] tablet:text-[25px] mb-10 tablet:mb-0 text-[#606060]">
        Status Recorded
      </h1>

      <img
        src={status2}
        className="mx-auto h-[300px] tablet:h-[260px] tablet:my-10"
        alt=""
      />
      <div className="capitalize mt-[100px] tablet:mt-10 text-[20px] text-[#5c5c5c] font-[500]  mb-[40px] text-center">
        <p className="mb-1">your responses has already been recorded!</p>
        <p>Thank you for filling out this survey for us.</p>
      </div>
    </div>
  );
};

export default ResponseRecordedErrorPage;
