import React, { memo, useCallback, useEffect, useState } from "react";
import MiratsLogo2 from "../../assets/MiratsLogo2.svg";
import DashboardImage from "../../assets/DashboardImage.svg";
import { FcGoogle } from "react-icons/fc";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../Utlils";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import facebook from "../../assets/facebooklogo.svg";
import microsoft from "../../assets/microsoft logo.svg";
import {
  Login,
  resetPassword,
  signInWithFacebook,
  signInWithGoogle,
  signInWithMicrosoft,
} from "../../FirebaseFunctions";

const LogIn = () => {
  const { setSnackbar, user } = useGlobalContext();
  const { type } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [inputData, setInputData] = useState({});

  const handleChange = (e) => {
    let { name, value } = e.target;
    setInputData((prev) => ({ ...prev, [name]: value }));
  };
  let footerData = [
    { name: "Privacy Policy", link: "/privacy-policy" },
    { name: "Terms And Conditions", link: "/terms-and-conditions" },
    { name: "Refund Policy", link: "/refund-policy" },
  ];
  // LOGIN:
  const handleSignItSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios
        .post(`${BASE_URL}/login`, {
          email: inputData?.email,
          password: inputData?.password,
        })
        .then((res) => {
          // console.log(res?.data?.token);
          const d = new Date();
          d.setTime(d.getTime() + 5 * 24 * 60 * 60 * 1000);
          let expires = "expires=" + d.toUTCString();
          document.cookie =
            "diy_token" + "=" + res?.data?.token + ";" + expires + ";path=/";
          if (res.data.token) {
            navigate("/");
            // console.log("token found", res.data.token);
            setSnackbar({
              open: true,
              severity: "success",
              msg: "Login Successfully!",
            });
            setInputData({});
          } else {
            // console.log("token not found", res.data);
            setSnackbar({
              open: true,
              severity: "error",
              msg: "Something went wrong!",
            });
            setInputData({});
          }
        });
    } catch (error) {
      console.log(error, error.message);
      setInputData({});
      setSnackbar({
        open: true,
        msg: `${error?.response.data?.error}`,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  //FORGOT PASSWORD:
  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let result = await resetPassword(inputData?.email);
      setSnackbar({
        open: true,
        msg: result,
        severity: "success",
      });
      // navigate(`/login/verify-email`);
    } catch (error) {
      console.log(error);
      setSnackbar({
        open: true,
        msg: error?.errorMessage,
        severity: "error",
      });
    } finally {
      setLoading(true);
    }
  };

  // console.log(inputData);

  const ForgotPassword = useCallback(
    memo(({ handleForgotPassword, inputData, setInputData, footerData }) => {
      return (
        <div className="w-full  h-screen flex ">
          <div className="w-[45%]   flex items-center justify-center ">
            <div className="w-[90%] h-[90%]  flex flex-col items-center justify-between   gap-[45px] pl-2">
              <div className="flex flex-col items-center justify-center gap-[30px]">
                <img src={MiratsLogo2} alt="CompanyLogo" className=" " />
                <form
                  className="w-full flex flex-col gap-[25px] mt-[40px]"
                  onSubmit={(e) => handleForgotPassword(e)}
                >
                  <div>
                    <h1 className="text-[#333] text-[38px] font-[600] inter">
                      Forgot Password
                    </h1>
                    <p className="text-[#333] text-[14px] font-[400]">
                      Enter the email associated with your account and we’ll
                      send an email with instructions to reset your password.
                    </p>
                  </div>
                  <div className="flex flex-col gap-[14px] ">
                    <h2 className="text-[#333] text-[14px] font-[600]">
                      Email Id
                    </h2>
                    <input
                      type="email"
                      name="email"
                      className="pl-2 border border-[#E2E2E5] w-full h-[40px] text-[14px]  focus:outline-[#325FFF] focus:text-[#325FFF]"
                      placeholder="Enter Email id"
                      autoComplete="off"
                      value={inputData?.email}
                      onChange={(e) => {
                        setInputData({ email: e.target.value });
                      }}
                      required
                    />
                  </div>

                  <button
                    className={` text-white font-[700] text-[14px] py-3 ${
                      !loading
                        ? "bg-[#0F0F0F] cursor-pointer"
                        : "bg-[#535353] cursor-not-allowed"
                    }`}
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? "Sending Email..." : "Send Email"}
                  </button>
                </form>
              </div>
              <h1 className="">
                Don’t have an account?{" "}
                <span
                  className="text-[#325FFF] text-[14px] font-[600] cursor-pointer"
                  onClick={(e) => navigate("/sign-up")}
                >
                  Sign Up
                </span>
              </h1>
            </div>
            <footer className="fixed w-[45%]  bottom-0 h-[30px] bg-gray-50 flex items-center justify-center gap-[20px] text-[12px]">
              {footerData?.map((d, i) => (
                <Link to={d?.link}>{d?.name}</Link>
              ))}
            </footer>
          </div>
          <div className="w-[55%]  signupBg h-full flex items-center justify-center ">
            <div className="w-[85%] h-[92%]  flex flex-col gap-[20px] pl-2">
              <h1 className="text-[44px] text-[#fff] font-[600] leading-[1.35]">
                The simplest way to handle your survey
              </h1>
              <p className="text-[16px] text-[#fff] font-[400] ">
                Enter your credentials to access your account.
              </p>
              <div className="w-full flex items-center justify-center mt-[35px]">
                <img src={DashboardImage} alt="" />
              </div>
            </div>
          </div>
        </div>
      );
    }),
    []
  );

  const VerifyEmail = useCallback(
    memo(({ inputData }) => {
      let user = JSON.parse(localStorage.getItem("onboard-user"));

      // console.log(user);
      return (
        <div className="w-full  h-screen flex ">
          <div className="w-[45%]   flex items-center justify-center ">
            <div className="w-[90%] h-[90%]   flex flex-col items-center justify-between   gap-[45px] pl-2">
              <div className="w-full flex flex-col items-center justify-center gap-[30px]">
                <img src={MiratsLogo2} alt="CompanyLogo" className=" " />
                <div className="w-full flex flex-col gap-[45px] mt-[40px]">
                  <div>
                    <h1 className="text-[#333] text-[38px] font-[600] inter">
                      Check your email
                    </h1>
                    <p className="text-[#333] text-[14px] font-[400]">
                      We have sent a password recover link to your email.
                    </p>
                  </div>

                  <h2 className="text-[#333] text-[16px] font-[600]">
                    {user?.email?.split("@")[0][0] +
                      user?.email?.split("@")[0][1] +
                      user?.email?.split("@")[0][2] +
                      "********" +
                      user?.email?.split("@")[0][
                        user?.email?.split("@")[0]?.length - 3
                      ] +
                      user?.email?.split("@")[0][
                        user?.email?.split("@")[0]?.length - 2
                      ] +
                      user?.email?.split("@")[0][
                        user?.email?.split("@")[0]?.length - 1
                      ] +
                      "@" +
                      user?.email?.split("@")[1]}
                  </h2>
                  <a
                    href="https://mail.google.com/mail/"
                    target="_blank"
                    className="bg-[#0F0F0F] text-white font-[700] text-[14px] py-3 text-center cursor-pointer"
                  >
                    Open email{" "}
                  </a>
                  <p
                    className="text-[#333] text-[14px] font-[600] self-center cursor-pointer"
                    onClick={(e) =>
                      (window.location.href = `${window.location.origin}/login`)
                    }
                  >
                    Skip, I’ll confirm later
                  </p>
                </div>
              </div>
              <div className="">
                <p className="text-center">
                  Did not receive the email? Check your spam filter, or
                </p>
                <h1
                  className="text-[#325FFF] text-[14px] font-[600] mt-[15px] cursor-pointer text-center"
                  onClick={(e) => navigate("/sign-up")}
                >
                  try another email address
                </h1>
              </div>
            </div>
          </div>
          <div className="w-[55%]  signupBg h-full flex items-center justify-center ">
            <div className="w-[85%] h-[92%]  flex flex-col gap-[20px] pl-2">
              <h1 className="text-[44px] text-[#fff] font-[600] leading-[1.35]">
                The simplest way to handle your survey
              </h1>
              <p className="text-[16px] text-[#fff] font-[400] ">
                Enter your credentials to access your account.
              </p>
              <div className="w-full flex items-center justify-center mt-[35px]">
                <img src={DashboardImage} alt="" />
              </div>
            </div>
          </div>
        </div>
      );
    })
  );

  const RedirectToHome = useCallback(
    memo(() => {
      window.location.href = `${window.location.origin}/login`;
      return <h1> redidrect Home</h1>;
    })
  );

  const renderComponent = () => {
    switch (type) {
      case undefined:
        return (
          <SignIn
            handleSignItSubmit={handleSignItSubmit}
            inputData={inputData}
            navigate={navigate}
            handleChange={handleChange}
            setSnackbar={setSnackbar}
            footerData={footerData}
          />
        );
      case "forgot-password":
        return (
          <ForgotPassword
            inputData={inputData}
            setInputData={setInputData}
            handleForgotPassword={handleForgotPassword}
            footerData={footerData}
          />
        );
      case "verify-email":
        return (
          <VerifyEmail
            inputData={inputData}
            handleForgotPassword={handleForgotPassword}
            user={user}
            footerData={footerData}
          />
        );
      default:
        return <RedirectToHome footerData={footerData} />;
    }
  };
  return <div className="h-screen">{renderComponent()}</div>;
};

const SignIn = ({
  inputData,
  handleChange,
  navigate,
  setSnackbar,
  footerData,
}) => {
  const [loading, setLoading] = useState(false);
  const handelSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const user = await Login(inputData);
      // console.log("User:", user);
      setSnackbar({
        msg: "Login Success !",
        severity: "success",
        open: true,
      });
      navigate("/");
    } catch (error) {
      console.log(error);
      if (error?.errorCode?.includes("auth/invalid-credential")) {
        setSnackbar({
          msg: "Invalid Credentials",
          severity: "error",
          open: true,
        });
      } else if (error?.errorCode?.includes("email_not_verified")) {
        setSnackbar({
          msg: "Email Not Verified",
          severity: "error",
          open: true,
        });
      } else {
        setSnackbar({
          msg: error?.errorMessage || "Something went wrong !",
          severity: "error",
          open: true,
        });
      }
    } finally {
      setLoading(false);
    }
  };
  const handleSignInWithGoogle = async (e) => {
    try {
      const user = await signInWithGoogle();
      if (!user?.success) {
        if (user?.errorType === "Disposable Email Detected!") {
          setSnackbar({
            msg: user?.msg || "Something went wrong !",
            severity: "error",
            open: true,
          });
        } else {
          setSnackbar({
            msg: "Error during sign-in!",
            severity: "error",
            open: true,
          });
        }
      } else {
        navigate("/");
        setSnackbar({
          msg: "Login Success !",
          severity: "success",
          open: true,
        });
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSignInWithMeta = async (e) => {
    try {
      let result = await signInWithFacebook();
      // console.log(result);
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };
  const handleSignInWithMicrosoft = async (e) => {
    try {
      let result = await signInWithMicrosoft();
      // console.log(result);
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-full  h-screen flex ">
      <div className="w-[45%]   flex flex-col  items-center justify-center ">
        <form
          onSubmit={handelSignIn}
          className="w-[90%]  flex flex-col items-center  gap-[25px] pl-2"
        >
          <img src={MiratsLogo2} alt="CompanyLogo" className=" " />
          <div className="w-full flex flex-col gap-[25px] ">
            <div>
              <h1 className="text-[#333] text-[38px] font-[600] inter">
                Sign In
              </h1>
              <p className="text-[#333] text-[14px] font-[400]">
                Enter your credentials to access your account
              </p>
            </div>
            <div className="flex flex-col gap-[14px]">
              <h2 className="text-[#333] text-[14px] font-[600]">Email Id</h2>
              <input
                type="email"
                name="email"
                required
                value={inputData?.email}
                onChange={handleChange}
                className="pl-2 border border-[#E2E2E5] w-full h-[40px] text-[14px]  focus:outline-[#325FFF] focus:text-[#325FFF]"
                placeholder="Enter Email id"
              />
            </div>
            <div className="flex flex-col gap-[14px]">
              <h2 className="text-[#333] text-[14px] font-[600]">Password</h2>
              <input
                type="password"
                name="password"
                onChange={handleChange}
                value={inputData?.password}
                className="pl-2 border border-[#E2E2E5] w-full h-[40px] text-[14px]  focus:outline-[#325FFF] focus:text-[#325FFF]"
                placeholder="Enter Password"
              />
              <p
                className="text-[#325FFF] text-end font-[600] text-[12px] cursor-pointer"
                onClick={(e) => navigate("forgot-password")}
              >
                Forgot password ?
              </p>
            </div>

            <button
              className={` text-white font-[700] text-[14px] py-3 ${
                !loading
                  ? "bg-[#0F0F0F] cursor-pointer"
                  : "bg-[#535353] cursor-not-allowed"
              }`}
              type="submit"
              disabled={loading}
            >
              {loading ? "Signing in..." : "Sign In"}
            </button>
            <div className="text-[#666] w-full flex items-center gap-[10px]">
              <div className=" border-b border-dashed border-[#999] w-[50%] "></div>
              Or
              <div className=" border-b border-dashed border-[#999] w-[50%] "></div>
            </div>
            <div className="w-full items-center flex justify-center flex-col gap-[12px]">
              {/* <button
                className="border border-[#E2E2E5] flex items-center justify-center gap-[10px] text-[16px] font-[500] h-[40px] w-full"
                type="button"
                onClick={handleSignInWithGoogle}
              >
                <FcGoogle size={24} />
                Log in with Google
              </button> */}
              {/* <button
                className="border border-[#E2E2E5] flex items-center justify-center gap-[10px] text-[16px] font-[500] h-[40px] w-full"
                type="button"
                onClick={handleSignInWithMeta}
              >
                <img
                  src={facebook}
                  alt="facebook"
                  className="w-[20px] h-[20px]"
                />
                Log in with Facebook
              </button> */}
              {/* <button
                className="border border-[#E2E2E5] flex items-center justify-center gap-[10px] text-[16px] font-[500] h-[40px] w-full"
                type="button"
                onClick={handleSignInWithMicrosoft}
              >
                <img
                  src={microsoft}
                  alt="microsoft"
                  className="w-[20px] h-[20px]"
                />
                Log in with Microsoft
              </button> */}
            </div>
          </div>
          <h1 className="">
            Don’t have an account?{" "}
            <span
              className="text-[#325FFF] text-[14px] font-[600] cursor-pointer"
              onClick={(e) => navigate("/sign-up")}
            >
              Sign Up
            </span>
          </h1>
        </form>
        <footer className="fixed w-[45%]  bottom-0 h-[30px] bg-gray-50 flex items-center justify-center gap-[20px] text-[12px]">
          {footerData?.map((d, i) => (
            <Link to={d?.link}>{d?.name}</Link>
          ))}
        </footer>
      </div>
      <div className="w-[55%]  signupBg h-full flex items-center justify-center ">
        <div className="w-[85%] h-[92%]  flex flex-col gap-[20px] pl-2">
          <h1 className="text-[44px] text-[#fff] font-[600] leading-[1.35]">
            The simplest way to handle your survey
          </h1>
          <p className="text-[16px] text-[#fff] font-[400] ">
            Enter your credentials to access your account.
          </p>
          <div className="w-full flex items-center justify-center mt-[35px]">
            <img src={DashboardImage} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
