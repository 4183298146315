import { getContinentByCountry } from "../survey/surveyDetails/components/helperData";
import panelData from "./Feasiblity.json";
export function getCountryTotalCount(country) {
  const countryData = panelData[country];
  if (!countryData) {
    console.log(`Country data for ${country} not found.`);
    return 0;
  }
  return countryData;
}
const countryActivePanelRate = {
  Afghanistan: 5,
  "Aland Islands": 0,
  Albania: 29,
  Algeria: 24,
  "American Samoa": 0,
  Andorra: 38,
  Angola: 8,
  Anguilla: 0,
  Antarctica: 0,
  "Antigua And Barbuda": 0,
  Argentina: 32,
  Armenia: 29,
  Aruba: 0,
  Australia: 35,
  Austria: 35,
  Azerbaijan: 31,
  "The Bahamas": 34,
  Bahrain: 39,
  Bangladesh: 6,
  Barbados: 33,
  Belarus: 32,
  Belgium: 36,
  Belize: 18,
  Benin: 5,
  Bermuda: 0,
  Bhutan: 19,
  Bolivia: 19,
  "Bosnia and Herzegovina": 28,
  Botswana: 15,
  "Bouvet Island": 0,
  Brazil: 29,
  "British Indian Ocean Territory": 0,
  Brunei: 38,
  Bulgaria: 26,
  "Burkina Faso": 4,
  Burundi: 2,
  Cambodia: 15,
  Cameroon: 12,
  Canada: 36,
  "Cape Verde": 22,
  "Cayman Islands": 0,
  "Central African Republic": 2,
  Chad: 2,
  Chile: 32,
  China: 26,
  "Christmas Island": 0,
  "Cocos (Keeling) Islands": 0,
  Colombia: 26,
  Comoros: 7,
  "Democratic Republic of the Congo": 3,
  "Cook Islands": 0,
  "Costa Rica": 32,
  "Cote D'Ivoire (Ivory Coast)": 0,
  Croatia: 31,
  Cuba: 19,
  Cyprus: 36,
  "Czech Republic": 35,
  Denmark: 38,
  Djibouti: 4,
  Dominica: 26,
  "Dominican Republic": 25,
  "East Timor": 0,
  Ecuador: 25,
  Egypt: 21,
  "El Salvador": 16,
  "Equatorial Guinea": 9,
  Eritrea: 0,
  Estonia: 35,
  Ethiopia: 7,
  "Falkland Islands": 0,
  "Faroe Islands": 0,
  "Fiji Islands": 21,
  Finland: 37,
  France: 35,
  "French Guiana": 0,
  "French Polynesia": 0,
  "French Southern Territories": 0,
  Gabon: 20,
  "Gambia The": 8,
  Georgia: 24,
  Germany: 36,
  Ghana: 15,
  Gibraltar: 0,
  Greece: 31,
  Greenland: 0,
  Grenada: 22,
  Guadeloupe: 0,
  Guam: 0,
  Guatemala: 17,
  "Guernsey and Alderney": 0,
  Guinea: 6,
  "Guinea-Bissau": 3,
  Guyana: 15,
  Haiti: 11,
  "Heard Island and McDonald Islands": 0,
  Honduras: 18,
  "Hong Kong S.A.R.": 0,
  Hungary: 32,
  Iceland: 39,
  India: 50,
  Indonesia: 21,
  Iran: 28,
  Iraq: 9,
  Ireland: 35,
  Israel: 34,
  Italy: 30,
  Jamaica: 22,
  Japan: 36,
  Jersey: 0,
  Jordan: 26,
  Kazakhstan: 31,
  Kenya: 17,
  Kiribati: 5,
  "North Korea": 0,
  "South Korea": 0,
  Kuwait: 39,
  Kyrgyzstan: 20,
  Laos: 10,
  Latvia: 33,
  Lebanon: 31,
  Lesotho: 11,
  Liberia: 3,
  Libya: 16,
  Liechtenstein: 38,
  Lithuania: 34,
  Luxembourg: 39,
  "Macau S.A.R.": 0,
  "North Macedonia": 31,
  Madagascar: 3,
  Malawi: 5,
  Malaysia: 33,
  Maldives: 27,
  Mali: 4,
  Malta: 36,
  "Man (Isle of)": 0,
  "Marshall Islands": 11,
  Martinique: 0,
  Mauritania: 7,
  Mauritius: 23,
  Mayotte: 0,
  Mexico: 26,
  Micronesia: 10,
  Moldova: 28,
  Monaco: 39,
  Mongolia: 23,
  Montenegro: 28,
  Montserrat: 0,
  Morocco: 26,
  Mozambique: 8,
  Myanmar: 15,
  Namibia: 12,
  Nauru: 23,
  Nepal: 13,
  "Bonaire, Sint Eustatius and Saba": 0,
  Netherlands: 37,
  "New Caledonia": 0,
  "New Zealand": 36,
  Nicaragua: 10,
  Niger: 4,
  Nigeria: 16,
  Niue: 0,
  "Norfolk Island": 0,
  "Northern Mariana Islands": 0,
  Norway: 39,
  Oman: 38,
  Pakistan: 10,
  Palau: 19,
  "Palestinian Territory Occupied": 0,
  Panama: 27,
  "Papua New Guinea": 4,
  Paraguay: 23,
  Peru: 23,
  Philippines: 24,
  "Pitcairn Island": 0,
  Poland: 31,
  Portugal: 32,
  "Puerto Rico": 0,
  Qatar: 39,
  Reunion: 0,
  Romania: 28,
  Russia: 30,
  Rwanda: 8,
  "Saint Helena": 0,
  "Saint Kitts And Nevis": 30, // Renamed from "Saint Kitts and Nevis"
  "Saint Lucia": 25,
  "Saint Pierre and Miquelon": 0,
  "Saint Vincent And The Grenadines": 22, // Renamed from "Saint Vincent and the Grenadines"
  "Saint-Barthelemy": 0,
  "Saint-Martin (French part)": 0,
  Samoa: 11,
  "San Marino": 37,
  "Sao Tome and Principe": 11,
  "Saudi Arabia": 37,
  Senegal: 12,
  Serbia: 29,
  Seychelles: 23,
  "Sierra Leone": 6,
  Singapore: 37,
  Slovakia: 32,
  Slovenia: 34,
  "Solomon Islands": 4,
  Somalia: 3,
  "South Africa": 22,
  "South Georgia": 0,
  "South Sudan": 3,
  Spain: 35,
  "Sri Lanka": 15,
  Sudan: 12,
  Suriname: 19,
  "Svalbard And Jan Mayen Islands": 0,
  Swaziland: 0,
  Sweden: 36,
  Switzerland: 37,
  Syria: 13,
  Taiwan: 0,
  Tajikistan: 10,
  Tanzania: 7,
  Thailand: 24,
  Togo: 4,
  Tokelau: 0,
  Tonga: 18,
  "Trinidad And Tobago": 27, // Renamed from "Trinidad and Tobago"
  Tunisia: 22,
  Turkey: 30,
  Turkmenistan: 8,
  "Turks And Caicos Islands": 0,
  Tuvalu: 20,
  Uganda: 9,
  Ukraine: 26,
  "United Arab Emirates": 38,
  "United Kingdom": 37,
  "United States": 35,
  "United States Minor Outlying Islands": 0,
  Uruguay: 30,
  Uzbekistan: 18,
  Vanuatu: 10,
  "Vatican City State (Holy See)": 0,
  Venezuela: 23,
  Vietnam: 28,
  "Virgin Islands (British)": 0,
  "Virgin Islands (US)": 0,
  "Wallis And Futuna Islands": 0,
  "Western Sahara": 0,
  Yemen: 8,
  Zambia: 11,
  Zimbabwe: 11,
  Kosovo: 0,
  Curaçao: 0,
  "Sint Maarten (Dutch part)": 0,
};
export function getTotalPanelCount(country, audience) {
  const countryData = getCountryTotalCount(country) || {};
  const totalCount = countryData["General population"] || 0;
  let audienceCount;
  if (audience == "Total") {
    audienceCount = totalCount || 0;
  } else if (["ITDMs", "HRDMs", "ITDMs", "Marketing DMs"]?.includes(audience)) {
    audienceCount = countryData["Decision Makers"]?.[audience] || 0;
  } else if (["Financial/Investment Managers"]?.includes(audience)) {
    audienceCount = countryData["Professionals"]?.[audience] || 0;
  } else if (["C-Level Executives"]?.includes(audience)) {
    audienceCount =
      countryData["Job role/Job title/Job level"]?.[audience] || 0;
  } else if (["A non-profit organization"]?.includes(audience)) {
    audienceCount = countryData["Sector"]?.[audience] || 0;
  } else {
    audienceCount = totalCount || 0;
  }

  if (!totalCount) {
    console.log(`"General population" data not found for country ${country}.`);
    return 0;
  }
  return audienceCount;
}
export function getActiveCount(totalCount, countryName) {
  if (typeof totalCount !== "number") {
    throw new Error(`Invalid totalCount: ${totalCount}`);
  }
  return (countryActivePanelRate[countryName] / 100) * totalCount;
}
export function getIRreductionCount(activeCount, IR) {
  if (typeof IR !== "number") {
    throw new Error(`Invalid IR: ${IR}(${typeof IR}).`);
  }
  if (typeof activeCount !== "number") {
    throw new Error(`Invalid activeCount: ${activeCount}`);
  }
  return (IR / 100) * activeCount;
}
export function getLOIReductionCount(LOI, baseLOI = 13, irReductionCount) {
  if (typeof LOI !== "number") {
    throw new Error(`Invalid LOI: ${LOI}(${typeof LOI}).`);
  }
  if (typeof baseLOI !== "number" || baseLOI < 0) {
    throw new Error(
      `Invalid baseLOI: ${baseLOI}(${typeof baseLOI}). It should be a positive number.`
    );
  }
  if (typeof irReductionCount !== "number" || irReductionCount < 0) {
    throw new Error(`Invalid irReductionCount: ${irReductionCount}`);
  }
  let reductionCount = LOI - baseLOI;
  return irReductionCount - (reductionCount / 100) * irReductionCount;
}
export function getFeasibilityCount(ratePerDay, sampleCount, finalCount) {
  // console.log("getFeasibilityCount", { sampleCount, ratePerDay, finalCount });
  if (typeof ratePerDay !== "number" || ratePerDay <= 0) {
    console.log(
      `Invalid ratePerDay: ${ratePerDay}(${typeof ratePerDay}). It should be a positive number or greater than 0.`
    );
    return 0;
  }
  if (typeof sampleCount !== "number" || sampleCount <= 0) {
    console.log(
      `Invalid sampleCount: ${sampleCount}(${typeof sampleCount}). It should be a positive number. or greater than 0`
    );
    return 0;
  }
  if (finalCount <= 0) {
    return 0;
  }

  const filledDay =
    finalCount <= sampleCount
      ? finalCount / ratePerDay
      : sampleCount / ratePerDay;
  return Math.ceil(filledDay);
}
const ratePerDayByContinent = {
  APAC: 800,
  Europe: 500,
  NorthAmerica: 650,
  SouthAmerica: 400,
  MENA: 400,
  Africa: 350,
  Antarctica: 0,
};
export function getCalculation(
  country,
  countryName,
  audience = "Total",
  IR,
  LOI,
  sample
) {
  try {
    const continent = getContinentByCountry(countryName);
    const ratePerDay = ratePerDayByContinent[continent];
    const totalCount = getTotalPanelCount(country, audience);
    const activeCount = getActiveCount(totalCount, countryName);
    const irReductionCount = getIRreductionCount(activeCount, IR);
    const loiReductionCount = getLOIReductionCount(LOI, 13, irReductionCount);
    const feasibilityDay = getFeasibilityCount(
      ratePerDay,
      sample,
      loiReductionCount
    );

    return {
      totalCount,
      activeCount,
      irReductionCount,
      finalCount: loiReductionCount,
      feasibilityDay,
      ratePerDay,
    };
  } catch (error) {
    console.error("Calculation error:", error.message);
    return { error: error.message };
  }
}
// console.log("panelData=>", panelData);
// console.log("getPanelCount=>", getCalculation("US", "ITDMs", 30, 26, 2300));
