import React from "react";

const Loader = () => {
  return (
    <div className="h-[300px] flex items-center justify-center w-full  ">
      <span class="loader "></span>
    </div>
  );
};

export default Loader;
