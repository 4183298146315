import React, { useState } from "react";
import { BiSolidPencil } from "react-icons/bi";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { RxCross2 } from "react-icons/rx";
import dummyUserProfilePicture from "../../../assets/defaultUserProfile.svg";
import { FcGoogle } from "react-icons/fc";
import { useGlobalContext } from "../../../Contexts/GlobalContext";
import axios from "axios";
import { BASE_URL, getCookie } from "../../../Utlils";
import { logOutUser, updateUserPassword } from "../../../FirebaseFunctions";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../FirebaseConfig";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
};

const MyAccount = () => {
  const navigate = useNavigate();
  const [changePassword, setChangePassword] = useState({});
  const [changeEmail, setChangeEmail] = useState("");
  const [modals, setModals] = useState({
    changeEmail: false,
    openEmail: false,
    changePassword: false,
    passwordResetSuccess: false,
    deleteAccount: false,
    confirmDeleteAccount: false,
  });
  const { user, setSnackbar } = useGlobalContext();
  const handleOpen = (key) => setModals((prev) => ({ ...prev, [key]: true }));
  const handleClose = (key) => setModals((prev) => ({ ...prev, [key]: false }));

  // console.log(changePassword);
  //CHANGE PASSWORD:
  const handlePasswordChangeSubmit = async (e) => {
    e.preventDefault();
    try {
      if (changePassword?.newPass === changePassword?.confirmNewPass) {
        // await axios
        //   .put(
        //     `${BASE_URL}/users/change-password-from-portal?orgId=${user?.organizationId}&uid=${user?._id}`,
        //     changePassword
        //   )
        //   .then((res) => {
        //     setSnackbar({
        //       open: true,
        //       msg: res.data.msg,
        //       severity: res.data.severity,
        //     });
        //     if (res.data.severity === "success") {
        //       document.cookie =
        //         "token" +
        //         "=" +
        //         (getCookie("token") || "") +
        //         "; expires=" +
        //         "Thu, 01 Jan 1970 00:00:01 GMT" +
        //         ";domain=.miratsoneservices.com;path=/";
        //       setModals((prev) => ({
        //         ...prev,
        //         passwordResetSuccess: true,
        //         changePassword: false,
        //       }));
        //       navigate("/login");
        //     }
        //   });
        await updateUserPassword(changePassword);
        setSnackbar((prev) => ({
          open: true,
          msg: "Password Updated , Please Login Again ! ",
          severity: "success",
        }));
        logOutUser();
        navigate("/login");
      } else {
        setSnackbar((prev) => ({
          open: true,
          msg: "New password and Confirm new password should be same!",
          severity: "error",
        }));
      }
    } catch (error) {
      // console.log(error);
      if (error?.errorCode === "auth/invalid-credential") {
        setSnackbar((prev) => ({
          open: true,
          msg: "Current Password is wrong !",
          severity: "error",
        }));
      }
    }
  };

  //GENERATE EMAIL LINK:
  // const generateEmailLink = (origin, userId, oldEmail, newEmail) => {
  //   let createUrl = `${origin}/verify-change-email?oe=${oldEmail}&ne=${newEmail}&uid=${userId}`;
  //   return createUrl;
  // };

  return (
    <div className="w-full flex flex-col gap-[15px] h-full py-5 ">
      <div className="pb-10 flex items-center justify-between  border-b border-[#E0E0E0] ">
        <div className="flex flex-col gap-[15px] justify-center">
          <h1 className="text-[#333333] text-[28px] font-[500]">My Account</h1>
          <p>Get notification from Mirats Quanto on your devices</p>
        </div>
        <button
          className="bg-[#b81d22] rounded-[8px] text-[#fff] text-[13px] px-4 py-2"
          onClick={(e) => handleOpen("deleteAccount")}
        >
          Delete Account
        </button>
      </div>
      <div className="pb-8 border-b border-[#E0E0E0] flex justify-between items-center">
        <div className="flex flex-col gap-[20px]">
          <h1 className="flex items-center gap-[20px] text-[16px] font-[600]">
            Email{" "}
            {/* <BiSolidPencil
              color="#0226BE"
              size={20}
              style={{ cursor: "pointer" }}
              onClick={(e) => handleOpen("changeEmail")}
            /> */}
          </h1>
          <p className="text-[#333] text-[14px] font-[400]">{user?.email}</p>
        </div>
        <div className="flex flex-col gap-[20px]">
          <h1 className="flex items-center gap-[20px] text-[16px] font-[600]">
            Password{" "}
            {auth?.currentUser?.providerData?.[0]?.providerId === "password" ? (
              <BiSolidPencil
                color="#0226BE"
                size={20}
                style={{ cursor: "pointer" }}
                onClick={(e) =>
                  setModals((prev) => ({ ...prev, changePassword: true }))
                }
              />
            ) : (
              ""
            )}
          </h1>
          <p className="text-[#333] text-[14px] font-[400]">
            {auth?.currentUser?.providerData?.[0]?.providerId === "password" ? (
              "**************"
            ) : (
              <FcGoogle size={25} />
            )}
          </p>
        </div>
        <div className="flex flex-col gap-[20px]">
          <h1 className="flex items-center gap-[20px] text-[16px] font-[600]">
            Account Status
          </h1>
          <p className="text-[#37AB00] text-[14px] font-[400]">Active</p>
        </div>
      </div>

      {/* change email modal*/}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modals?.changeEmail}
        onClose={(e) => {
          handleClose("changeEmail");
          setChangeEmail("");
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modals?.changeEmail}>
          <Box sx={style}>
            <div className="w-full flex items-center flex-col">
              <div className="w-full py-6 border-b border-[#E0E0E0]  text-center relative">
                <h1 className="text-[#333333] text-[20px] font-[600]">
                  Change Email
                </h1>
                <p
                  className=" bg-[#F1F1F1] py-1 px-1 rounded-[50%] w-fit absolute top-3 cursor-pointer right-4"
                  onClick={(e) => {
                    setModals((prev) => ({ ...prev, changeEmail: false }));
                    setChangeEmail("");
                  }}
                >
                  <RxCross2 size={16} />
                </p>
              </div>

              <form
                className="w-full py-4 px-5  flex flex-col gap-[19px]"
                onSubmit={(e) => {
                  e.preventDefault();
                  setModals((prev) => ({
                    ...prev,
                    changeEmail: false,
                    openEmail: true,
                  }));
                  console.log(
                    `${window.location.origin}/verify-change-email?oe=${user?.email}&ne=${changeEmail}&id=${user?._id}`
                  );
                }}
              >
                <div className="flex flex-col gap-[10px]">
                  <p className="text-[#333] text-[16px] font-[400]">
                    If you want to change your e-mail, verify it here{" "}
                  </p>
                  <p className="text-[#333] text-[14px] font-[500]">
                    Previous Email : {user?.email}
                  </p>
                </div>
                <div className="flex flex-col gap-[10px]">
                  <label
                    htmlFor="email"
                    className="text-[#333] text-[14px] font-[500]"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    required
                    placeholder="johndoe@gmail.com"
                    className="border border-[#E2E2E5] rounded-[4px] pl-3 h-[40px] focus:outline-none text-[#666] text-[14px] cursor-pointer"
                    onChange={(e) => {
                      setChangeEmail(e.target.value);
                    }}
                  />
                </div>

                <button
                  type="submit"
                  className="text-[#fff] inter text-[16px] font-[400] bg-[#0226BE] rounded-[4px] py-2 "
                >
                  Change Email
                </button>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* Open Email modal*/}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modals?.openEmail}
        onClose={(e) => handleClose("openEmail")}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modals?.openEmail}>
          <Box sx={style}>
            <div className="w-full flex items-center flex-col">
              <div className="w-full py-6 border-b border-[#E0E0E0]  text-center relative">
                <h1 className="text-[#333333] text-[20px] font-[600]">
                  Change Email
                </h1>
                <p
                  className=" bg-[#F1F1F1] py-1 px-1 rounded-[50%] w-fit absolute top-3 cursor-pointer right-4"
                  onClick={(e) =>
                    setModals((prev) => ({ ...prev, openEmail: false }))
                  }
                >
                  <RxCross2 size={16} />
                </p>
              </div>

              <form className="w-full py-4 px-5  flex flex-col gap-[23px]">
                <div className="flex flex-col gap-[10px]">
                  <p className="text-[#333] text-[20px] font-[500]">
                    Check your email
                  </p>
                  <p className="text-[#333] text-[14px] font-[400]">
                    We have sent a Email Verified link to your email.
                  </p>
                </div>
                <p className="text-[#333] text-[16px] font-[500]">
                  {user?.email}
                </p>
                <button
                  type="submit"
                  className="text-[#fff] inter text-[16px] font-[400] bg-[#0226BE] rounded-[4px] py-2 "
                >
                  Open Email
                </button>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* Change Password modal*/}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modals?.changePassword}
        onClose={(e) => handleClose("changePassword")}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modals?.changePassword}>
          <Box sx={style}>
            <div className="w-full flex items-center flex-col">
              <div className="w-full py-6 border-b border-[#E0E0E0]  text-center relative">
                <h1 className="text-[#333333] text-[20px] font-[600]">
                  Change Password
                </h1>
                <p
                  className=" bg-[#F1F1F1] py-1 px-1 rounded-[50%] w-fit absolute top-3 cursor-pointer right-4"
                  onClick={(e) =>
                    setModals((prev) => ({ ...prev, changePassword: false }))
                  }
                >
                  <RxCross2 size={16} />
                </p>
              </div>

              <form
                className="w-full py-4 px-5  flex flex-col gap-[19px]"
                onSubmit={handlePasswordChangeSubmit}
              >
                <div className="flex flex-col gap-[10px]">
                  <p className="text-[#333] text-[14px] font-[400]">
                    Your new password must be different from previous used
                    passwords.
                  </p>
                </div>
                <div className="flex flex-col gap-[10px]">
                  <label
                    htmlFor="currentPassword"
                    className="text-[#333] text-[14px] font-[500]"
                  >
                    Current Password
                  </label>
                  <input
                    type="text"
                    required
                    onChange={(e) =>
                      setChangePassword((prev) => ({
                        ...prev,
                        oldPass: e.target.value,
                      }))
                    }
                    placeholder="Enter Old Password"
                    className="border border-[#E2E2E5] rounded-[4px] pl-3 h-[40px] focus:outline-none text-[#666] text-[14px] cursor-pointer"
                  />
                </div>
                <div className="flex flex-col gap-[10px]">
                  <label
                    htmlFor="newPassword"
                    className="text-[#333] text-[14px] font-[500]"
                  >
                    New Password
                  </label>
                  <input
                    type="text"
                    required
                    onChange={(e) =>
                      setChangePassword((prev) => ({
                        ...prev,
                        newPass: e.target.value,
                      }))
                    }
                    placeholder="************"
                    className="border border-[#E2E2E5] rounded-[4px] pl-3 h-[40px] focus:outline-none text-[#393939] text-[14px]"
                  />
                </div>
                <div className="flex flex-col gap-[10px]">
                  <label
                    htmlFor="confirmNewPassword"
                    className="text-[#333] text-[14px] font-[500]"
                  >
                    Confirm New Password
                  </label>
                  <input
                    type="password"
                    required
                    onChange={(e) =>
                      setChangePassword((prev) => ({
                        ...prev,
                        confirmNewPass: e.target.value,
                      }))
                    }
                    placeholder="************"
                    className="border border-[#E2E2E5] rounded-[4px] pl-3 h-[40px] focus:outline-none text-[#393939] text-[14px]"
                  />
                </div>
                <button
                  type="submit"
                  className="text-[#fff] inter text-[16px] font-[400] bg-[#0226BE] rounded-[4px] py-2 "
                >
                  Set Password
                </button>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* Password rest success modal*/}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modals?.passwordResetSuccess}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modals?.passwordResetSuccess}>
          <Box sx={{ ...style, width: 400 }}>
            <div className="w-full flex items-center flex-col">
              <div className="w-full py-4 px-5  flex flex-col gap-[23px]">
                <div className="flex flex-col gap-[10px] items-center">
                  <img
                    src={dummyUserProfilePicture}
                    alt=""
                    className="w-[130px] h-[130px] rounded-[50%]"
                  />
                  <p className="text-[#333] text-[20px] mt-4 font-[500]">
                    Successful Password Reset
                  </p>
                  <p className="text-[#333] text-[14px] font-[400] text-center">
                    You can now use your new password to log in to your
                    account.!
                  </p>
                </div>

                <button
                  className="text-[#fff] inter text-[16px] font-[400] bg-[#0226BE] rounded-[4px] py-2 "
                  onClick={(e) => {
                    window.location.href = `${window.location.origin}/login`;
                  }}
                >
                  Login
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* delete account modal*/}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modals?.deleteAccount}
        onClose={(e) => handleClose("deleteAccount")}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modals?.deleteAccount}>
          <Box sx={{ ...style, width: 450 }}>
            <div className="w-full flex items-center flex-col">
              <div className="w-full py-6 border-b border-[#E0E0E0]  text-center relative">
                <h1 className="text-[#333333] text-[20px] font-[600]">
                  Deleting your account
                </h1>
                <p
                  className=" bg-[#F1F1F1] py-1 px-1 rounded-[50%] w-fit absolute top-3 cursor-pointer right-4"
                  onClick={(e) =>
                    setModals((prev) => ({ ...prev, deleteAccount: false }))
                  }
                >
                  <RxCross2 size={16} />
                </p>
              </div>

              <form
                className="w-full py-4 px-5  flex flex-col gap-[15px]"
                onSubmit={(e) => {
                  e.preventDefault();
                  setModals((prev) => ({
                    ...prev,
                    deleteAccount: false,
                    confirmDeleteAccount: true,
                  }));
                }}
              >
                <div className="flex flex-col gap-[10px]">
                  <p className="text-[#333] text-[14px] font-[400]">
                    If you want to permanently delete your account
                  </p>
                </div>
                <h3 className="text-[#333] text-[16px] font-[500]">
                  Why Deleting your account ?
                </h3>
                <div className="flex flex-col gap-[-5px]">
                  <div className="flex items-center gap-[10px] ">
                    <input
                      type="radio"
                      required
                      name="reason"
                      id="tooMuchTime"
                      placeholder="Enter Old Password"
                      className="border border-[#E2E2E5] rounded-[4px] pl-3 h-[40px] focus:outline-none text-[#666] text-[14px] cursor-pointer"
                    />
                    <label
                      htmlFor="tooMuchTime"
                      className="text-[#333] text-[14px] font-[400] cursor-pointer"
                    >
                      I spend too much time on DIY Surveys.
                    </label>
                  </div>
                  <div className="flex items-center gap-[10px] ">
                    <input
                      type="radio"
                      required
                      name="reason"
                      id="privacyConcern"
                      className="border border-[#E2E2E5] rounded-[4px] pl-3 h-[40px] focus:outline-none text-[#666] text-[14px] cursor-pointer"
                    />
                    <label
                      htmlFor="privacyConcern"
                      className="text-[#333] text-[14px] font-[400] cursor-pointer"
                    >
                      I have a privacy concern.
                    </label>
                  </div>
                  <div className="flex items-center gap-[10px] ">
                    <input
                      type="radio"
                      required
                      name="reason"
                      id="dontFeelSafe"
                      placeholder="Enter Old Password"
                      className="border border-[#E2E2E5] rounded-[4px] pl-3 h-[40px] focus:outline-none text-[#666] text-[14px] cursor-pointer"
                    />
                    <label
                      htmlFor="dontFeelSafe"
                      className="text-[#333] text-[14px] font-[400] cursor-pointer"
                    >
                      I don't feel safe on Facebook.
                    </label>
                  </div>
                  <div className="flex items-center gap-[10px] ">
                    <input
                      type="radio"
                      required
                      name="reason"
                      id="noLongerFindUseful"
                      placeholder="Enter Old Password"
                      className="border border-[#E2E2E5] rounded-[4px] pl-3 h-[40px] focus:outline-none text-[#666] text-[14px] cursor-pointer"
                    />
                    <label
                      htmlFor="noLongerFindUseful"
                      className="text-[#333] text-[14px] font-[400] cursor-pointer"
                    >
                      I no longer find Facebook useful.
                    </label>
                  </div>
                </div>
                <button
                  type="submit"
                  className="text-[#fff] inter text-[16px] font-[400] bg-[#0226BE] rounded-[4px] py-2 "
                >
                  Continue
                </button>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* Delete Account Confirmation modal*/}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modals?.confirmDeleteAccount}
        onClose={(e) => handleClose("confirmDeleteAccount")}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modals?.confirmDeleteAccount}>
          <Box sx={{ ...style, width: 400 }}>
            <div className="w-full flex items-center flex-col">
              <div className="w-full py-4 px-5  flex flex-col gap-[23px]">
                <div className="flex flex-col gap-[10px] ">
                  <p className="text-[#333] text-[20px] mt-4 font-[500]">
                    Delete Account
                  </p>
                  <p className="text-[#333] text-[14px] font-[400] ">
                    Do you really want to delete account?
                  </p>
                </div>

                <div className="w-full flex items-center justify-between gap-[25px]">
                  <button
                    className="inter text-[16px] font-[500] border text-[#0226BE] border-[#0226BE] rounded-[4px] py-2 w-1/2"
                    onClick={(e) =>
                      setModals((prev) => ({
                        ...prev,
                        confirmDeleteAccount: false,
                      }))
                    }
                  >
                    No
                  </button>
                  <button
                    className="text-[#fff] inter text-[16px] font-[400] bg-[#0226BE] rounded-[4px] py-2 w-1/2"
                    onClick={(e) => {
                      setModals((prev) => ({
                        ...prev,
                        confirmDeleteAccount: false,
                      }));
                      navigate("/login");
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default MyAccount;
