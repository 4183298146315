import React, { useState } from "react";
import { IoMdCheckmark, IoMdClose } from "react-icons/io";
import { useGlobalContext } from "../../../Contexts/GlobalContext";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { RxCross2 } from "react-icons/rx";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import axios from "axios";
import { BASE_URL } from "../../../Utlils";
import { addTeamMember } from "../../../FirebaseFunctions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
};

const MyMembers = () => {
  const { teamMembers, user, setSnackbar, getTeamMembers, getUser } =
    useGlobalContext();
  const [open, setOpen] = useState(false);
  const [inputData, setInputData] = useState({ permission: [] });
  const handleClose = (e) => setOpen(false);

  function generateRandomString() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";

    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }

    return randomString;
  }
  // console.log(inputData);
  const handleAddMember = (e) => {
    e.preventDefault();
    let password = generateRandomString();
    // console.log({ email: inputData?.email, password: password });
    const emailDomains = ["gmail", "hotmail", "yahoo", "outlook"];
    if (emailDomains.some((domain) => inputData?.email?.includes(domain))) {
      setSnackbar({
        msg: "Please use corporate email !",
        open: true,
        severity: "error",
      });
      return;
    }

    addTeamMember({
      ...inputData,
      organizationName: user?.organizationName,
      organizationOwner: false,
      organizationId: user?.organizationId,
      password: "12345678",
      accountStatus: "active",
      authorize: false,
      projectSettings: user?.projectSettings,
    })
      .then((res) => {
        setOpen(false);
        getTeamMembers();
        setSnackbar({
          msg: "Member Added Successfully !",
          open: true,
          severity: "success",
        });
      })
      .catch((er) => {
        // console.log(er);
        setSnackbar({
          msg: er?.errorMessage || "Something went wrong !",
          open: true,
          severity: "error",
        });
      });
  };

  //EDIT ROLE & ACCESS:
  const editMembersAccess = async (
    accessName,
    id,
    authorization,
    accountStatus
  ) => {
    try {
      const update = await axios.put(
        `${BASE_URL}/users-access?userId=${id}`,
        accessName !== null
          ? {
              role: accessName,
              permission: accessName == "admin" ? ["PB", "GB"] : ["PB"],
            }
          : authorization !== null
          ? { authorize: authorization }
          : { accountStatus: accountStatus }
      );
      setSnackbar({
        msg: "Member Details Updated Successfully !",
        open: true,
        severity: "success",
      });
      getTeamMembers();
      getUser();
    } catch (error) {
      // console.log(error);
      setSnackbar({
        msg: "Something went wrong !",
        open: false,
        severity: "error",
      });
    }
  };
  // console.log("user=>", user);
  return (
    <div className="w-full flex flex-col gap-[15px] h-full py-5 ">
      <div className="pb-10 flex items-center justify-between  border-b border-[#E0E0E0] ">
        <div className="flex flex-col gap-[15px] justify-center">
          <h1 className="text-[#333333] text-[28px] font-[500]">Members</h1>
          <p>Get notification from Mirats Quanto on your devices</p>
        </div>
        {user?.role?.toLowerCase() == "admin" ? (
          <button
            className="bg-[#0226BE] rounded-[8px] text-[#fff] px-4 text-[14px] py-2"
            onClick={(e) => setOpen(true)}
          >
            Add Member
          </button>
        ) : (
          ""
        )}
      </div>
      <div className="w-full tableBorder flex flex-col  ">
        <div className="w-full overflow-y-auto containerScroll">
          <table
            className="whitespace-nowrap text-left w-full"
            id="table-to-xls-DATA"
          >
            <thead className=" h-[45px] ">
              <tr className="py-3">
                {[
                  "Account Name",
                  "Allocation",
                  "Project Based Access",
                  "Global Member Access",
                  // "Remove Authorisation",
                  "Account Status",
                ]?.map((data, ind) => (
                  <th className="text-[#666] text-[14px] font-[600] px-3">
                    {data}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {teamMembers?.map((data, ind) => (
                <tr className=" tableBorder transition-all ">
                  <td className="px-3 py-2 text-[#333] font-[400] text-[14px] ">
                    <h1 className="whitespace-normal text-ellipsis overflow-hidden">
                      {data?.personalDetails?.fullName}
                    </h1>
                  </td>
                  <td className="px-3 py-2 text-[#333] font-[400] text-[14px] ">
                    <select
                      className="border border-[#E0E0E0] py-[6px] px-[16px] capitalize rounded-[4px]"
                      value={data?.role}
                      onChange={(e) => {
                        if (user?.role?.toLowerCase() == "admin") {
                          editMembersAccess(
                            e.target.value,
                            data?._id,
                            null,
                            null
                          );
                        } else {
                          setSnackbar({
                            msg: "Only Admin can change roles !",
                            open: true,
                            severity: "error",
                          });
                        }
                      }}
                    >
                      <option value=""></option>
                      {["member", "admin"]?.map((d) => (
                        <option value={d}>{d}</option>
                      ))}
                    </select>
                  </td>
                  <td className="px-3 py-2 text-[#667085] text-center font-[400] text-[14px] ">
                    <div className=" w-[80%] flex items-center justify-center">
                      {data?.permission?.includes("PB") ? (
                        <IoMdCheckmark size={18} color="green" />
                      ) : (
                        <IoMdClose size={18} color="red" />
                      )}
                    </div>
                  </td>
                  <td className="px-3 py-2 text-[#667085] font-[400] text-[14px] ">
                    <div className=" w-[80%] flex items-center justify-center">
                      {data?.permission?.includes("GB") ? (
                        <IoMdCheckmark size={18} color="green" />
                      ) : (
                        <IoMdClose size={18} color="red" />
                      )}
                    </div>
                  </td>
                  {/* <td className="px-3 py-2 text-[#667085] font-[400] text-[14px] ">
                    {data?.authorize ? (
                      <button
                        className="py-[6px]  rounded-[4px] text-[#F00] border border-[#F00] text-[14px] font-[500] inter w-[125px]"
                        onClick={(e) =>
                          editMembersAccess(null, data?._id, false, null)
                        }
                      >
                        Remove Access
                      </button>
                    ) : (
                      <button
                        className="py-[6px]  w-[125px] rounded-[4px] text-green-500 border border-green-500  text-[14px] font-[500] inter"
                        onClick={(e) =>
                          editMembersAccess(null, data?._id, true, null)
                        }
                      >
                        Allow Access
                      </button>
                    )}
                  </td> */}
                  <td className="px-3 py-4 text-[#667085] font-[400] text-[14px] ">
                    {data?.accountStatus?.toLowerCase() === "active" ? (
                      <button
                        className="py-[6px] px-[8px]  rounded-[4px] text-[#F00] border border-[#F00] text-[14px] font-[500] inter w-[200px]"
                        onClick={(e) => {
                          if (user?.role?.toLowerCase() == "admin") {
                            editMembersAccess(
                              null,
                              data?._id,
                              null,
                              "inactive"
                            );
                          } else {
                            setSnackbar({
                              msg: "Only Admin can change roles !",
                              open: true,
                              severity: "error",
                            });
                          }
                        }}
                      >
                        Deactivate this account
                      </button>
                    ) : (
                      <button
                        className="py-[6px] px-[8px] rounded-[4px] text-green-500 border border-green-500  text-[14px] font-[500] inter w-[200px]"
                        onClick={(e) => {
                          if (user?.role?.toLowerCase() == "admin") {
                            editMembersAccess(null, data?._id, null, "active");
                          } else {
                            setSnackbar({
                              msg: "Only Admin can change roles !",
                              open: true,
                              severity: "error",
                            });
                          }
                        }}
                      >
                        Activate this account
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="w-full flex items-center flex-col">
              <div className="w-full py-6 border-b border-[#E0E0E0]  text-center relative">
                <h1 className="text-[#333333] text-[20px] font-[600]">
                  Add Member
                </h1>
                <p
                  className=" bg-[#F1F1F1] py-1 px-1 rounded-[50%] w-fit absolute top-3 cursor-pointer right-4"
                  onClick={handleClose}
                >
                  <RxCross2 size={16} />
                </p>
              </div>

              <form
                className="w-full py-4 px-5  flex flex-col gap-[20px] "
                onSubmit={handleAddMember}
              >
                <div className="flex flex-col gap-[10px]">
                  <label className="text-[#333] text-[14px] font-[500]">
                    Account Name
                  </label>
                  <input
                    type="text"
                    name="account name"
                    value={inputData?.personalDetails?.fullName}
                    onChange={(e) =>
                      setInputData((prev) => ({
                        ...prev,
                        personalDetails: {
                          ...prev?.personalDetails,
                          fullName: e.target.value,
                        },
                      }))
                    }
                    required
                    placeholder="Enter Account Name"
                    className="border border-[#E2E2E5] rounded-[4px] pl-2 h-[40px] focus:outline-none text-[#12112766] text-[12px] capitalize"
                  />
                </div>
                <div className="flex flex-col gap-[10px]">
                  <label className="text-[#333] text-[12px] font-[500]">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={inputData?.email}
                    onChange={(e) =>
                      setInputData((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }))
                    }
                    required
                    placeholder="Enter Email"
                    className="border border-[#E2E2E5] rounded-[4px] pl-2 h-[40px] focus:outline-none text-[#12112766] text-[12px] "
                  />
                </div>
                <div className="flex flex-col gap-[10px]">
                  <label className="text-[#333] text-[14px] font-[500]">
                    User Role
                  </label>
                  <select
                    className="border border-[#E2E2E5] rounded-[4px] pl-2 h-[40px] focus:outline-none text-[#12112766] text-[14px] capitalize"
                    name="supplierAccount"
                    required
                    value={inputData?.role}
                    onChange={(e) =>
                      setInputData((prev) => ({
                        ...prev,
                        role: e.target.value,
                        permission:
                          e.target.value == "member" ? ["PB"] : ["PB", "GB"],
                      }))
                    }
                  >
                    <option value="" selected disabled>
                      Select User Role
                    </option>
                    {["member", "admin"]?.map((d) => (
                      <option value={d}>{d}</option>
                    ))}
                  </select>
                </div>
                <div className="flex flex-col gap-[15px]">
                  <label className="text-[#333] text-[14px] font-[500]">
                    Members Role Accessibility
                  </label>
                  <div className="flex items-center gap-[38px] pl-2">
                    <div className="flex items-center gap-[10px] ">
                      <input
                        type="checkbox"
                        name="project base"
                        id="project base"
                        checked={inputData?.permission?.includes("PB")}
                        // onChange={(e) => {
                        //   setInputData((prev) => ({
                        //     ...prev,
                        //     permission: e.target.checked
                        //       ? [...prev.permission, "PB"]
                        //       : prev?.permission?.filter((d) => d !== "PB"),
                        //   }));
                        // }}
                        // required={!inputData?.permission?.length}
                        placeholder="Enter Email"
                        className="border border-[#E2E2E5] rounded-[4px] pl-2 h-[17px] focus:outline-none w-[17px]"
                      />
                      <label
                        htmlFor="project base"
                        className="text-[#333] text-[14px] font-[500]"
                      >
                        Project Base
                      </label>
                    </div>
                    <div className="flex items-center gap-[10px] ">
                      <input
                        type="checkbox"
                        name="global access"
                        id="global access"
                        // required={!inputData?.permission?.length}
                        checked={inputData?.permission?.includes("GB")}
                        // onChange={(e) => {
                        //   setInputData((prev) => ({
                        //     ...prev,
                        //     permission: e.target.checked
                        //       ? [...prev.permission, "GB"]
                        //       : prev?.permission?.filter((d) => d !== "GB"),
                        //   }));
                        // }}
                        placeholder="Enter Email"
                        className="border border-[#E2E2E5] rounded-[4px] pl-2 h-[17px] focus:outline-none w-[17px]"
                      />
                      <label
                        htmlFor="global access"
                        className="text-[#333] text-[14px] font-[500]"
                      >
                        Global Base
                      </label>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="text-[#fff] inter text-[14px] font-[400] bg-[#0226BE] rounded-[4px] py-2 w-fit self-center px-4 mt-5"
                >
                  Add Member
                </button>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>
      <div className="pt-5"></div>
    </div>
  );
};

export default MyMembers;
