import React from "react";
import PaymentNavbar from "./comp/PaymentNavbar";

const PaymentDetails = () => {
  return (
    <div>
      <PaymentNavbar />
      <div className="flex items-center justify-center space-y-3 py-8 h-[80vh]">
        <div className="border px-4 py-2 ">
          <h1 className="text-center font-[600] text-[20px]">Wire Details</h1>
          <div className="py-3 space-y-3">
            <p className="text-[16px] font-[600] text-gray-400">
              <span className="text-[14px] font-[#666] font-[600] text-[#333]">
                ACCOUNT NAME
              </span>{" "}
              - MIRATS INSIGHTS PRIVATE LIMITED
            </p>
            <p className="text-[16px] font-[600] text-gray-400">
              <span className="text-[14px] font-[#666] font-[600] text-[#333]">
                ACCOUNT NUMBER
              </span>{" "}
              - 125805001709
            </p>
            <p className="text-[16px] font-[600] text-gray-400">
              <span className="text-[14px] font-[#666] font-[600] text-[#333]">
                IFSC CODE
              </span>{" "}
              - ICIC0001258
            </p>
            <p className="text-[16px] font-[600] text-gray-400">
              <span className="text-[14px] font-[#666] font-[600] text-[#333]">
                SWIFT Code
              </span>{" "}
              - ICICINBBXXX
            </p>
            <p className="text-[16px] font-[600] text-gray-400">
              <span className="text-[14px] font-[#666] font-[600] text-[#333]">
                BANK ADDRESS
              </span>{" "}
              - Sitapur Road, Lucknow, Uttar Pradesh
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
