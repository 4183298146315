import React, { useEffect, useRef, useState } from "react";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import TotalSurveyIcon from "../../assets/icons/TotalSurveyIcon.svg";
import TotalLiveSurveyIcon from "../../assets/icons/TotalLiveSurvey.svg";
import TotalPendingSurvey from "../../assets/icons/TotalPendingSurvey.svg";
import TotalCompleatSurvey from "../../assets/icons/TotalCompleateSurvey.svg";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineModeEditOutline, MdDownload } from "react-icons/md";
import { IoMdArchive } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL, calculatePercentageChange } from "../../Utlils";
import Loader from "../../components/Loader";
import NoData from "../../components/NoData";
import { utils, writeFile } from "xlsx";
import DeleteModal from "../../components/DeleteModal";
import { CircularProgress } from "@mui/material";
import Required from "../../components/Required";
import { getValueByCountryRateAndLOI } from "./surveyDetails/components/helperData";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 530,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
};

let dummyfilterData = [
  "All",
  "Live",
  "Awarded",
  "Paused",
  "Completed",
  "billed",
  "Archived",
];

const Survey = () => {
  const rowRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const {
    teamMembers,
    countryData,
    user,
    setSnackbar,
    allSurvey,
    allSurveyGroup,
    getAllSurvey,
    globalLoader,
    getAllSurveyStats,
    allSurveyStats,
    getAllSurveyGroup,
    industryData,
    studyTypesData,
  } = useGlobalContext();
  const [selectRows, setSelectRows] = useState([]);
  const navigate = useNavigate();
  const [tab, setTab] = useState(1);
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const handleOpen = () => setOpen(true);
  const [inputData, setInputData] = useState({
    edit: false,
  });
  const [filters, setFilters] = useState({});
  const handleCloseModal = (e) => setDeleteModal(false);

  // HANDLE CHANGE:
  const handleChange = (e) => {
    let { name, value } = e.target;
    setInputData((prev) => ({ ...prev, [name]: value }));
  };

  //ADD SURVEY & SURVEY GROUP:
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios
      .post(`${BASE_URL}/survey`, {
        ...inputData,
        setup: {
          ...inputData?.setup,
          targetAudience: "B2C",
          businessUnit: user?.organizationName,
        },
        createdBy: user?.organizationId,
      })
      .then((res) => {
        if (inputData?.surveyGroup) {
          axios
            .put(
              `${BASE_URL}/survey-group/add-survey?sid=${res?.data?._id}&sgId=${inputData?.surveyGroup}`
            )
            .then((result) => {
              setLoading(false);
              getAllSurvey();
              getAllSurveyStats();
              rowRef.current.classList.add("flashAnimation");
              setOpen(false);
              setInputData({ edit: false });
              getAllSurveyGroup();
              setSnackbar((prev) => ({
                open: true,
                msg: "Survey Created Successfully !",
                severity: "success",
              }));
            });
        } else {
          setLoading(false);
          getAllSurvey();
          getAllSurveyStats();
          getAllSurveyGroup();
          rowRef.current.classList.add("flashAnimation");
          setOpen(false);
          setInputData({ edit: false });
          setSnackbar((prev) => ({
            open: true,
            msg: "Survey Created Successfully !",
            severity: "success",
          }));
        }
      })
      .catch((er) => {
        setLoading(false);
        getAllSurveyStats();
        getAllSurvey();
        getAllSurveyGroup();
        setOpen(false);
        setInputData({ edit: false });
        console.log(er);
      });
  };
  // console.log(name);

  //EDIT SURVEY & SURVEY GROUP:
  const editSurvey = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      await axios
        .put(`${BASE_URL}/survey?id=${inputData?._id}`, inputData)
        .then((res) => {
          if (
            inputData?.prevSurveyGroup &&
            inputData?.prevSurveyGroup !== inputData?.surveyGroup
          ) {
            axios
              .put(
                `${BASE_URL}/survey-group/remove-survey-and-add-new?oSgId=${inputData?.prevSurveyGroup}&newSgId=${inputData?.surveyGroup}&sid=${inputData?._id}`
              )
              .then(() => {
                setLoading(false);
                getAllSurvey();
                getAllSurveyStats();
                setSelectRows([]);
                rowRef.current.classList.add("flashAnimation");
                setOpen(false);
                setInputData({ edit: false });
                setSnackbar((prev) => ({
                  open: true,
                  msg: "Survey Details Updated Successfully !",
                  severity: "success",
                }));
              });
          } else {
            setLoading(false);
            getAllSurvey();
            getAllSurveyStats();
            setSelectRows([]);
            rowRef.current.classList.add("flashAnimation");
            setOpen(false);
            setInputData({ edit: false });
            setSnackbar((prev) => ({
              open: true,
              msg: "Survey Details Updated Successfully !",
              severity: "success",
            }));
          }
        });
    } catch (error) {
      setLoading(false);
      getAllSurveyStats();
      getAllSurvey();
      setOpen(false);
      setInputData({ edit: false });
      console.log(error);
    }
  };

  //HANDLE FILTER CHANGE DATA:
  const handelFilterChange = (e) => {
    let { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  //GENERATE EXCEL FILE:
  const [xlData, setXlData] = useState([]);
  const generateExcelFile = (orgData) => {
    let generateData = [];
    orgData?.forEach((data) => {
      const surveyStats = allSurveyStats?.find(
        (stats) => stats?.survey?._id == data?._id
      );
      generateData.push({
        "Survey Name": data?.surveyName,
        "Project Number": data?.projectNumber,
        "Survey Number": data?.surveyNumber,
        "Progress/completes": `${surveyStats?.completes}/${data?.expectedMatrix?.expectedCompletes}`,
        "Avg. LOI": `${
          surveyStats?.avgLoi ? surveyStats?.avgLoi + "min" : "0 min"
        }`,
        "Conversation Rate": `${
          surveyStats?.conversion ? surveyStats?.conversion + "%" : " 0 %"
        } `,
        "Incidence Rate": `${surveyStats?.IR ? `${surveyStats?.IR} %` : `0 %`}`,
        "Avg. CPI": `${surveyStats?.avgCPI || 0}`,
        "Drop-off": `${
          surveyStats?.dropRate ? `${surveyStats?.dropRate} %` : `0 `
        }`,
        EPC: `${Number(surveyStats?.epc) || 0}`,
      });
    });
    setXlData(generateData);
  };

  useEffect(() => {
    setInputData((prev) => ({
      ...prev,
      peoples: { projectManager: [user?._id] },
    }));
    if (selectRows?.length) {
      generateExcelFile(selectRows);
    } else {
      generateExcelFile(allSurvey);
    }
  }, [allSurvey, allSurveyStats, selectRows]);
  useEffect(() => {
    if (
      inputData?.expectedMatrix?.expectedCompletionLOI &&
      inputData?.expectedMatrix?.expectedIncidenceRate &&
      inputData?.setup?.country
    ) {
      setInputData((prev) => ({
        ...prev,
        setup: {
          ...prev.setup,
          surveyCpi: getValueByCountryRateAndLOI(
            inputData?.setup?.country,
            inputData?.expectedMatrix?.expectedIncidenceRate,
            inputData?.expectedMatrix?.expectedCompletionLOI
          ),
        },
      }));
    }
  }, [
    inputData?.expectedMatrix?.expectedCompletionLOI &&
      inputData?.expectedMatrix?.expectedIncidenceRate &&
      inputData?.setup?.country,
  ]);

  //DOWNLOAD TO EXCEL DATA:
  const DownloadToExcel = () => {
    var wb = utils.book_new(),
      ws = utils.json_to_sheet(xlData);
    utils.book_append_sheet(wb, ws, "Sheet1");
    setSelectRows([]);
    writeFile(wb, `Survey-Data.xlsx`);
    setSnackbar({
      open: true,
      severity: "success",
      msg: "Excel Sheet Downloaded!",
    });
  };

  // DELETE SURVEYS:
  const deleteSurveys = async (e) => {
    e.preventDefault();
    try {
      selectRows?.forEach(async (data) => {
        await axios
          .put(`${BASE_URL}/survey?id=${data?._id}`, {
            surveyStatus: "archived",
          })
          .then((res) => {
            // console.log(res);
            getAllSurveyStats();
            getAllSurvey();
            setDeleteModal(false);
            setSnackbar((prev) => ({
              open: true,
              msg: "Survey Archived Successfully !",
              severity: "success",
            }));
          });
      });
    } catch (error) {
      // console.log(error);
      getAllSurveyStats();
      setDeleteModal(false);
      getAllSurvey();
      setSnackbar((prev) => ({
        open: true,
        msg: "Something went wrong!",
        severity: "error",
      }));
    }
  };
  // console.log("inputData=>", inputData);
  // console.log("user=>", user);
  // console.log("allSurveyStats=>", allSurveyStats);
  return (
    <div className="w-full flex flex-col gap-[30px] py-6">
      <div className="w-full flex items-center justify-between ">
        <h1 className="text-[#333] text-[32px] font-[500] inter">Surveys</h1>
        <button
          className="bg-[#0226BE] text-[#fff] py-2 px-3 text-[13.3px] inter font-[500] rounded-[8px]"
          onClick={handleOpen}
        >
          Create Target Screening
        </button>
      </div>
      {/* survey card */}
      <div className="w-full grid grid-cols-5 gap-10">
        <div className="surveyCardShadow rounded-[10px] bg-[#fff]  py-4 px-5 flex flex-col gap-2">
          <img src={TotalSurveyIcon} alt="" className="w-[26px] h-[27px]" />
          {/* inconvenience */}
          <h3 className="text-[#000] inter font-[600] text-[18px]">
            {allSurvey?.length}
          </h3>
          <p className="text-[#000A12] text-[14px] font-[500] inter">
            Total Surveys
          </p>
          <h2 className="text-[#5470DE] inter text-[12px] font-[500]">
            {calculatePercentageChange(
              allSurvey?.filter(
                (data) =>
                  new Date()?.getDate() - 1 ==
                    new Date(data?.createdAt)?.getDate() &&
                  new Date()?.getMonth() ==
                    new Date(data?.createdAt)?.getMonth() &&
                  new Date()?.getFullYear() ==
                    new Date(data?.createdAt)?.getFullYear()
              )?.length,
              allSurvey?.filter(
                (data) =>
                  new Date()?.getDate() ==
                    new Date(data?.createdAt)?.getDate() &&
                  new Date()?.getMonth() ==
                    new Date(data?.createdAt)?.getMonth() &&
                  new Date()?.getFullYear() ==
                    new Date(data?.createdAt)?.getFullYear()
              )?.length
            ) || 0}
            % from yesterday
          </h2>
        </div>
        <div className="surveyCardShadow rounded-[10px] bg-[#fff]  py-4 px-5 flex flex-col gap-2">
          <img src={TotalLiveSurveyIcon} alt="" className="w-[26px] h-[27px]" />
          <h3 className="text-[#000] inter font-[600] text-[18px]">
            {" "}
            {
              allSurvey?.filter((d) => d?.surveyStatus?.toLowerCase() == "live")
                ?.length
            }
          </h3>
          <p className="text-[#000A12] text-[14px] font-[500] inter">
            Total Live Survey
          </p>
          <h2 className="text-[#20AEF3] inter text-[12px] font-[500]">
            {calculatePercentageChange(
              allSurvey
                ?.filter((d) => d?.surveyStatus?.toLowerCase() == "live")
                ?.filter(
                  (data) =>
                    new Date()?.getDate() - 1 ==
                      new Date(data?.createdAt)?.getDate() &&
                    new Date()?.getMonth() ==
                      new Date(data?.createdAt)?.getMonth() &&
                    new Date()?.getFullYear() ==
                      new Date(data?.createdAt)?.getFullYear()
                )?.length,
              allSurvey
                ?.filter((d) => d?.surveyStatus?.toLowerCase() == "live")
                ?.filter(
                  (data) =>
                    new Date()?.getDate() ==
                      new Date(data?.createdAt)?.getDate() &&
                    new Date()?.getMonth() ==
                      new Date(data?.createdAt)?.getMonth() &&
                    new Date()?.getFullYear() ==
                      new Date(data?.createdAt)?.getFullYear()
                )?.length
            ) || 0}
            % from yesterday
          </h2>
        </div>
        <div className="surveyCardShadow rounded-[10px] bg-[#fff]  py-4 px-5 flex flex-col gap-2">
          <img src={TotalCompleatSurvey} alt="" className="w-[26px] h-[27px]" />
          <h3 className="text-[#000] inter font-[600] text-[18px]">
            {" "}
            {
              allSurvey?.filter(
                (d) => d?.surveyStatus?.toLowerCase() == "completed"
              )?.length
            }
          </h3>
          <p className="text-[#000A12] text-[14px] font-[500] inter">
            Total Completed Surveys
          </p>
          <h2 className="text-[#FEB95A] inter text-[12px] font-[500]">
            {calculatePercentageChange(
              allSurvey
                ?.filter((d) => d?.surveyStatus?.toLowerCase() == "completed")
                ?.filter(
                  (data) =>
                    new Date()?.getDate() - 1 ==
                      new Date(data?.createdAt)?.getDate() &&
                    new Date()?.getMonth() ==
                      new Date(data?.createdAt)?.getMonth() &&
                    new Date()?.getFullYear() ==
                      new Date(data?.createdAt)?.getFullYear()
                )?.length,
              allSurvey
                ?.filter((d) => d?.surveyStatus?.toLowerCase() == "completed")
                ?.filter(
                  (data) =>
                    new Date()?.getDate() ==
                      new Date(data?.createdAt)?.getDate() &&
                    new Date()?.getMonth() ==
                      new Date(data?.createdAt)?.getMonth() &&
                    new Date()?.getFullYear() ==
                      new Date(data?.createdAt)?.getFullYear()
                )?.length
            ) || 0}
            % from yesterday
          </h2>
        </div>
        <div className="surveyCardShadow rounded-[10px] bg-[#fff]  py-4 px-5 flex flex-col gap-2">
          <img src={TotalPendingSurvey} alt="" className="w-[26px] h-[27px]" />
          <h3 className="text-[#000] inter font-[600] text-[18px]">
            {
              allSurvey?.filter(
                (d) => d?.surveyStatus?.toLowerCase() == "pending"
              )?.length
            }
          </h3>
          <p className="text-[#000A12] text-[14px] font-[500] inter">
            Total Pending Surveys
          </p>
          <h2 className="text-[#25E8CE] inter text-[12px] font-[500]">
            {calculatePercentageChange(
              allSurvey
                ?.filter((d) => d?.surveyStatus?.toLowerCase() == "pending")
                ?.filter(
                  (data) =>
                    new Date()?.getDate() - 1 ==
                      new Date(data?.createdAt)?.getDate() &&
                    new Date()?.getMonth() ==
                      new Date(data?.createdAt)?.getMonth() &&
                    new Date()?.getFullYear() ==
                      new Date(data?.createdAt)?.getFullYear()
                )?.length,
              allSurvey
                ?.filter((d) => d?.surveyStatus?.toLowerCase() == "pending")
                ?.filter(
                  (data) =>
                    new Date()?.getDate() ==
                      new Date(data?.createdAt)?.getDate() &&
                    new Date()?.getMonth() ==
                      new Date(data?.createdAt)?.getMonth() &&
                    new Date()?.getFullYear() ==
                      new Date(data?.createdAt)?.getFullYear()
                )?.length
            ) || 0}
            % from yesterday
          </h2>
        </div>
      </div>
      {/* filters row */}
      <div className="flex items-center justify-between py-3">
        <div className="flex items-center gap-5">
          <select
            className="border border-[#E0E0E0] py-2 px-4 rounded-[8px] text-[#666] text-[14px] focus:outline-none w-[170px]"
            onChange={handelFilterChange}
            value={filters?.industry || ""}
            name="industry"
          >
            <option value="" selected>
              Industry Type
            </option>
            {industryData?.map((data, key) => (
              <option key={key} value={data?.value}>
                {data?.label}
              </option>
            ))}
          </select>
          <select
            className="border border-[#E0E0E0] py-2 px-4 rounded-[8px] text-[#666] text-[14px] focus:outline-none w-[170px]"
            onChange={handelFilterChange}
            value={filters?.projectManager || ""}
            name="projectManager"
          >
            <option value="" selected>
              Project Manager
            </option>
            {teamMembers?.map((data, key) => (
              <option value={data?._id} key={key}>
                {data?.personalDetails?.fullName}
              </option>
            ))}
          </select>
          <select
            className="border border-[#E0E0E0] py-2 px-4 rounded-[8px] text-[#666] text-[14px] focus:outline-none w-[170px]"
            onChange={handelFilterChange}
            name="studyType"
            value={filters?.studyType || ""}
          >
            <option value="" selected>
              Study Type
            </option>
            {studyTypesData?.map((data, ind) => (
              <option value={data?.value} key={ind}>
                {data?.label}
              </option>
            ))}
          </select>
          <select
            className="border border-[#E0E0E0] py-2 px-4 rounded-[8px] text-[#666] text-[14px] focus:outline-none w-[170px]"
            onChange={handelFilterChange}
            value={filters?.country || ""}
            name="country"
          >
            <option value="" selected>
              Select Country{" "}
            </option>
            {countryData?.isLoading ? (
              <option value="" disabled>
                Loading Countries...
              </option>
            ) : countryData?.error ? (
              <option value="" disabled>
                Error while fetching countries try refreshing the page
              </option>
            ) : (
              countryData?.data?.map((data, key) => (
                <option value={data?.name} key={key}>
                  {data?.name}
                </option>
              ))
            )}
          </select>
          <p
            className="text-[14px] underline underline-offset-4 cursor-pointer"
            onClick={(e) => setFilters({})}
          >
            Clear filters
          </p>
        </div>

        <div className="w-[25%]">
          <input
            type="text"
            className="w-full border border-[#E0E0E0] rounded-[8px] focus:outline-none py-2 px-4 text-[12px] h-[40px]"
            name="search"
            value={filters?.search || ""}
            onChange={handelFilterChange}
            placeholder="Search Survey Name , Project Number , Survey Number"
          />
        </div>
      </div>

      {/* table  */}

      <div>
        {/* tabs */}
        <div className="w-full tableBorder flex items-center justify-between px-10 py-3 mt-[5px]">
          {dummyfilterData?.map((data, ind) => (
            <h1
              key={ind}
              className={`text-[#000] text-[16px] font-[400] cursor-pointer transition-all ease-linear capitalize ${
                tab == ind &&
                `text-[#0226BE] font-[700] underline decoration-2 decoration-[#0226BE] underline-offset-[17px]`
              }`}
              onClick={(e) => {
                setTab(ind);
                setSelectRows([]);
              }}
            >
              {data} (
              {ind == 0
                ? allSurvey?.length
                : allSurvey?.filter(
                    (d) => d?.surveyStatus?.toLowerCase() == data?.toLowerCase()
                  )?.length}
              )
            </h1>
          ))}
        </div>
        <div className="w-full tableBorder flex items-center justify-between px-6 py-5 ">
          <div className="flex items-center gap-4">
            <input
              type="checkbox"
              className="w-[15px] h-[15px] cursor-pointer ml-[-10px]"
              checked={selectRows?.length}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectRows(
                    allSurvey
                      ?.filter(
                        (data) =>
                          data?.surveyStatus?.toLowerCase() ===
                            dummyfilterData?.[tab]?.toLowerCase() ||
                          dummyfilterData[tab] === "All"
                      )
                      ?.filter((data) =>
                        filters?.industry
                          ? data?.setup?.industry === filters?.industry
                          : data
                      )
                      .filter((data) =>
                        filters?.projectManager
                          ? data?.peoples?.projectManager?._id ===
                            filters?.projectManager
                          : data
                      )
                      ?.filter((data) =>
                        filters?.businessUnit
                          ? data?.setup?.businessUnit?.toLowerCase() ===
                            filters?.businessUnit?.toLowerCase()
                          : data
                      )
                      ?.filter((data) =>
                        filters?.country
                          ? data?.setup?.country?.toLowerCase() ===
                            filters?.country?.toLowerCase()
                          : data
                      )
                      ?.filter((data) =>
                        filters?.search
                          ? data?.surveyName
                              ?.toLowerCase()
                              ?.includes(filters.search?.toLowerCase()) ||
                            String(data?.projectNumber)
                              ?.toLowerCase()
                              ?.includes(filters.search?.toLowerCase()) ||
                            String(data?.surveyNumber)
                              ?.toLowerCase()
                              ?.includes(filters.search?.toLowerCase())
                          : data
                      )
                  );
                } else {
                  setSelectRows([]);
                }
              }}
            />
            {selectRows?.length ? (
              <span className="text-[15px] text-[#0226BE]">
                Selected: ({selectRows?.length})
              </span>
            ) : (
              <></>
            )}
            {selectRows?.length === 1 ? (
              <MdOutlineModeEditOutline
                size={24}
                className="cursor-pointer"
                onClick={(e) => {
                  if (selectRows?.length && selectRows?.length == 1) {
                    setOpen(true);
                    setInputData({
                      edit: true,
                      ...selectRows?.[0],
                    });
                  } else {
                    setSnackbar((prev) => ({
                      open: true,
                      msg: `Please Select one survey to edit!`,
                      severity: "warning",
                    }));
                  }
                }}
              />
            ) : (
              ""
            )}

            <MdDownload
              size={24}
              onClick={(e) => {
                DownloadToExcel();
              }}
              className="cursor-pointer"
            />

            <IoMdArchive
              size={24}
              className="cursor-pointer"
              onClick={(e) => {
                if (selectRows?.length) {
                  setDeleteModal(true);
                } else {
                  setSnackbar((prev) => ({
                    open: true,
                    msg: "Please Select at least one survey to Archive!",
                    severity: "warning",
                  }));
                }
              }}
            />
          </div>
        </div>
        <div className="w-full tableBorder flex flex-col">
          {!globalLoader?.allSurvey ? (
            allSurvey?.length ? (
              <div className="w-full overflow-x-auto containerScroll">
                <table
                  className="whitespace-nowrap text-left w-full"
                  id="table-to-xls-DATA"
                >
                  <thead className=" h-[45px] ">
                    <tr className="py-3">
                      <th className="text-[#464545] text-[12px] font-[600] px-3">
                        {/* <input
                          type="checkbox"
                          className="w-[15px] h-[15px] cursor-pointer"
                        /> */}
                      </th>
                      <th className="text-[#464545] text-[12px] font-[600] px-3">
                        Survey Name/ Survey Number/ Project Number
                      </th>
                      <th className="text-[#666] text-[12px] font-[600] px-3">
                        Progress
                      </th>
                      <th className="text-[#666] text-[12px] font-[600] px-3">
                        Avg. LOI
                      </th>
                      <th className="text-[#666] text-[12px] font-[600] px-3">
                        Conversation Rate
                      </th>
                      <th className="text-[#666] text-[12px] font-[600] px-3">
                        Incidence Rate
                      </th>
                      <th className="text-[#666] text-[12px] font-[600] px-3">
                        Avg. CPI
                      </th>
                      <th className="text-[#666] text-[12px] font-[600] px-3">
                        Drop-off
                      </th>
                      <th className="text-[#666] text-[12px] font-[600] px-3">
                        EPC
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allSurvey
                      ?.filter(
                        (data) =>
                          data?.surveyStatus?.toLowerCase() ===
                            dummyfilterData?.[tab]?.toLowerCase() ||
                          dummyfilterData[tab] === "All"
                      )
                      ?.filter((data) =>
                        filters?.industry
                          ? data?.setup?.industry === filters?.industry
                          : data
                      )
                      .filter((data) =>
                        filters?.projectManager
                          ? data?.peoples?.projectManager?._id ===
                            filters?.projectManager
                          : data
                      )
                      ?.filter((data) =>
                        filters?.studyType
                          ? data?.setup?.studyType === filters?.studyType
                          : data
                      )
                      ?.filter((data) =>
                        filters?.country
                          ? data?.setup?.country?.toLowerCase() ===
                            filters?.country?.toLowerCase()
                          : data
                      )
                      ?.filter((data) =>
                        filters?.search
                          ? data?.surveyName
                              ?.toLowerCase()
                              ?.includes(filters.search?.toLowerCase()) ||
                            String(data?.projectNumber)
                              ?.toLowerCase()
                              ?.includes(filters.search?.toLowerCase()) ||
                            String(data?.surveyNumber)
                              ?.toLowerCase()
                              ?.includes(filters.search?.toLowerCase())
                          : data
                      )
                      ?.map((data, ind) => (
                        <>
                          {allSurveyStats?.map((stats) => {
                            if (data?._id === stats?.survey?._id) {
                              return (
                                <tr
                                  key={ind}
                                  className=" tableBorder transition-all cursor-pointer hover:bg-[#f6f9ff] "
                                  ref={rowRef}
                                  onClick={(e) => {
                                    navigate(`${data?._id}/details`);
                                  }}
                                >
                                  <td className="px-3 py-2 text-[#667085] font-[400] text-[14px] ">
                                    <div className="flex items-center gap-[11px] text-[20px] text-[#333333]">
                                      <input
                                        type="checkbox"
                                        className="w-[15px] h-[15px] cursor-pointer"
                                        checked={selectRows?.some(
                                          (se) => se?._id == data?._id
                                        )}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            setSelectRows((prev) => [
                                              ...prev,
                                              data,
                                            ]);
                                          } else {
                                            setSelectRows(
                                              selectRows?.filter(
                                                (sc) => sc?._id !== data?._id
                                              )
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  </td>
                                  <td className="px-3 py-2 text-[#667085] font-[400] text-[14px] ">
                                    <div className="flex flex-col gap-[13px] py-1">
                                      <h2 className="text-[#667085] font-[500] text-[13px]">
                                        {data?.surveyName}
                                      </h2>
                                      <p className="text-[#667085] font-[400] text-[13px]">
                                        PN#{data?.projectNumber} SN#
                                        {data?.surveyNumber}
                                      </p>
                                    </div>
                                  </td>
                                  <td className="px-3 py-2 text-[#667085] font-[400] text-[14px] ">
                                    {stats?.completes} /{" "}
                                    {data?.expectedMatrix?.expectedCompletes}
                                  </td>
                                  <td className="px-3 py-2 text-[#667085] font-[400] text-[14px] ">
                                    {stats?.avgLoi
                                      ? `${stats?.avgLoi} min`
                                      : "0 min"}
                                  </td>
                                  <td className="px-3 py-2 text-[#667085] font-[400] text-[14px] ">
                                    {stats?.conversion
                                      ? `${Number(stats?.conversion)?.toFixed(
                                          2
                                        )} %`
                                      : "0 %"}
                                  </td>
                                  <td className="px-3 py-2 text-[#667085] font-[400] text-[14px] ">
                                    {stats?.IR
                                      ? `${Number(stats?.IR)?.toFixed(2)} %`
                                      : `0 %`}
                                  </td>
                                  <td className="px-3 py-2 text-[#667085] font-[400] text-[14px] ">
                                    $ {stats?.avgCPI || 0}
                                  </td>
                                  <td className="px-3 py-2 text-[#667085] font-[400] text-[14px] ">
                                    {stats?.dropRate
                                      ? `${stats?.dropRate} %`
                                      : `0 `}
                                  </td>
                                  <td className="px-3 py-2 text-[#667085] font-[400] text-[14px] ">
                                    {Number(stats?.epc) || 0}
                                  </td>
                                </tr>
                              );
                            }
                          })}
                        </>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <NoData />
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>

      {/* create modal*/}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => {
          setOpen(false);
          setInputData({ edit: false });
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={{ ...style }}>
            <div className="w-full flex items-center flex-col gap-[10px]  h-[500px] md:h-[550px] xl:h-[600px] ">
              <div className="w-full py-5 border-b border-[#E0E0E0]  text-center relative">
                <h1 className="text-[#333333] text-[20px] font-[600]">
                  {inputData?.edit ? "Edit" : "Create"} Target Screener Survey
                </h1>
                <p
                  className=" bg-[#F1F1F1] py-1 px-1 rounded-[50%] w-fit absolute top-3 cursor-pointer right-4"
                  onClick={() => {
                    setOpen(false);
                    setInputData({ edit: false });
                    setSelectRows([]);
                  }}
                >
                  <RxCross2 size={16} />
                </p>
              </div>
              <form
                className="w-full flex flex-col max-h-[85%]   items-center"
                onSubmit={inputData?.edit ? editSurvey : handleSubmit}
              >
                <div className="w-full py-2 px-5  flex flex-col gap-[15px] max-h-[90%] overflow-y-auto containerScroll ">
                  <div className="flex flex-col gap-[10px]">
                    <label
                      htmlFor="SurveyName"
                      className="text-[#333] text-[14px] font-[500]"
                    >
                      Survey Name <Required />
                    </label>
                    <input
                      type="text"
                      name="surveyName"
                      required
                      onChange={handleChange}
                      value={inputData?.surveyName}
                      className="border border-[#E2E2E5] rounded-[4px] pl-3 h-[40px] focus:outline-none"
                    />
                  </div>
                  {/* survey group start */}
                  <div className="flex flex-col gap-[10px]">
                    <label
                      htmlFor="SurveyName"
                      className="text-[#333] text-[14px] font-[500]"
                    >
                      Survey Group
                    </label>
                    <div className="flex w-full items-center relative">
                      <select
                        className="w-full border border-[#E2E2E5] rounded-[4px] h-[40px] placeholder:pl-3 placeholder:text-[14px] placeholder:text-[#12112766] focus:outline-none pl-3 text-[14px]"
                        value={
                          inputData?.edit
                            ? inputData?.surveyGroup?._id
                            : inputData?.surveyGroup
                        }
                        onChange={(e) => {
                          if (inputData?.edit && !inputData?.prevSurveyGroup) {
                            setInputData((prev) => ({
                              ...prev,
                              prevSurveyGroup: inputData?.surveyGroup?._id,
                              surveyGroup: e.target.value,
                            }));
                          } else {
                            setInputData((prev) => ({
                              ...prev,
                              surveyGroup: e.target.value,
                            }));
                          }
                        }}
                      >
                        <option selected>Select Survey Group</option>
                        {allSurveyGroup?.map((d, i) => (
                          <option value={d?._id} key={i}>
                            {d?.groupName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* survey group end */}
                  <div className="flex flex-col gap-[10px]">
                    <label
                      htmlFor="SurveyName"
                      className="text-[#333] text-[14px] font-[500]"
                    >
                      Project Manager <Required />
                    </label>
                    <select
                      className="border border-[#E2E2E5] rounded-[4px] h-[40px] text-[14px] pl-3"
                      required
                      value={
                        inputData?.edit
                          ? inputData?.peoples?.projectManager?._id
                          : inputData?.peoples?.projectManager
                      }
                      onChange={(e) =>
                        setInputData((prev) => ({
                          ...prev,
                          peoples: {
                            ...prev.peoples,
                            projectManager: [e.target.value],
                          },
                        }))
                      }
                    >
                      <option value="">Select Project Manger</option>
                      {teamMembers?.map((data, key) => (
                        <option key={key} value={data?._id}>
                          {data?.personalDetails?.fullName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="grid grid-cols-3 gap-[10px]">
                    {/* no of competes */}
                    <div className="w-full  space-y-2">
                      <label
                        htmlFor="SurveyName"
                        className="text-[#333] text-[14px] font-[500]"
                      >
                        No. of Completes <Required />
                      </label>
                      <input
                        type="number"
                        placeholder="10"
                        required
                        min="10"
                        value={
                          inputData?.expectedMatrix?.expectedCompletes || ""
                        }
                        onKeyDown={(e) => {
                          if (e.key === "e" || e.key === "+" || e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          if (e.target.value <= 1000000) {
                            setInputData((prev) => ({
                              ...prev,
                              expectedMatrix: {
                                ...prev.expectedMatrix,
                                expectedCompletes: Number(e.target.value),
                              },
                            }));
                          } else {
                            setSnackbar({
                              open: true,
                              msg: "No of completes should be less than or equal to 1000000",
                              severity: "error",
                            });
                          }
                        }}
                        className="border border-[#E2E2E5] text-myBlack rounded-[4px] h-[40px] pl-3 focus:outline-none w-full"
                      />
                    </div>
                    {/* expected loi */}
                    <div className="w-full  space-y-2">
                      <label
                        htmlFor="expectedCompletionLOI"
                        className="text-[#333] text-[14px] font-[500]"
                      >
                        Expected LOI (mins) <Required />
                      </label>
                      <input
                        type="number"
                        placeholder="15"
                        step="0.01"
                        required
                        min="1"
                        value={
                          inputData?.expectedMatrix?.expectedCompletionLOI || ""
                        }
                        onKeyDown={(e) => {
                          if (e.key === "e" || e.key === "+" || e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          if (e.target.value <= 90) {
                            setInputData((prev) => ({
                              ...prev,
                              expectedMatrix: {
                                ...prev.expectedMatrix,
                                expectedCompletionLOI: Number(e.target.value),
                              },
                            }));
                          } else {
                            setSnackbar({
                              open: true,
                              msg: "Expected LOI should be less than or equal to 90 mins",
                              severity: "error",
                            });
                          }
                        }}
                        className="border border-[#E2E2E5] text-myBlack rounded-[4px] h-[40px] pl-3 focus:outline-none w-full"
                      />
                    </div>
                    {/* expected IR */}
                    <div className="w-full  space-y-2">
                      <label
                        htmlFor="expectedIncidenceRate"
                        className="text-[#333] text-[14px] font-[500]"
                      >
                        Expected IR(%) <Required />
                      </label>
                      <input
                        type="number"
                        placeholder="25"
                        step="0.01"
                        required
                        min="1"
                        value={
                          inputData?.expectedMatrix?.expectedIncidenceRate || ""
                        }
                        onKeyDown={(e) => {
                          if (e.key === "e" || e.key === "+" || e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          if (e.target.value <= 100) {
                            setInputData((prev) => ({
                              ...prev,
                              expectedMatrix: {
                                ...prev.expectedMatrix,
                                expectedIncidenceRate: Number(e.target.value),
                              },
                            }));
                          } else {
                            setSnackbar({
                              open: true,
                              msg: "Expected IR should be less than or equal to 100 %",
                              severity: "error",
                            });
                          }
                        }}
                        className="border border-[#E2E2E5] w-full text-myBlack rounded-[4px] h-[40px] pl-3 focus:outline-none"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-[10px]">
                    <label
                      htmlFor="SurveyName"
                      className="text-[#333] text-[14px] font-[500]"
                    >
                      Country <Required />
                    </label>
                    <select
                      className="border border-[#E2E2E5] rounded-[4px] h-[40px] text-[14px] text-myBlack pl-3"
                      required
                      value={inputData?.setup?.countryCode}
                      onChange={(e) =>
                        setInputData((prev) => ({
                          ...prev,
                          setup: {
                            ...prev.setup,
                            country:
                              e.target.options[e.target.selectedIndex].text,
                            countryCode: e.target.value,
                          },
                        }))
                      }
                    >
                      <option value="">Select Country </option>
                      {countryData?.isLoading ? (
                        <option value="" disabled>
                          Loading Countries...
                        </option>
                      ) : countryData?.error ? (
                        <option value="" disabled>
                          Error while fetching countries try refreshing the page
                        </option>
                      ) : (
                        countryData?.data?.map((data, key) => (
                          <option value={data?.iso2} key={key}>
                            {data?.name}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                  <div className="flex flex-col gap-[10px]">
                    <label
                      htmlFor="surveyCpi"
                      className="text-[#333] text-[14px] font-[500]"
                    >
                      Survey CPI (USD) <Required />
                    </label>
                    <input
                      type="number"
                      placeholder="2.5"
                      step="0.01"
                      min="0.50"
                      required
                      onKeyDown={(e) => {
                        if (e.key === "e" || e.key === "+" || e.key === "-") {
                          e.preventDefault();
                        }
                      }}
                      value={inputData?.setup?.surveyCpi || ""}
                      onChange={(e) => {
                        if (e.target.value <= 499) {
                          setInputData((prev) => ({
                            ...prev,
                            setup: {
                              ...prev.setup,
                              surveyCpi: Number(e.target.value),
                            },
                          }));
                        } else {
                          setSnackbar({
                            msg: "Survey CPI should be less than or equal to 499",
                            open: true,
                            severity: "error",
                          });
                        }
                      }}
                      className="border border-[#E2E2E5] text-myBlack rounded-[4px] h-[40px] pl-3 focus:outline-none w-full"
                    />
                  </div>
                </div>
                <div className="w-full flex px-5 pb-2">
                  {loading ? (
                    <div className="w-full flex justify-center  px-5 bg-[#0226BE] py-2 mt-4">
                      <CircularProgress className="text-center" size={23} />
                    </div>
                  ) : (
                    <button
                      type="submit"
                      className="w-full  text-[#fff] inter text-[16px] font-[400] bg-[#0226BE] rounded-[4px] py-2 mt-4  "
                    >
                      {inputData?.edit ? "Edit" : "Create"} Survey
                    </button>
                  )}
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* DELETE MODAL */}
      <DeleteModal
        open={deleteModal}
        handleClose={handleCloseModal}
        btnText="Archive"
        oparationText="Archive"
        text={
          selectRows?.length
            ? [...selectRows?.map((data) => data?.surveyName)]
            : [...allSurvey?.map((data) => data?.surveyName)]
        }
        handleClick={(e) => deleteSurveys(e)}
      />
    </div>
  );
};

export default Survey;
