import { useState } from "react";

const ProgressBar = ({ progress }) => {
  const [style, setStyle] = useState({});
  setTimeout(() => {
    const newStyle = {
      opacity: 1,
      width: `${progress}%`,
    };

    setStyle(newStyle);
  }, 200);

  return (
    <div className="progress">
      <div className="progress_done" style={style}>
        {/* <span>{progress}%</span> */}
      </div>
    </div>
  );
};

export default ProgressBar;
