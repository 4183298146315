import React from "react";
import { useParams } from "react-router-dom";
import { getCurrentSurvey } from "../Utlils";

const EncryptStart = () => {
  const { surveyId, encSupplierId, orgId } = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const { r, t, tk, p, org } = Object.fromEntries(urlSearchParams.entries());
  console.log(r, t, tk, p, org);
  // console.log(surveyId, encSupplierId);
  getCurrentSurvey(surveyId)
    .then((survey) => {
      if (survey === undefined) {
        console.log("survey not found");
        return;
      }
      if (t == 0) {
        let originalUrl = `${window.location.origin}/DIY/${
          survey?._id
        }/${encSupplierId}/${orgId}/lightningStart?SRCID=${encSupplierId}&RID=${
          tk ? tk : r
        }${p ? `&p=${p}` : ""}${org ? `&org=${org}` : ""}`;
        window.location.href = originalUrl;
      } else {
        let originalUrl = `${window.location.origin}/DIY/${
          survey?._id
        }/${encSupplierId}/${orgId}/lightningStart?SRCID=${encSupplierId}&RID=${
          tk ? tk : r
        }&gamma=alpha${p ? `&p=${p}` : ""}${org ? `&org=${org}` : ""}`;
        window.location.href = originalUrl;
      }
    })
    .catch((err) => {
      console.log("something went wrong in url");
    });
  return (
    <div className="flex items-center justify-center h-screen">
      <h1>Loading...</h1>
    </div>
  );
};

export default EncryptStart;
