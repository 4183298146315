import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import { useGlobalContext } from "../../../../Contexts/GlobalContext";
Chart.register(CategoryScale);
const Reports = ({ data, id, singleSurveyStats }) => {
  const { user, organization } = useGlobalContext();
  const completesKeys = singleSurveyStats?.completes
    ? Object.keys(singleSurveyStats.completes)
    : [];
  const entrantsKeys = singleSurveyStats?.entrants
    ? Object.keys(singleSurveyStats.entrants)
    : [];
  const prescreenKeys = singleSurveyStats?.prescreens
    ? Object.keys(singleSurveyStats.prescreens)
    : [];
  const labels = [...new Set([...entrantsKeys, ...completesKeys])];
  const [tab, setTab] = useState(["ENTRANTS"]);
  const [graphData, setGraphData] = useState([]);
  useEffect(() => {
    setGraphData([
      {
        label: "Entrants",
        data: entrantsKeys?.map((d) => singleSurveyStats?.entrants?.[d]),
        backgroundColor: "#EE964BCC",
        barThickness: 60,
      },
    ]);
  }, [entrantsKeys?.length]);

  const BarChart = () => {
    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            drawOnChartArea: false,
          },
        },
        y: {
          border: {
            display: false,
            dash: [10, 10, 10, 10],
          },
        },
      },
    };
    const data = {
      labels: labels,
      // labels: ["jan", "feb", "march", "april"],
      datasets: graphData,
    };
    return <Bar options={options} data={data} height={100} />;
  };

  const RateChart = ({ title, alertLevel, expected, avgDate, w1, w2 }) => {
    // console.log({ title, w1, w2, expected, avgDate });
    return (
      <div className="w-full flex flex-col gap-[15px]">
        <div className="w-full flex items-center justify-between">
          <h1 className="text-[#666] font-[600] text-[20px]">{title}</h1>
          <div className="flex items-center gap-[19px]">
            <h1 className="text-[#000] font-[600] text-[12px]">Alert Level</h1>
            <p className="text-[#F68D8D] text-[12px] border border-[#F68D8D] bg-[#FFF1F1] rounded-[8px] py-[4px] px-[8px]">
              {alertLevel}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-[20px]">
          <div className="flex items-center gap-[10px]">
            <p className="text-[12px] text-[#888] font-[600]">Expected :</p>
            <p className="text-[12px] text-[#0226BED9] font-[600]">
              {expected || 0} %
            </p>
          </div>
          <div className="flex items-center gap-[10px] border-l border-[#888] pl-3">
            <p className="text-[12px] text-[#888] font-[600]">
              Average to Date :
            </p>
            <p className="text-[12px] text-[#F68D8D] font-[600]">
              {Number(avgDate)?.toFixed(2) || 0} %
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-[5px]">
          <h1 className="text-[#666] font-[600] text-[12px]">To Date</h1>
          <div className=" w-full">
            <div className="border-b border-[#ccc] h-[18px] w-[75%] bg-[#F5F5F5] rounded-tl-[8px] rounded-tr-[8px] overflow-hidden">
              {/* {w1 ? ( */}
              <div
                style={{ width: (avgDate || 0) + "%" }}
                className={`bg-[#F68D8D] h-[100%] rounded-tl-[8px] `}
              ></div>
              {/* ) : (
                ""
              )} */}
            </div>
            <div className=" h-[18px] w-[75%] bg-[#F5F5F5] rounded-bl-[8px] rounded-br-[8px]">
              {/* {w2 ? ( */}
              <div
                style={{ width: (expected || 0) + "%" }}
                className={`bg-[#0226BED9] h-[100%] rounded-bl-[8px] `}
              ></div>
              {/* // ) : ( // "" // )} */}
            </div>
          </div>
          <h1 className="text-[#666] font-[600] text-[12px]">Expected</h1>
        </div>
      </div>
    );
  };
  const LineChart = ({ title, alertLevel, expected, avgDate, data }) => {
    // console.log(title, data);
    const LineChartOptions = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: "bottom",
        },
        title: {
          display: false,
          text: "",
        },
      },
      scales: {
        x: {
          stacked: true,

          grid: {
            drawOnChartArea: true,
            // display: false,
            color: "#E0E0E0",
          },
          ticks: {
            font: {
              size: 14,
            },
            color: "#ACACAC",
          },
          border: {
            display: false,
            dash: [18, 10, 10, 10],
          },
        },

        y: {
          grid: {
            drawOnChartArea: false,
            display: false,
          },

          ticks: {
            font: {
              size: 14,
            },
            callback: function (value, index, ticks) {
              return Number(value)?.toFixed(2) + " Min";
            },
            // display: false,
            color: "#666",
          },
          border: {
            display: false,
          },
        },
      },
    };

    return (
      <div className="w-full flex flex-col gap-[15px]">
        <div className="w-full flex items-center justify-between">
          <h1 className="text-[#666] font-[600] text-[20px]">{title}</h1>
          <div className="flex items-center gap-[19px]">
            <h1 className="text-[#000] font-[600] text-[12px]">Alert Level</h1>
            <p className="text-[#F68D8D] text-[12px] border border-[#F68D8D] bg-[#FFF1F1] rounded-[8px] py-[4px] px-[8px]">
              {alertLevel}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-[20px]">
          <div className="flex items-center gap-[10px]">
            <p className="text-[12px] text-[#888] font-[600]">Expected :</p>
            <p className="text-[12px] text-[#0226BED9] font-[600]">
              {expected} Min
            </p>
          </div>
          <div className="flex items-center gap-[10px] border-l border-[#888] pl-3">
            <p className="text-[12px] text-[#888] font-[600]">
              Average to Date :
            </p>
            <p className="text-[12px] text-[#F68D8D] font-[600]">
              {Number(avgDate)?.toFixed(2) || 0} Min
            </p>
          </div>
        </div>

        <Line options={LineChartOptions} data={data} />
      </div>
    );
  };
  const terminationLOIData = {
    labels: Object?.keys(singleSurveyStats?.terminationLOI || {})
      ?.map((d) =>
        new Date(d)?.toLocaleDateString("en-in", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        })
      )
      ?.slice(0, 4),
    datasets: [
      {
        axis: "y",
        label: " Average Length Of Interview ",
        data: Object?.values(singleSurveyStats?.terminationLOI || {})
          ?.map((d) => (d === null ? 0 : d))
          ?.slice(0, 4),
        fill: false,
        borderColor: "#E0E0E0",
        tension: 0.3,
        borderWidth: 1,
        pointRadius: 9,
        pointBackgroundColor: Object?.values(
          singleSurveyStats?.terminationLOI || {}
        )
          ?.map((d) =>
            d <
            Object?.values(singleSurveyStats?.terminationLOI || {})
              ?.slice(0, 4)
              ?.reduce((a, c) => (a += c), 0) /
              Object?.values(singleSurveyStats?.terminationLOI || {})?.slice(
                0,
                4
              )?.length
              ? "#F68D8D"
              : "#0226BED9"
          )
          ?.slice(0, 4),
        pointHoverRadius: 10,
      },
      {
        axis: "y",
        label: " Expected Length Of Interview ",
        data: [
          Object?.values(singleSurveyStats?.terminationLOI || {})
            ?.slice(0, 4)
            ?.reduce((a, c) => (a += c), 0) /
            Object?.values(singleSurveyStats?.terminationLOI || {})?.slice(0, 4)
              ?.length,
          Object?.values(singleSurveyStats?.terminationLOI || {})
            ?.slice(0, 4)
            ?.reduce((a, c) => (a += c), 0) /
            Object?.values(singleSurveyStats?.terminationLOI || {})?.slice(0, 4)
              ?.length,
          Object?.values(singleSurveyStats?.terminationLOI || {})
            ?.slice(0, 4)
            ?.reduce((a, c) => (a += c), 0) /
            Object?.values(singleSurveyStats?.terminationLOI || {})?.slice(0, 4)
              ?.length,
          Object?.values(singleSurveyStats?.terminationLOI || {})
            ?.slice(0, 4)
            ?.reduce((a, c) => (a += c), 0) /
            Object?.values(singleSurveyStats?.terminationLOI || {})?.slice(0, 4)
              ?.length,
        ],
        borderColor: "#0226BED9",
        fill: false,
        borderWidth: 1,
        pointRadius: 0,
      },
    ],
  };
  const lengthOfInterviewData = {
    labels: Object?.keys(singleSurveyStats?.avgLOI || {})
      ?.map((d) =>
        new Date(d)?.toLocaleDateString("en-in", {
          day: "2-digit",
          month: "short",
        })
      )
      ?.slice(0, 4),
    datasets: [
      {
        axis: "y",
        label: "Length of Interview",
        data: Object?.values(singleSurveyStats?.avgLOI || {})
          ?.map((d) => (d === null ? 0 : d))
          ?.slice(0, 4),
        fill: false,
        borderColor: "#E0E0E0",
        tension: 0.3,
        borderWidth: 1,
        pointRadius: 9,
        pointBackgroundColor: Object?.values(singleSurveyStats?.avgLOI || {})
          ?.map((d) =>
            d <
            Object?.values(singleSurveyStats?.avgLOI || {})
              ?.slice(0, 4)
              ?.reduce((a, c) => (a += c), 0) /
              Object?.values(singleSurveyStats?.avgLOI || {})?.slice(0, 4)
                ?.length
              ? "#F68D8D"
              : "#0226BED9"
          )
          ?.slice(0, 4),
        pointHoverRadius: 10,
      },
      {
        axis: "y",
        label: " Expected Length Of Interview ",
        data: [
          // !this will be correct
          // data?.expectedMatrix?.expectedCompletionLOI
          Object?.values(singleSurveyStats?.avgLOI || {})
            ?.slice(0, 4)
            ?.reduce((a, c) => (a += c), 0) /
            Object?.values(singleSurveyStats?.avgLOI || {})?.slice(0, 4)
              ?.length,
          Object?.values(singleSurveyStats?.avgLOI || {})
            ?.slice(0, 4)
            ?.reduce((a, c) => (a += c), 0) /
            Object?.values(singleSurveyStats?.avgLOI || {})?.slice(0, 4)
              ?.length,
          Object?.values(singleSurveyStats?.avgLOI || {})
            ?.slice(0, 4)
            ?.reduce((a, c) => (a += c), 0) /
            Object?.values(singleSurveyStats?.avgLOI || {})?.slice(0, 4)
              ?.length,
          Object?.values(singleSurveyStats?.avgLOI || {})
            ?.slice(0, 4)
            ?.reduce((a, c) => (a += c), 0) /
            Object?.values(singleSurveyStats?.avgLOI || {})?.slice(0, 4)
              ?.length,
        ],
        borderColor: "#0226BED9",
        fill: false,
        borderWidth: 1,
        pointRadius: 0,
      },
    ],
  };
  // console.log("entrantsKey=>", entrantsKeys);
  // console.log(entrantsKeys?.map((d) => singleSurveyStats?.entrants?.[d]));
  // console.log("graphData=>", graphData);
  // console.log(Object.keys(singleSurveyStats?.completes));
  // console.log("singleSurveyStats=>", singleSurveyStats);
  // console.log("data=>", data);
  // console.log("organization=>", organization);
  return (
    <div className="flex items-center flex-col gap-[22px] pb-6">
      <div className="w-full reportsShadow py-3 flex flex-col items-center justify-center">
        <div className="w-[97%]">
          <h1 className="w-1/3 text-[24px] font-[500] text-[#666666]">
            Respondant Activity
          </h1>
          <div className="flex items-center gap-[40px] py-6">
            <h1
              className={`text-[#000] text-[16px] font-[400] cursor-pointer transition-all ease-linear ${
                tab?.includes("ENTRANTS") &&
                `text-[#EE964BCC] font-[700] underline decoration-2 decoration-[#EE964BCC] underline-offset-[17px]`
              }`}
              onClick={(e) => {
                if (tab?.includes("ENTRANTS")) {
                  setTab((prev) => prev?.filter((data) => data !== "ENTRANTS"));
                } else {
                  setTab((prev) => [...prev, "ENTRANTS"]);
                }
                if (graphData?.find((data) => data?.label == "Entrants")) {
                  setGraphData((prev) =>
                    prev?.filter((data) => data?.label !== "Entrants")
                  );
                } else {
                  setGraphData((prev) => [
                    ...prev,
                    {
                      label: "Entrants",
                      data: entrantsKeys?.map(
                        (d) => singleSurveyStats?.entrants?.[d]
                      ),
                      backgroundColor: "#EE964BCC",
                      barThickness: 60,
                    },
                  ]);
                }
              }}
            >
              ENTRANTS{" "}
              {Object?.values(singleSurveyStats?.entrants || {})?.reduce(
                (acc, cur) => (acc += cur),
                0
              )}
            </h1>
            <h1
              className={`text-[#000] text-[16px] font-[400] cursor-pointer transition-all ease-linear ${
                tab?.includes("PRESCREENS") &&
                `text-[#F4D35E] font-[700] underline decoration-2 decoration-[#F4D35E] underline-offset-[17px]`
              }`}
              onClick={(e) => {
                if (tab?.includes("PRESCREENS")) {
                  setTab((prev) =>
                    prev?.filter((data) => data !== "PRESCREENS")
                  );
                } else {
                  setTab((prev) => [...prev, "PRESCREENS"]);
                }
                if (graphData?.find((data) => data?.label == "Preescreens")) {
                  setGraphData((prev) =>
                    prev?.filter((data) => data?.label !== "Preescreens")
                  );
                } else {
                  setGraphData((prev) => [
                    ...prev,
                    {
                      label: "Preescreens",
                      data: prescreenKeys?.map(
                        (d) => singleSurveyStats?.prescreens?.[d]
                      ),
                      backgroundColor: "#F4D35E99",
                      barThickness: 60,
                    },
                  ]);
                }
              }}
            >
              PRESCREENS{" "}
              {Object?.values(singleSurveyStats?.prescreens || {})?.reduce(
                (acc, cur) => (acc += cur),
                0
              )}
            </h1>
            <h1
              className={`text-[#000] text-[16px] font-[400] cursor-pointer transition-all ease-linear ${
                tab?.includes("COMPLETES") &&
                `text-[#0226BED9] font-[700] underline decoration-2 decoration-[#0226BED9] underline-offset-[17px]`
              }`}
              onClick={(e) => {
                if (tab?.includes("COMPLETES")) {
                  setTab((prev) =>
                    prev?.filter((data) => data !== "COMPLETES")
                  );
                } else {
                  setTab((prev) => [...prev, "COMPLETES"]);
                }
                if (graphData?.find((data) => data?.label == "Completes")) {
                  setGraphData((prev) =>
                    prev?.filter((data) => data?.label !== "Completes")
                  );
                } else {
                  setGraphData((prev) => [
                    ...prev,
                    {
                      label: "Completes",
                      data: completesKeys?.map(
                        (d) => singleSurveyStats?.completes?.[d]
                      ),
                      backgroundColor: "#0226BED9",
                      barThickness: 60,
                    },
                  ]);
                }
              }}
            >
              COMPLETES{" "}
              {Object?.values(singleSurveyStats?.completes || {})?.reduce(
                (acc, cur) => (acc += cur),
                0
              )}
            </h1>
          </div>
          <BarChart />
        </div>
      </div>
      {/* Line Chart */}
      <div className="w-full flex items-center justify-between gap-[18px]">
        <div className="w-[50%] reportsShadow p-[24px]">
          <LineChart
            title={"Termination LOI"}
            alertLevel={"High"}
            expected={2}
            avgDate={Number(
              Object?.values(singleSurveyStats?.terminationLOI || {})
                ?.slice(0, 4)
                ?.reduce((a, c) => (a += c), 0) /
                Object?.values(singleSurveyStats?.terminationLOI || {})?.slice(
                  0,
                  4
                )?.length
            )?.toFixed(2)}
            data={terminationLOIData}
          />
        </div>
        <div className="w-[50%] reportsShadow p-[24px]">
          <LineChart
            title={"Length Of Interview"}
            alertLevel={"High"}
            expected={data?.expectedMatrix?.expectedCompletionLOI}
            avgDate={Number(
              Object?.values(singleSurveyStats?.avgLOI || {})
                ?.slice(0, 4)
                ?.reduce((a, c) => (a += c), 0) /
                Object?.values(singleSurveyStats?.avgLOI || {})?.slice(0, 4)
                  ?.length
            )?.toFixed(2)}
            data={lengthOfInterviewData}
          />
        </div>
      </div>
      {/* rate charts */}
      <div className="w-full flex items-center justify-between gap-[18px] ">
        <div className="w-[50%] reportsShadow p-[24px]">
          <RateChart
            title={"Incidence Rate"}
            alertLevel={"High"}
            expected={data?.expectedMatrix?.expectedIncidenceRate}
            avgDate={singleSurveyStats?.incidenceRate}
            w1={singleSurveyStats?.incidenceRate}
            w2={data?.expectedMatrix?.expectedIncidenceRate}
          />
        </div>
        <div className="w-[50%] reportsShadow p-[24px]">
          <RateChart
            title={"Drop Off Rate"}
            alertLevel={"High"}
            expected={organization?.projectSettings?.expectedDropOfRate}
            avgDate={singleSurveyStats?.dropOffRate}
            w1={singleSurveyStats?.dropOffRate}
            w2={organization?.projectSettings?.expectedDropOfRate}
          />
        </div>
      </div>
      <div className="w-full flex items-center justify-between gap-[18px]">
        <div className="w-[50%] reportsShadow p-[24px]">
          <RateChart
            title={"Quota Rate"}
            alertLevel={"High"}
            expected={organization?.projectSettings?.expectedQuotaRate}
            avgDate={singleSurveyStats?.quotaRate}
            w1={55}
            w2={organization?.projectSettings?.expectedQuotaRate}
          />
        </div>
        <div className="w-[50%]  p-[24px]"></div>
      </div>
    </div>
  );
};

export default Reports;
