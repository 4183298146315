import React, { useState } from "react";
import deleteIllustration from "../assets/delete-illustration.svg";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { RxCross2 } from "react-icons/rx";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
};
const DeleteModal = ({
  open,
  handleClose,
  text,
  handleClick,
  oparationText,
  cancel,
  btnText,
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <div className="w-full flex items-center flex-col">
            <div className="w-full py-6   text-center relative">
              <p
                className=" bg-[#F1F1F1] py-1 px-1 rounded-[50%] w-fit absolute top-3 cursor-pointer right-4"
                onClick={handleClose}
              >
                <RxCross2 size={16} />
              </p>
            </div>

            <form className="w-full py-4 px-5  flex flex-col gap-[10px] items-center">
              <img src={deleteIllustration} alt="" />
              <h1 className="text-[#000] text-[24px] font-[500] inter py-3">
                Are you sure you want to{" "}
                {oparationText ? oparationText : "Delete"} this?{" "}
              </h1>
              {typeof text == "string" ? (
                <p className="text-[18px] text-[#666] font-[500] inter  mb-8 mt-1">
                  {text}
                </p>
              ) : (
                <div className="w-[90%] max-h-[200px] overflow-y-auto containerScroll border rounded-[10px] p-[10px]">
                  {text?.map((data, i) => {
                    return (
                      <div className="flex items-center gap-2 ">
                        <p className="text-[#333] text-[14px] font-[600] ">
                          {i + 1}.{" "}
                        </p>
                        <p className="text-[#333] text-[14px] font-[600] bg-gray-200 px-3 py-2 rounded-[8px] my-1">
                          {data}
                        </p>
                      </div>
                    );
                  })}
                </div>
              )}

              <div className="flex items-center gap-[20px]">
                <button
                  type="button"
                  className=" inter text-[16px] font-[400] border border-[#0226BE] text-[#0226BE] rounded-[4px] py-2 px-4"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="text-[#fff] inter text-[16px]  border border-[#0226BE] font-[400] bg-[#0226BE] rounded-[4px] py-2 px-4"
                  onClick={handleClick}
                >
                  {btnText || "Save"}
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default DeleteModal;
