import React, { useState } from "react";
import MiratsLogo2 from "../../assets/MiratsLogo2.svg";
import DashboardImage from "../../assets/DashboardImage.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { userSignUp } from "../../FirebaseFunctions";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import Required from "../../components/Required";
const SignUp = () => {
  const [loading, setLoading] = useState(false);
  const { setSnackbar } = useGlobalContext();
  const navigate = useNavigate();
  const [signupData, setSignupData] = useState({});
  const handleChange = async (e) => {
    let { name, value } = e.target;
    setSignupData((prev) => ({ ...prev, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (signupData?.password !== signupData?.confirmPassword) {
        setSnackbar({
          msg: "Password and confirm password should be the same!",
          severity: "error",
          open: true,
        });
        return;
      }
      const emailDomains = ["gmail", "hotmail", "yahoo", "outlook"];
      if (emailDomains.some((domain) => signupData?.email?.includes(domain))) {
        setSnackbar({
          msg: "Please use your corporate email!",
          severity: "error",
          open: true,
        });
        return;
      }
      let data = await userSignUp(signupData);
      if (!data.success) {
        if (data?.errorType === "Disposable Email Detected!") {
          setSnackbar({
            msg: data?.msg || "Something went wrong!",
            severity: "error",
            open: true,
          });
        } else {
          setSnackbar({
            msg: "Error during sign up!",
            severity: "error",
            open: true,
          });
        }
      } else {
        localStorage.setItem("onboard-user", JSON.stringify(signupData));
        navigate("/login/verify-email");
      }
    } catch (error) {
      if (error?.errorCode?.includes("auth/email-already-in-use")) {
        setSnackbar({
          msg: "Email is already in use!",
          severity: "error",
          open: true,
        });
      } else {
        setSnackbar({
          msg: "Something went wrong",
          severity: "error",
          open: true,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     if (signupData?.password === signupData?.confirmPassword) {
  //       const emailDomains = ["gmail", "hotmail", "yahoo", "outlook"];
  //       if (
  //         emailDomains.some((domain) => signupData?.email?.includes(domain))
  //       ) {
  //         setSnackbar({
  //           msg: "Please use your corporate email !",
  //           severity: "error",
  //           open: true,
  //         });
  //         return;
  //       }
  //       let data = await userSignUp(signupData);
  //       if (!data.success) {
  //         if (user?.errorType === "Disposable Email Detected!") {
  //           setSnackbar({
  //             msg: user?.msg || "Something went wrong !",
  //             severity: "error",
  //             open: true,
  //           });
  //         } else {
  //           setSnackbar({
  //             msg: "Error during sign up!",
  //             severity: "error",
  //             open: true,
  //           });
  //         }
  //       } else {
  //         localStorage.setItem("onboard-user", JSON.stringify(signupData));
  //         navigate("/login/verify-email");
  //       }
  //     } else {
  //       setSnackbar({
  //         msg: "password and confirm password should be same !",
  //         severity: "error",
  //         open: true,
  //       });
  //       // alert("password and confirm password should be same !");
  //     }
  //   } catch (error) {
  //     if (error?.errorCode?.includes("auth/email-already-in-use")) {
  //       setSnackbar({
  //         msg: "Email is already in use !",
  //         severity: "error",
  //         open: true,
  //       });
  //     } else {
  //       setSnackbar({
  //         msg: "something went wrong",
  //         severity: "error",
  //         open: true,
  //       });
  //     }
  //   }
  // };
  let footerData = [
    { name: "Privacy Policy", link: "/privacy-policy" },
    { name: "Terms And Conditions", link: "/terms-and-conditions" },
    { name: "Refund Policy", link: "/refund-policy" },
  ];
  // console.log(signupData);
  return (
    <div className="w-full  h-screen flex ">
      <div className="w-[45%]   flex items-center justify-center ">
        <div className="w-[90%]  flex flex-col items-center  gap-[20px] pl-2">
          <img src={MiratsLogo2} alt="CompanyLogo" className=" " />
          <form
            className="w-full flex flex-col gap-[15px] "
            onSubmit={handleSubmit}
          >
            <div>
              <h1 className="text-[#333] text-[38px] font-[600] inter">
                Sign Up
              </h1>
              <p className="text-[#333] text-[14px] font-[400]">
                Create your account.
              </p>
            </div>
            <div className="flex flex-col gap-[5px]">
              <h2 className="text-[#333] text-[14px] font-[600]">
                Full Name <Required />
              </h2>
              <input
                type="text"
                name="fullName"
                className="pl-2 border border-[#E2E2E5] w-full h-[40px] text-[14px]  focus:outline-[#325FFF] focus:text-[#325FFF]"
                placeholder="Enter Full Name"
                required
                value={signupData?.personalDetails?.fullName}
                onChange={(e) =>
                  setSignupData((prev) => ({
                    ...prev,
                    personalDetails: {
                      ...prev?.personalDetails,
                      fullName: e.target.value,
                    },
                  }))
                }
              />
            </div>
            <div className="flex flex-col gap-[5px]">
              <h2 className="text-[#333] text-[14px] font-[600]">
                Email Id <Required />
              </h2>
              <input
                type="email"
                name="email"
                required
                className="pl-2 border border-[#E2E2E5] w-full h-[40px] text-[14px]  focus:outline-[#325FFF] focus:text-[#325FFF]"
                placeholder="Enter Email ID"
                value={signupData?.email}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-[5px]">
              <h2 className="text-[#333] text-[14px] font-[600]">
                Organization Name <Required />
              </h2>
              <input
                type="text"
                name="organizationName"
                required
                className="pl-2 border border-[#E2E2E5] w-full h-[40px] text-[14px]  focus:outline-[#325FFF] focus:text-[#325FFF]"
                placeholder="Enter Organisation Name"
                value={signupData?.organizationName}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-[5px]">
              <h2 className="text-[#333] text-[14px] font-[600]">
                Password <Required />
              </h2>
              <input
                type="password"
                name="password"
                required
                minLength={8}
                className="pl-2 border border-[#E2E2E5] w-full h-[40px] text-[14px]  focus:outline-[#325FFF] focus:text-[#325FFF]"
                placeholder="Enter Password"
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-[5px]">
              <h2 className="text-[#333] text-[14px] font-[600]">
                Confirm Password <Required />
              </h2>
              <input
                type="password"
                name="confirmPassword"
                required
                value={signupData?.confirmPassword}
                minLength={8}
                onChange={handleChange}
                className="pl-2 border border-[#E2E2E5] w-full h-[40px] text-[14px]  focus:outline-[#325FFF] focus:text-[#325FFF]"
                placeholder="Confirm your password"
              />
            </div>
            {loading ? (
              <button
                className="bg-[#0F0F0F] text-white font-[700] text-[14px] py-3 cursor-not-allowed"
                type="button"
                disabled={true}
              >
                Signing up...
              </button>
            ) : (
              <button
                className="bg-[#535353] text-white font-[700] text-[14px] py-3"
                type="submit"
              >
                Sign Up
              </button>
            )}
          </form>
          <h1 className="">
            Already have an account?{" "}
            <span
              className="text-[#325FFF] text-[14px] font-[600] cursor-pointer"
              onClick={(e) => navigate("/login")}
            >
              Sign In
            </span>
          </h1>
        </div>
        <footer className="fixed w-[45%]  bottom-0 h-[30px] bg-gray-50 flex items-center justify-center gap-[20px] text-[12px]">
          {footerData?.map((d, i) => (
            <Link to={d?.link}>{d?.name}</Link>
          ))}
        </footer>
      </div>
      <div className="w-[55%]  signupBg h-full flex items-center justify-center ">
        <div className="w-[85%] h-[92%]  flex flex-col gap-[20px] pl-2">
          <h1 className="text-[44px] text-[#fff] font-[600] leading-[1.35]">
            The simplest way to handle your survey
          </h1>
          <p className="text-[16px] text-[#fff] font-[400] ">
            Enter your credentials to access your account.
          </p>
          <div className="w-full flex items-center justify-center mt-[35px]">
            <img src={DashboardImage} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
