import React from "react";
import { BASE_URL } from "../../../Utlils";
import { useGlobalContext } from "../../../Contexts/GlobalContext";

const Postback = ({ user, setUser }) => {
  const { organization } = useGlobalContext();
  const tableData = [
    { operation: "Success/Complete", status: 10, type: "GET" },
    { operation: "Failure/Terminate", status: 20, type: "GET" },
    { operation: "Security Terminate", status: 30, type: "GET" },
    { operation: "Quota Full", status: 40, type: "GET" },
  ];
  return (
    <div className="w-full flex flex-col gap-[15px] h-full py-5">
      <div className="pb-10 flex items-center justify-between  border-b border-[#E0E0E0] ">
        <div className="flex flex-col gap-[15px] justify-center">
          <h1 className="text-[#333333] text-[28px] font-[500]">
            Post Back URL's
          </h1>
          <p>This information will be used server side endpoint</p>
        </div>
      </div>
      <div className="py-3 space-y-3">
        {" "}
        <h1 className="flex items-center gap-[20px] text-[16px] font-[600]">
          Endpoint
        </h1>
        <div className="tableBorder min-h-[100px] w-full">
          <div className="w-full overflow-x-auto containerScroll">
            <table className="whitespace-nowrap text-left w-full">
              <thead className=" h-[45px] ">
                <tr className="py-3">
                  <th className="text-[#666666] text-[14px] font-[600] px-3">
                    Type
                  </th>
                  <th className="text-[#666666] text-[14px] font-[600] px-3">
                    Operation
                  </th>
                  <th className="text-[#666666] text-[14px] font-[600] px-3">
                    Endpoint
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData?.map((d, i) => (
                  <tr
                    className=" tableBorder transition-all cursor-pointer hover:bg-[#f6f9ff]"
                    key={i}
                  >
                    <td className="px-3 py-5 capitalize  text-green-600 font-[600] text-[14px] w-[300px]">
                      {d?.type}
                    </td>
                    <td className="px-3 py-5 capitalize  text-[#667085] font-[400] text-[14px] w-[300px]">
                      {d?.operation}
                    </td>
                    <td className="px-3 py-5  text-[#667085] font-[400] text-[14px]">
                      {BASE_URL}/post-back/{organization?.id}
                      /[%transaction_id%]/{d?.status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Postback;
