import React from "react";
import MiratsLogo from "../../../assets/MiratsLogo.svg";
import { NavLink, useNavigate } from "react-router-dom";
import dummyUserProfilePicture from "../../../assets/dummy.png";
import { useGlobalContext } from "../../../Contexts/GlobalContext";
const PaymentNavbar = () => {
  const { user } = useGlobalContext();
  const navigate = useNavigate();
  return (
    <nav
      className={`w-full navShadow flex z-10 items-center justify-center h-[64px] sticky top-0`}
    >
      <div className="w-[92%]  flex items-center justify-between">
        <img
          src={MiratsLogo}
          alt="Logo"
          className="w-[180px] h-[50px] object-contain cursor-pointer"
          onClick={(e) => {
            navigate("/");
          }}
        />
        <div className="flex items-center gap-[34px]"></div>
        <div className=" flex items-center gap-[15px] cursor-pointer">
          <div>
            <h1 className="text-[16px] text-end font-[500] inter text-[#000]">
              {user?.personalDetails?.fullName}
            </h1>
            <p className="text-[12px] inter font-[400]">{user?.email}</p>
          </div>
          <img
            src={user?.avatar ? user?.avatar : dummyUserProfilePicture}
            alt="user"
            className="w-[52px] h-[52px] rounded-[50%]"
          />
        </div>
      </div>
    </nav>
  );
};

export default PaymentNavbar;
