import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../../Utlils";
import { useGlobalContext } from "../../../../Contexts/GlobalContext";

const Security = ({ data, getSingleSurvey }) => {
  const [inputData, setInputData] = useState({});
  const { setSnackbar } = useGlobalContext();
  const handleChange = (e) => {
    let { name, value } = e.target;
    if (e.target.checked) {
      setInputData((prev) => ({ ...prev, [name]: true }));
    } else {
      setInputData((prev) => ({ ...prev, [name]: false }));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    axios
      .put(`${BASE_URL}/survey?id=${data?._id}`, { security_checks: inputData })
      .then((res) => {
        getSingleSurvey();
        setSnackbar((prev) => ({
          ...prev,
          open: true,
          msg: "Survey Security Updated !",
          severity: "success",
        }));
      })
      .catch((er) =>
        setSnackbar({
          msg: "Something went wrong !",
          open: true,
          severity: "danger",
        })
      );
  };
  useEffect(() => {
    setInputData(data?.security_checks);
  }, [data]);
  // console.log(inputData);
  return (
    <div className="w-full reconciliationShadow flex flex-col gap-[18px] items-center justify-center pb-10">
      <div className="w-[93%]    my-6">
        <div className="w-full  flex flex-col gap-[24px]">
          <h1 className="text-[#666] font-[600] text-[16px]">Security</h1>
          <div className="w-full flex flex-col gap-[20px]">
            <div className=" flex flex-col gap-[30px]">
              <table className="w-full border border-[#EAECF0] text-left">
                <thead>
                  <tr className="border border-b-[#EAECF0]">
                    <th
                      style={{
                        width: "30%",
                        color: "#666666",
                        fontWeight: 600,
                        fontSize: "14px",
                        padding: "11px 8px",
                      }}
                    >
                      Survey Validation
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="">
                    <td className="py-[15px] px-[8px] text-[#333] text-[14px] font-[400]">
                      Geo Location
                    </td>
                    <td className="text-[#333] font-[600] text-[14px]">
                      {" "}
                      <input
                        name="geoLocation"
                        type="checkbox"
                        checked={true}
                        className="w-[16px] h-[16px] cursor-pointer"
                      />
                    </td>
                  </tr>
                  <tr className="">
                    <td className="py-[15px] px-[8px] text-[#333] text-[14px] font-[400]">
                      Bot Detection
                    </td>
                    <td className="text-[#333] font-[600] text-[14px]">
                      {" "}
                      <input
                        name="botDetection"
                        type="checkbox"
                        checked={true}
                        className="w-[16px] h-[16px] cursor-pointer"
                      />
                    </td>
                  </tr>
                  <tr className="">
                    <td className="py-[15px] px-[8px] text-[#333] text-[14px] font-[400]">
                      Verify Callback
                    </td>
                    <td className="text-[#333] font-[600] text-[14px]">
                      <input
                        type="checkbox"
                        name="verify_callbacks"
                        checked={inputData?.verify_callbacks}
                        onChange={handleChange}
                        className="w-[16px] h-[16px] cursor-pointer"
                      />
                    </td>
                  </tr>
                  <tr className="">
                    <td className="py-[15px] px-[8px] text-[#333] text-[14px] font-[400]">
                      Unique IP Address
                    </td>
                    <td className="text-[#333] font-[600] text-[14px]">
                      {" "}
                      <input
                        type="checkbox"
                        name="unique_ip"
                        onChange={handleChange}
                        checked={inputData?.unique_ip}
                        className="w-[16px] h-[16px] cursor-pointer"
                      />
                    </td>
                  </tr>
                  <tr className="">
                    <td className="py-[15px] px-[8px] text-[#333] text-[14px] font-[400]">
                      Unique RID
                    </td>
                    <td className="text-[#333] font-[600] text-[14px]">
                      {" "}
                      <input
                        type="checkbox"
                        name="unique_rid"
                        onChange={handleChange}
                        checked={inputData?.unique_rid}
                        className="w-[16px] h-[16px] cursor-pointer"
                      />
                    </td>
                  </tr>
                  <tr className="">
                    <td className="py-[15px] px-[8px] text-[#333] text-[14px] font-[400]">
                      Unique Fingerprint
                    </td>
                    <td className="text-[#333] font-[600] text-[14px]">
                      {" "}
                      <input
                        name="unique_fingerprint"
                        onChange={handleChange}
                        type="checkbox"
                        checked={inputData?.unique_fingerprint}
                        className="w-[16px] h-[16px] cursor-pointer"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-[20px]">
        {/* <button className="border border-primary text-primary py-[8px] px-[16px] text-[12px] font-[400] rounded-[4px]">
          Cancel
        </button> */}
        <button
          className="border border-primary bg-primary text-white py-[8px] px-[16px] text-[12px] font-[400] rounded-[4px]"
          onClick={handleSubmit}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Security;
