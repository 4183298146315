import React, { useState } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import { useGlobalContext } from "../../../../Contexts/GlobalContext";
import { BASE_URL } from "../../../../Utlils";
import axios from "axios";

const Documents = ({ data }) => {
  const { setSnackbar } = useGlobalContext();
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleUpload = async (e) => {
    e.preventDefault();
    try {
      if (!selectedFile) {
        setSnackbar({
          open: true,
          msg: "Please select a file",
          severity: "error",
        });

        return;
      }
      const fileSizeInBytes = selectedFile.size;
      const maxSizeInBytes = 5 * 1024 * 1024; // 3 MB
      if (fileSizeInBytes > maxSizeInBytes) {
        setSnackbar({
          open: true,
          msg: `File size exceeds 5 MB limit`,
          severity: "error",
        });

        return;
      }
      const formData = new FormData();
      formData.append("file", selectedFile);

      const config = {
        method: "post",
        url: `${BASE_URL}/document/upload?sNumber=${data?.surveyNumber}`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const result = await axios(config);
      console.log("File uploaded successfully:", result.data);

      setSnackbar({
        open: true,
        msg: "File uploaded successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error uploading file:", error);
      setSnackbar({
        open: true,
        msg: "Error uploading file!",
        severity: "error",
      });
    }
  };
  return (
    <form
      onSubmit={handleUpload}
      className="w-full reconciliationShadow flex flex-col gap-[18px] items-center justify-center pb-10"
    >
      <div className="w-[93%]    my-6">
        <div className="w-[60%] flex flex-col gap-[44px]">
          <h1 className="text-[#666] font-[600] text-[16px]">
            Upload Survey Related Document
          </h1>
          <div className="w-full flex flex-col gap-[20px]">
            <div className="flex flex-col gap-[11px]">
              <label
                htmlFor="reconcilationType"
                className="text-[#666] font-[600] text-[14px]"
              >
                Upload a document
              </label>
              <div className="border-dashed border-[1px] border-primary rounded-[4px] flex  px-6 py-3">
                <div className="flex w-full items-center justify-between py-2">
                  <div className="flex items-center gap-[18px]">
                    <MdOutlineFileUpload size={30} color="#0226BE" />
                    <p className="text-[#12112766] font-[400] text-[12px]">
                      Supported File type (.docx) (.xlsx) (.csv)
                    </p>
                  </div>
                  <input
                    type="file"
                    name="uploadBTN"
                    id="uploadBTN"
                    className="hidden"
                    onChange={handleFileChange}
                    required
                    accept=".docx, .xlsx, .csv"
                  />
                  <label
                    htmlFor="uploadBTN"
                    className="bg-primary text-white cursor-pointer text-[10px] font-[500] py-2 px-4 rounded-[4px] btnShadow"
                  >
                    Browse File
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-[20px]">
        <button className="border border-primary text-white bg-primary py-[8px] px-[25px] text-[12px] font-[400] rounded-[4px]">
          Save
        </button>
      </div>
    </form>
  );
};

export default Documents;
