import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  OAuthProvider,
  signOut,
  sendEmailVerification,
  sendPasswordResetEmail,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import { auth, secondAuth } from "./FirebaseConfig";
import axios from "axios";
import { BASE_URL } from "./Utlils";

const provider = new GoogleAuthProvider();
const faceBookProvider = new FacebookAuthProvider();
const microsoftProvider = new OAuthProvider("microsoft.com");

export const addTeamMember = async (body) => {
  try {
    await createUserWithEmailAndPassword(
      secondAuth,
      body?.email,
      body?.password
    )
      .then(async (res) => {
        sendEmailVerification(secondAuth.currentUser);
        signOut(secondAuth);
        let user = res?.user;
        let saveUser = await axios.post(`${BASE_URL}/users`, {
          ...body,
          uid: user?.uid,
        });
        return saveUser;
      })
      .catch((err) => {
        switch (err.message) {
          case "Firebase: Error (auth/email-already-in-use).":
            let customError = {
              errorCode: 404,
              errorMessage:
                "Email already exist! please try with another email!",
            };
            throw customError;
          default:
            let customError2 = {
              errorCode: 404,
              errorMessage: err.message,
            };
            throw customError2;
        }
      });
  } catch (error) {
    // console.log(error);
    const customError = {
      errorCode: error.errorCode,
      errorMessage: error.errorMessage,
    };
    throw customError;
  }
};

// ! SIGN_UP
export const userSignUp = async (body) => {
  try {
    const emailCheckResult = await checkIsEmailDisposable(body);
    if (!emailCheckResult.success) {
      return emailCheckResult;
    }
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      body.email,
      body.password
    );
    await sendEmailVerification(auth.currentUser);
    const user = userCredential.user;
    const saveUserResponse = await axios.post(`${BASE_URL}/users`, {
      ...body,
      uid: user.uid,
    });
    await axios.post(`${BASE_URL}/company`, {
      name: body.organizationName,
      id: saveUserResponse.data.organizationId,
    });

    return { success: true };
  } catch (error) {
    const customError = {
      errorCode: error.code,
      errorMessage: error.message,
    };
    console.error("Error during user signup: ", customError);
    return { success: false, error: customError };
  }
};
// export const userSignUp = async (body) => {
//   try {
//     let data = await checkIsEmailDisposable(body);
//     if (data?.success) {
//       const userCredential = await createUserWithEmailAndPassword(
//         auth,
//         body?.email,
//         body?.password
//       );
//       sendEmailVerification(auth.currentUser);

//       let user = userCredential?.user;
//       let saveUser = await axios.post(`${BASE_URL}/users`, {
//         ...body,
//         uid: user?.uid,
//       });
//       await axios.post(`${BASE_URL}/company`, {
//         name: body?.organizationName,
//         id: saveUser?.data?.organizationId,
//       });

//       return { success: true };
//     }
//     return data;
//   } catch (error) {
//     const customError = {
//       errorCode: error.code,
//       errorMessage: error.message,
//     };
//     throw customError;
//   }
// };
// ! SIGN_IN
export const Login = async (body) => {
  try {
    let user = await axios.get(
      `${BASE_URL}/users/user-by-email?email=${encodeURIComponent(body.email)}`
    );
    user = user.data;
    if (Object.keys(user || {}).length) {
      // console.log("user=>", user);
      if (user?.accountStatus?.toLowerCase() == "active") {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          body?.email,
          body?.password
        );
        // console.log("userCredential=>", userCredential);
        if (!userCredential.user.emailVerified) {
          const customError = {
            errorCode: "email_not_verified",
            errorMessage: "Email not verified",
          };
          throw customError;
        }
      } else {
        const customError = {
          errorCode: "access_denied",
          errorMessage: "Access denied ask your manager to provide access !",
        };
        throw customError;
      }
    } else {
      const customError = {
        errorCode: "auth/invalid-credential",
        errorMessage: "Invalid email or password",
      };
      throw customError;
    }
  } catch (error) {
    // console.log(error);
    // Handle specific error codes
    if (error.code === "auth/invalid-credential") {
      // Invalid email or password
      const customError = {
        errorCode: "auth/invalid-credential",
        errorMessage: "Invalid email or password",
      };
      throw customError;
    }

    throw error;
  }
};

async function checkIsEmailDisposable(user) {
  const email = user.email;
  try {
    let response = await fetch(
      "https://orionverifier.miratsapiservices.com/check-email",
      {
        method: "post",
        body: JSON.stringify({ email }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const emailData = await response.json();

    if (emailData?.is_disposable) {
      try {
        return {
          msg: "Provided Email is not allowed, please use a valid email!",
          errorType: "Disposable Email Detected!",
          success: false,
        };
      } catch (error) {
        console.error("Error deleting user: ", error);
        return {
          msg: "Error in deleting user!",
          errorType: "Delete user!",
          success: false,
        };
      }
    } else {
      return {
        success: true,
      };
    }
  } catch (error) {
    console.error("Error checking email: ", error);
    return {
      msg: "Error checking email!",
      errorType: "Email check error!",
      success: false,
    };
  }
}
// ! google signIn
export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    const data = await checkIsEmailDisposable(user);
    if (data?.success) {
      await axios.post(`${BASE_URL}/users`, {
        email: user.email,
        personalDetails: {
          fullName: user.displayName,
        },
        uid: user.uid,
        avatar: user.photoURL,
      });
      return {
        msg: "User created successfully!",
        success: true,
      };
    }
    await user.delete();
    return data;
  } catch (error) {
    console.error("Error during Google sign-in: ", error);
    return {
      msg: "Error during Google sign-in!",
      errorType: "Google sign-in error!",
      success: false,
    };
  }
};
// ! facebook signIn
export const signInWithFacebook = async (body) => {
  try {
    let user = await signInWithPopup(auth, faceBookProvider);
    user = user?.user;
    await axios.post(`${BASE_URL}/users`, {
      email: user?.email,
      authorize: user?.emailVerified,
      personalDetails: {
        fullName: user?.displayName,
      },
      uid: user?.uid,
      avatar: user?.photoURL,
    });
    return user;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
// ! microsoft signIn
export const signInWithMicrosoft = async () => {
  try {
    signInWithPopup(auth, microsoftProvider)
      .then((result) => {
        // User is signed in.
        // IdP data available in result.additionalUserInfo.profile.

        // Get the OAuth access token and ID Token
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;
        const user = result.user;
        // console.log(result);
        // console.log(user);
        return user;
      })
      .catch((error) => {
        // Handle error.
        console.log(error);
      });
  } catch (error) {
    console.log(error);
  }
};
// ! logOut user
export const logOutUser = async () => {
  try {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  } catch (error) {
    console.log(error);
  }
};
// ! reset password
// export const resetPassword = async (email) => {
//   console.log(email);
//   try {
//     const signInMethods = await fetchSignInMethodsForEmail(auth, email);
//     console.log("SignInMethods:", signInMethods);

//     if (signInMethods.length === 0) {
//       const customError = {
//         errorCode: "auth/user-not-found",
//         errorMessage: "Email not found in the authentication system",
//       };
//       throw customError;
//     }

//     await sendPasswordResetEmail(auth, email);
//     console.log("Password reset email sent");
//     return "Password reset email sent!";
//   } catch (error) {
//     console.error("Error:", error);
//     console.error("Error Code:", error.code);
//     console.error("Error Message:", error.message);
//     throw error;
//   }
// };
export const resetPassword = async (email) => {
  // console.log(email);
  try {
    let sent = await sendPasswordResetEmail(auth, email);
    return "Password Reset Email Sent !";
  } catch (error) {
    console.log(error);
    const customError = {
      errorCode: error.code,
      errorMessage: error.message,
    };
    throw customError;
  }
};
export const updateUserPassword = async (body) => {
  try {
    // Prompt the user to reauthenticate before updating the password
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, body?.oldPass);
    await reauthenticateWithCredential(user, credential);

    await updatePassword(user, body?.confirmNewPass);

    // console.log("Password updated successfully");
  } catch (error) {
    console.error("Error:", error);
    const customError = {
      errorCode: error.code,
      errorMessage: error.message,
    };
    throw customError;
  }
};
