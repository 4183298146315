import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useGlobalContext } from "../Contexts/GlobalContext";
import { BASE_URL } from "../Utlils";

const VerifyChangeEmail = () => {
  const { setSnackbar } = useGlobalContext();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const queryParams = Object.fromEntries(urlSearchParams.entries());
  const [status, setStatus] = useState({
    msg: "Verifying",
    success: "pending",
  });

  //VERIFY EMAIL:
  const sendVerifyEmail = async (e) => {
    try {
      await axios
        .put(`${BASE_URL}/users/change-email?userId=${queryParams?.id}`, {
          oldEmail: queryParams?.oe,
          newEmail: queryParams?.ne,
        })
        .then((res) => {
          setStatus(res.data);
          if (res?.data?.success === true) {
            localStorage.removeItem("user");
            setTimeout(() => {
              window.location.href = `${window.location.origin}/login`;
            }, 3000);
            setSnackbar({
              open: true,
              msg: "Email has been updated successfully!",
              severity: "success",
            });
          } else {
            setSnackbar({
              open: true,
              msg: res?.data?.msg,
              severity: "error",
            });
          }
        })
        .catch((er) => console.log(er));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    sendVerifyEmail();
  }, []);

  return (
    <div className="h-screen flex items-center justify-center">
      {status?.success === "pending" ? (
        <h1>{status?.msg}</h1>
      ) : (
        <h1>{status?.msg}</h1>
      )}
    </div>
  );
};

export default VerifyChangeEmail;
