import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { utils, writeFile } from "xlsx";
import { useGlobalContext } from "../../../../Contexts/GlobalContext";
import { BASE_URL } from "../../../../Utlils";
import { getStatsDetail } from "./helperData";

let dummyfilterData = [
  { label: "All", value: "all" },
  { label: "Completes", value: "10" },
  { label: "Over Quota", value: "40" },
  { label: "Prescreens", value: "1" },
  { label: "Security Drops", value: [36, 30, 38, 35] },
  { label: "Terminates", value: [20, 30] },
];

const RespondentAnswers = ({ data }) => {
  const { setSnackbar, myQuestion, questionLibrary } = useGlobalContext();
  const { id } = useParams();
  const [tab, setTab] = useState(0);
  const [filters, setFilters] = useState({
    startDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 1,
      new Date().getDate()
    )?.toLocaleDateString("en-CA"),

    endDate: new Date().toLocaleDateString("en-CA"),
  });
  const [qualificationTable, setQualificationTable] = useState([]);

  //GET SURVEY SESSIONS:
  const [surveySessions, setSurveySessions] = useState([]);
  const getSurveySessions = async (id, start, end) => {
    try {
      const sessionData = await axios.get(
        `${BASE_URL}/session/get-survey-sessions?surveyID=${id}&startDate=${start}&endDate=${end}`
      );
      setSurveySessions(sessionData?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSurveySessions(id, filters.startDate, filters.endDate);
  }, [id]);

  // console.log(tab);

  //DOWNLOAD TO EXCEL:
  const DownloadToExcel = () => {
    var elt = document.getElementById("table-to-xls-DATA");
    var wb = utils.table_to_book(elt, { sheet: "MiratsQuanto" });
    const worksheet = wb.Sheets[wb.SheetNames[0]];
    const jsonData = utils.sheet_to_json(worksheet);
    const fieldsToRemove = ["Supplier Account"];
    const filteredData = jsonData.map((row) => {
      fieldsToRemove.forEach((field) => delete row[field]);
      return row;
    });
    const newWorksheet = utils.json_to_sheet(filteredData);
    const newWorkbook = utils.book_new();
    utils.book_append_sheet(newWorkbook, newWorksheet, "MiratsQuanto");
    writeFile(
      newWorkbook,
      `Mirats Quanto - SN#${data?.surveyNumber} - Resp Answers.xlsx`
    );
    setSnackbar({
      open: true,
      severity: "success",
      msg: "Excel Sheet Downloaded!",
    });
  };

  const getTableQualification = async (e) => {
    setQualificationTable([]);
    data?.qualification?.forEach((f) => {
      [...myQuestion, ...questionLibrary]?.forEach((d) => {
        if (d?._id == f?.question) {
          setQualificationTable((prev) => [...prev, { ...f, question: d }]);
        }
      });
    });
  };

  useEffect(() => {
    if (myQuestion && questionLibrary && data?.qualification) {
      getTableQualification();
    }
  }, [myQuestion, questionLibrary, data]);
  // console.log("qualificationTable=>", qualificationTable);
  return (
    <div className="w-full flex flex-col gap-[30px] py-6">
      <div className="w-full flex items-center justify-between ">
        <div className="flex flex-col gap-[26px]">
          <h1 className="text-[#000] text-[18px] font-[500] inter">
            Field Date
          </h1>
          <div className="flex items-center gap-[28px]">
            <input
              type="date"
              className="border h-[40px] px-4 rounded-[8px] text-[#666] text-[14px]"
              value={filters?.startDate}
              onChange={(e) => {
                setFilters((prev) => ({ ...prev, startDate: e.target.value }));
              }}
            />
            <p className="text-[#333] text-[16px] font-[400] inter">To</p>
            <input
              type="date"
              className="border h-[40px] px-4 rounded-[8px] text-[#666] text-[14px]"
              value={filters?.endDate}
              onChange={(e) => {
                setFilters((prev) => ({ ...prev, endDate: e.target.value }));
              }}
            />
            <button
              className="border border-[#0226BE] text-[#0226BE] py-2 px-3 text-[13.3px] inter font-[500] rounded-[8px]  cursor-pointer"
              onClick={(e) =>
                getSurveySessions(id, filters.startDate, filters.endDate)
              }
            >
              Search
            </button>
            <p
              className="text-[14px] underline underline-offset-4 cursor-pointer"
              onClick={(e) => {
                const start = new Date(
                  new Date().getFullYear(),
                  new Date().getMonth() - 1,
                  new Date().getDate()
                )?.toLocaleDateString("en-CA");
                const end = new Date().toLocaleDateString("en-CA");
                setFilters({
                  startDate: start,

                  endDate: end,
                });
                getSurveySessions(id, start, end);
              }}
            >
              Clear filters
            </p>
          </div>
        </div>
        <button
          className="bg-[#0226BE] text-[#fff] py-2 px-3 text-[13.3px] inter font-[500] rounded-[8px] btnShadow cursor-pointer"
          onClick={(e) => DownloadToExcel()}
        >
          Export To Excel
        </button>
      </div>
      {/* table */}
      <div>
        {/* tabs */}
        <div className="w-full tableBorder flex items-center justify-between px-10 py-3 mt-[5px]">
          {dummyfilterData?.map((data, ind) => (
            <h1
              className={`text-[#000] text-[16px] font-[400] cursor-pointer transition-all ease-linear ${
                tab == ind &&
                `text-[#0226BE] font-[700] underline decoration-2 decoration-[#0226BE] underline-offset-[17px]`
              }`}
              onClick={(e) => {
                setTab(ind);
              }}
            >
              {data?.label}{" "}
            </h1>
          ))}
        </div>
        <div className="w-full tableBorder flex flex-col">
          <div className="w-full overflow-x-auto containerScroll">
            <table
              className="whitespace-nowrap text-left w-full"
              id="table-to-xls-DATA"
            >
              <thead className=" h-[45px] ">
                <tr className="py-3">
                  {[
                    "Ref ID",
                    "Supplier Account",
                    "Survey No.",
                    "Project No.",
                    "Status",
                    "Client Status",
                    // "IP",
                    "Browser",
                    "Device Type",
                    "Start Time",
                    "End Time",
                    "Timezone",
                    "Total Time",
                    "CPI",
                  ]?.map((d) => (
                    <td className="text-[#666] text-[14px] font-[600] pl-4">
                      {d}
                    </td>
                  ))}
                  {qualificationTable
                    ?.filter((d) => d?.status)
                    ?.map((data) => {
                      return (
                        <td className="text-[#666] text-[14px] font-[600] pl-4">
                          {data?.question?.questionName}
                        </td>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                {surveySessions
                  ?.filter((da) => {
                    return tab == 0
                      ? da
                      : tab == 4
                      ? dummyfilterData[tab]?.value?.includes(da?.mirats_status)
                      : tab == 5
                      ? da?.client_status == 20
                      : //  da?.mirats_status == 30 ||
                      // da?.mirats_status == 38 ||
                      // da?.mirats_status == 37 ||
                      // da?.mirats_status == 36 ||
                      // da?.mirats_status == 35
                      tab == 2
                      ? da?.client_status == dummyfilterData[tab]?.value ||
                        da?.mirats_status == dummyfilterData[tab]?.value ||
                        da?.mirats_status == 41
                      : tab == 3 || tab == 1 || tab == 2
                      ? da?.client_status == dummyfilterData[tab]?.value
                      : da?.client_status == dummyfilterData[tab]?.value;
                  })
                  ?.map((da) => (
                    <tr className=" tableBorder transition-all cursor-pointer hover:bg-[#f6f9ff] ">
                      <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                        {da?.ref_id}
                      </td>
                      <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                        {da?.supplierAccountId?.supplierName}
                      </td>
                      <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                        {data?.surveyNumber}
                      </td>
                      <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                        {data?.projectNumber}
                      </td>
                      <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                        {getStatsDetail("mirats", da?.mirats_status)} (
                        {da?.mirats_status})
                      </td>
                      <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                        {getStatsDetail("client", da?.client_status)} (
                        {da?.client_status})
                      </td>
                      {/* <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                        {da?.geoData?.ip}
                      </td> */}

                      <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                        {da?.sessionTechnicalDetails?.browserName
                          ? da?.sessionTechnicalDetails?.browserName
                          : "-"}
                      </td>
                      <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                        {da?.sessionTechnicalDetails?.os
                          ? da?.sessionTechnicalDetails?.os
                          : "-"}
                      </td>
                      <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                        {new Date(da?.survey_start_time)?.toLocaleDateString() +
                          " " +
                          new Date(da?.survey_start_time)?.toLocaleTimeString()}
                      </td>
                      <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                        {da?.survey_end_time
                          ? new Date(
                              da?.survey_end_time
                            )?.toLocaleDateString() +
                            " " +
                            new Date(da?.survey_end_time)?.toLocaleTimeString()
                          : "-"}
                      </td>
                      <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                        {da?.geoData?.time_zone?.name}
                      </td>
                      <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                        {da?.total_survey_time}
                      </td>
                      <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                        {Number(da?.surveyCPI)?.toFixed(2) || 0}
                      </td>

                      {qualificationTable?.map((data) => {
                        const response = da?.responses?.find(
                          (res) => res?.question_id == data?._id
                        );
                        if (typeof response?.user_response == "string") {
                          return (
                            <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                              {response?.user_response}
                            </td>
                          );
                        } else {
                          return (
                            <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                              {response?.user_response?.map((ur) => {
                                return <p>{ur}</p>;
                              })}
                            </td>
                          );
                        }
                      })}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RespondentAnswers;
