import React from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    marginRight: 0,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#0226BE" : "#0226BE",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const MyNotification = () => {
  return (
    <div className="w-full flex flex-col gap-[15px] h-full py-5">
      <div className="pb-10 flex items-center justify-between  border-b border-[#E0E0E0] ">
        <div className="flex flex-col gap-[15px] justify-center">
          <h1 className="text-[#333333] text-[28px] font-[500]">
            My Notifications
          </h1>
          <p>Get notification from Mirats Quanto on your devices</p>
        </div>
      </div>
      <div className="pb-10 flex items-center justify-between  border-b border-[#E0E0E0] ">
        <div className="flex flex-col gap-[15px] justify-center">
          <h1 className="text-[#333333] text-[18px] font-[500]">
            Push Notification
          </h1>
          <p className="text-[#666] text-[16px]">
            Receive push notifications on mentions and comments via your web
            browser or app.
          </p>
        </div>
        <FormControlLabel
          control={<IOSSwitch sx={{ m: 1 }} defaultChecked={false} />}
        />
      </div>
      <div className="pb-10 flex items-center justify-between  border-b border-[#E0E0E0] ">
        <div className="flex flex-col gap-[15px] justify-center">
          <h1 className="text-[#333333] text-[18px] font-[500]">
            Email Notification
          </h1>
          <p className="text-[#666] text-[16px]">
            Receive push notifications on mentions and comments via your web
            browser or app.
          </p>
        </div>
        <FormControlLabel
          control={<IOSSwitch sx={{ m: 1 }} defaultChecked={false} />}
        />
      </div>
    </div>
  );
};

export default MyNotification;
