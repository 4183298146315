import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../Contexts/GlobalContext";
import { hideNav } from "../Utlils";

const AdditionalWarningBar = () => {
  const navigate = useNavigate();
  const { organization, user, setSnackbar } = useGlobalContext();
  const [shouldDisplay, setShouldDisplay] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (user && organization) {
        if (
          !Object.keys(organization?.addresses?.billingAddress || {})?.length &&
          user._id
        ) {
          setShouldDisplay(true);
        } else {
          setShouldDisplay(false);
        }
      }
    }, 1500);

    return () => clearTimeout(timer);
  }, [user?._id, organization?._id]);
  if (
    hideNav.some((data) => window.location.href.includes(data)) ||
    !shouldDisplay
  ) {
    return null;
  }
  return (
    <div
      className={`w-full py-2  px-4 bg-yellow-400 my-3 flex items-center justify-between rounded-md ${
        hideNav?.some((data) => window.location.href?.includes(data))
          ? "hidden"
          : ""
      }`}
    >
      <h1 className="text text-gray-700 text-[14px] font-semibold">
        Organisation's Billing and some other details are empty !
      </h1>
      <button
        className="border-2 border-red-500 px-4 py-1 text-red-500 font-bold text-[12px] rounded-md"
        onClick={() => {
          if (user?.role === "admin") {
            navigate("/onboarding");
          } else {
            setSnackbar({
              open: true,
              msg: "Only admins are allowed to change organization details !",
              severity: "warning",
            });
          }
        }}
      >
        FIX
      </button>
    </div>
  );
};

export default AdditionalWarningBar;
