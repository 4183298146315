import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../Utlils";
import { useGlobalContext } from "../../../Contexts/GlobalContext";

const GlobalProjectSetting = () => {
  const { user, setSnackbar, organization, getOrganization } =
    useGlobalContext();
  const [inputData, setInputData] = useState({});
  const handleChange = (e) => {
    let { name, value } = e.target;
    setInputData((prev) => ({ ...prev, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let updatedOrganization = await axios.put(
        `${BASE_URL}/company/${organization?.id}`,
        { projectSettings: inputData }
      );
      // console.log("updatedOrganization=>", updatedOrganization);
      setSnackbar((prev) => ({
        open: true,
        msg: "Project setting updated !",
        severity: "success",
      }));
      getOrganization(updatedOrganization?.data?.id);
    } catch (error) {
      setSnackbar((prev) => ({
        open: true,
        msg: "Something went wrong !",
        severity: "error",
      }));
    }
  };
  // console.log("inputData=>", inputData);
  // console.log(user);
  // console.log("organization=>",organization);
  useEffect(() => {
    setInputData(organization?.projectSettings);
  }, [Object.keys(organization)?.length]);
  return (
    <form
      className="w-full flex flex-col gap-[15px] h-full py-5 "
      onSubmit={handleSubmit}
    >
      <div className="pb-10 flex items-center justify-between  border-b border-[#E0E0E0] ">
        <div className="flex flex-col gap-[15px] justify-center">
          <h1 className="text-[#333333] text-[28px] font-[500]">
            Global Project Settings
          </h1>
          <p>Set global project settings</p>
        </div>
        <button
          type="submit"
          disabled={!user?.role === "admin"}
          style={
            user?.role === "admin"
              ? { cursor: "pointer" }
              : { cursor: "not-allowed" }
          }
          className="border text-white bg-primary py-2 px-4 rounded-[8px]"
        >
          Update
        </button>
      </div>
      <div className="pb-8  grid grid-cols-3 justify-between gap-[40px] items-center">
        {/* <div className="flex flex-col gap-[20px]">
          <h1 className="flex items-center gap-[20px] text-[16px] font-[600]">
            Expected Incidence Rate (%){" "}
          </h1>
          <input
            type="text"
            pattern="[0-9]*"
            required
            name="expectedIncidenceRate"
            inputMode="numeric"
            onChange={handleChange}
            value={inputData?.expectedIncidenceRate}
            placeholder="eg-20"
            className="border py-2 px-4 rounded-[8px] text-[#666] text-[12px]"
          />
        </div> */}
        <div className="flex flex-col gap-[20px]">
          <h1 className="flex items-center gap-[20px] text-[16px] font-[600]">
            Expected Drop of Rate (%){" "}
          </h1>
          <input
            type="number"
            pattern="[0-9]*"
            inputMode="numeric"
            placeholder="eg-10"
            onChange={handleChange}
            onKeyDown={(e) => {
              if (e.key === "e" || e.key === "+" || e.key === "-") {
                e.preventDefault();
              }
            }}
            disabled={!user?.role === "admin"}
            style={
              user?.role === "admin"
                ? { cursor: "auto" }
                : { cursor: "not-allowed" }
            }
            value={inputData?.expectedDropOfRate}
            name="expectedDropOfRate"
            required
            className="border py-2 px-4 rounded-[8px] text-[#666] text-[12px]"
          />
        </div>
        <div className="flex flex-col gap-[20px]">
          <h1 className="flex items-center gap-[20px] text-[16px] font-[600]">
            Expected Quota Rate (%){" "}
          </h1>
          <input
            type="number"
            pattern="[0-9]*"
            inputMode="numeric"
            placeholder="eg-2"
            disabled={!user?.role === "admin"}
            onKeyDown={(e) => {
              if (e.key === "e" || e.key === "+" || e.key === "-") {
                e.preventDefault();
              }
            }}
            style={
              user?.role === "admin"
                ? { cursor: "auto" }
                : { cursor: "not-allowed" }
            }
            value={inputData?.expectedQuotaRate}
            onChange={handleChange}
            name="expectedQuotaRate"
            required
            className="border py-2 px-4 rounded-[8px] text-[#666] text-[12px]"
          />
        </div>
      </div>
      {/* <div className="pb-8 border-b border-[#E0E0E0] grid grid-cols-3 justify-between gap-[40px] items-center">
        <div className="flex flex-col gap-[20px]">
          <h1 className="flex items-center gap-[20px] text-[16px] font-[600]">
            Time Zone
          </h1>
          <select
            name="timeZone"
            id="timeZone"
            className="border py-2 px-4 rounded-[8px] text-[#666] text-[12px]"
            disabled={!user?.role === "admin"}
            onChange={handleChange}
            required
            style={
              user?.role === "admin"
                ? { cursor: "auto" }
                : { cursor: "not-allowed" }
            }
            value={inputData?.timeZone}
          >
            <option value="">Select Time Zone</option>
            {Intl.supportedValuesOf("timeZone")?.map((d, i) => (
              <option value={d} key={i}>
                {d}
              </option>
            ))}
          </select>
        </div>
      </div> */}
    </form>
  );
};

export default GlobalProjectSetting;
