import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../Utlils";
import axios from "axios";

const Dashboard = () => {
  const {
    allSurvey,
    myQuestion,
    questionLibrary,
    allSurveyGroup,
    allSurveyStats,
    allMonthsAndYear,
    allInvoices,
    user,
  } = useGlobalContext();
  const navigate = useNavigate();
  const [overviewFilter, setOverviewFilter] = useState({
    survey: {
      month: new Date().getMonth(),
      year: new Date()?.getFullYear(),
    },
    invoice: {
      month: new Date().getMonth(),
      year: new Date()?.getFullYear(),
    },
  });
  const [dashboardAnalytics, setDashboardAnalytics] = useState({});
  const data = {
    labels: [],
    datasets: [
      {
        label: "My First Dataset",
        data: [
          allInvoices
            ?.filter(
              (d) =>
                String(new Date(d?.invoiceDate)?.getFullYear()) ===
                String(overviewFilter?.invoice?.year)
            )
            ?.reduce(
              (acc, curr) =>
                (acc += curr?.paymentStats?.toLowerCase() === "unpaid"),
              0
            ),
          allInvoices
            ?.filter(
              (d) =>
                String(new Date(d?.invoiceDate)?.getFullYear()) ===
                String(overviewFilter?.invoice?.year)
            )
            ?.reduce(
              (acc, curr) =>
                (acc += curr?.paymentStats?.toLowerCase() === "paid"),
              0
            ),
        ],
        borderColor: ["rgba(255,206,86,0.2)"],
        backgroundColor: ["#EE799F", "#0226BEB2"],
        pointBackgroundColor: "#000000b3",
        hoverBackgroundColor: ["#f5588a", "#4a68ee"],
        borderWidth: 1,
        cutoutPercentage: 10,
        cutout: 75,
        hoverOffset: 4,
        spacing: 6,
        borderRadius: 15,
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        text: "",
        display: false,
        color: "blue",
        font: {
          size: 5,
        },
        padding: {
          top: 10,
          bottom: 10,
        },
        borderWidth: 1,
        responsive: true,
        animation: {
          animateScale: true,
        },
      },
    },
  };

  const BarChart = () => {
    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            drawOnChartArea: false,
            display: false,
          },
          ticks: {
            font: {
              size: 8,
            },
            color: "#ACACAC",
          },
          border: {
            display: false,
          },
        },
        y: {
          stacked: true,
          grid: {
            drawOnChartArea: false,
            display: false,
          },
          ticks: {
            font: {
              size: 10,
            },
            color: "#ACACAC",
          },
          border: {
            display: false,
          },
        },
      },
    };
    let obj = { label: [], data1: [], data2: [] };

    for (let i = 1; i <= Object?.keys(dashboardAnalytics || {}).length; i++) {
      let suffix = "th";

      if (i % 10 === 1 && i !== 11) {
        suffix = "st";
      } else if (i % 10 === 2 && i !== 12) {
        suffix = "nd";
      } else if (i % 10 === 3 && i !== 13) {
        suffix = "rd";
      }
      obj.label.push(i + suffix);
    }
    Object?.entries(dashboardAnalytics)?.map(([key, value]) => {
      obj.data1.push(value?.paused);
      obj.data2.push(value?.live);
    });
    const data = {
      labels: obj.label,

      datasets: [
        {
          label: "Paused",
          backgroundColor: "#F6CF7D",
          data: obj.data1,
          barThickness: 11,
          borderRadius: 10,
        },
        {
          label: "Live",
          backgroundColor: "#B6B0FB",
          data: obj.data2,
          barThickness: 11,
          borderRadius: 3,
        },
      ],
    };
    return <Bar options={options} data={data} height={80} />;
  };

  const handleFilterChange = (e, name) => {
    setOverviewFilter((prev) => ({
      ...prev,
      [name]: {
        ...prev?.[name],
        [e.target.name]: e.target.value,
      },
    }));
  };

  const getDashboardAnalytics = async (e) => {
    try {
      let data = await axios.get(
        `${BASE_URL}/survey/getDashboardSurveyAnalysis?month=${
          Number(overviewFilter?.survey?.month) + 1
        }&year=${overviewFilter?.survey?.year}&orgId=${user?.organizationId}`
      );
      setDashboardAnalytics(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (Object.keys(user)?.length !== 0) getDashboardAnalytics();
  }, [
    overviewFilter?.survey?.month,
    overviewFilter?.survey?.year,
    user?.organizationId,
  ]);
  // console.log("allInvoices=>", allInvoices);
  return (
    <div className="w-full flex flex-col gap-[35px] py-6">
      <div className="w-full flex items-center justify-between ">
        <h1 className="text-[#333] text-[32px] font-[500] inter ">Dashboard</h1>
      </div>
      <div className="w-full flex flex-col gap-[23px]">
        <h1 className="text-[#333] text-[20px] font-[500] inter">
          Survey Overview
        </h1>

        <div className="w-full flex  gap-[27px]">
          <div className="w-[65%] dashboardShadow h-[360px]">
            <div className="mx-5 py-5 border-b border-[#E0E0E0] flex items-center justify-between">
              <h1 className="text-[#333] text-[16px] font-[500] inter">
                Survey Analysis
              </h1>
              <div className="flex items-center gap-[33px]">
                <select
                  className="text-[#0F2552] text-[12px] font-[600] px-3"
                  value={overviewFilter?.survey?.year}
                  name="year"
                  onChange={(e) => handleFilterChange(e, "survey")}
                >
                  <option value="">Year</option>
                  {allMonthsAndYear?.years?.map((d) => (
                    <option value={d}>{d}</option>
                  ))}
                </select>
                <select
                  className="text-[#0F2552] text-[12px] font-[600] px-3"
                  value={overviewFilter?.survey?.month}
                  name="month"
                  onChange={(e) => handleFilterChange(e, "survey")}
                >
                  <option value="">Month</option>
                  {allMonthsAndYear?.months?.map((d) => (
                    <option value={d?.value}>{d?.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mx-5 my-2 flex items-center gap-[30px] ">
              <div>
                <h1 className="text-[#242424] text-[14px] font-[500]">
                  {Object.values(dashboardAnalytics || {})?.reduce(
                    (acc, cur) => (acc += cur?.live),
                    0
                  )}
                </h1>
                <div className="flex items-center gap-[7px]">
                  <p className="bg-[#B6B0FB] w-[5px] h-[5px] rounded-[50%]"></p>
                  <p className="text-[10px] text-[#7A7A7A] font-[600]">
                    Live Survey
                  </p>
                </div>
              </div>
              <div>
                <h1 className="text-[#242424] text-[14px] font-[500]">
                  {" "}
                  {Object.values(dashboardAnalytics || {})?.reduce(
                    (acc, cur) => (acc += cur?.paused),
                    0
                  )}
                </h1>
                <div className="flex items-center gap-[7px]">
                  <p className="bg-[#F6CF7D] w-[5px] h-[5px] rounded-[50%]"></p>
                  <p className="text-[10px] text-[#7A7A7A] font-[600]">
                    Pause Survey
                  </p>
                </div>
              </div>
            </div>
            <div className="mx-5 my-5 h-[220px] w-[95%] ">
              <div className="w-full h-[100%] ">
                <BarChart />
              </div>
            </div>
          </div>
          <div className="w-[35%] dashboardShadow h-[360px]">
            <div className="mx-5 py-5 border-b border-[#E0E0E0] flex items-center justify-between">
              <h1 className="text-[#333] text-[16px] font-[500] inter">
                Invoice Overview
              </h1>
              <div className="flex items-center gap-[33px]">
                <select
                  className="text-[#0F2552] text-[12px] font-[600] px-3"
                  value={overviewFilter?.invoice?.year}
                  name="year"
                  onChange={(e) => handleFilterChange(e, "invoice")}
                >
                  <option value="">Year</option>
                  {allMonthsAndYear?.years?.map((d) => (
                    <option value={d}>{d}</option>
                  ))}
                </select>
                {/* <select
                  className="text-[#0F2552] text-[12px] font-[600] px-3"
                  value={overviewFilter?.invoice?.month}
                  name="month"
                  onChange={(e) => handleFilterChange(e, "invoice")}
                >
                  <option value="">Month</option>
                  {allMonthsAndYear?.months?.map((d) => (
                    <option value={d?.value}>{d?.name}</option>
                  ))}
                </select> */}
              </div>
            </div>
            <div className="h-[300px]  flex items-center justify-between">
              <div className="mx-5 flex flex-col justify-between  h-[100%] ">
                <div className=" mt-5">
                  <p className="text-[#4D4D4D80] text-[16px] font-[500]">
                    Total Invoices
                  </p>
                  <h3 className="text-[#000] text-[18px] font-[700]">
                    {allInvoices?.filter(
                      (d) =>
                        String(new Date(d?.invoiceDate)?.getFullYear()) ===
                        String(overviewFilter?.invoice?.year)
                    )?.length || 0}
                  </h3>
                </div>
                <div className=" mb-5 flex flex-col gap-2">
                  <div className="flex items-center ">
                    <div className="w-[35px] h-[23px] rounded-[9px] bg-[#4E67D2]"></div>
                    <h1 className="ml-3 text-[#000000b3] text-[18px] font-[700]">
                      {Number(
                        (allInvoices
                          ?.filter(
                            (d) =>
                              String(
                                new Date(d?.invoiceDate)?.getFullYear()
                              ) === String(overviewFilter?.invoice?.year)
                          )
                          ?.filter((d) => d?.paymentStats === "paid")?.length /
                          allInvoices?.filter(
                            (d) =>
                              String(
                                new Date(d?.invoiceDate)?.getFullYear()
                              ) === String(overviewFilter?.invoice?.year)
                          )?.length) *
                          100 || 0
                      )?.toFixed(1)}
                      %
                    </h1>
                    <p className="ml-2 text-[#00000080] text-[16px] font-[500]">
                      Paid
                    </p>
                  </div>
                  <div className="flex items-center ">
                    <div className="w-[35px] h-[23px] rounded-[9px] bg-[#EE799F]"></div>
                    <h1 className="ml-3 text-[#000000b3] text-[18px] font-[700]">
                      {Number(
                        (allInvoices
                          ?.filter(
                            (d) =>
                              String(
                                new Date(d?.invoiceDate)?.getFullYear()
                              ) === String(overviewFilter?.invoice?.year)
                          )

                          ?.filter(
                            (d) => d?.paymentStats?.toLowerCase() === "unpaid"
                          )?.length /
                          allInvoices?.filter(
                            (d) =>
                              String(
                                new Date(d?.invoiceDate)?.getFullYear()
                              ) === String(overviewFilter?.invoice?.year)
                          )?.length) *
                          100 || 0
                      )?.toFixed(1)}
                      %
                    </h1>
                    <p className="ml-2 text-[#00000080] text-[16px] font-[500]">
                      Unpaid
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-[60%] h-[100%]  flex items-center justify-center overflow-hidden mr-4">
                <Doughnut data={data} options={options} />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex  gap-[18px]">
          <div className="w-[60%] dashboardShadow h-[360px]">
            <div className="mx-5 py-6  flex items-center justify-between">
              <h1 className="text-[#333] text-[16px] font-[500] inter">
                Total Survey List
              </h1>
              <p
                className="text-[#ACACAC] text-[14px] font-[400] cursor-pointer hover:text-primary hover:font-[500] hover:underline underline-offset-[3px] hover:transition-all duration-500 ease-linear decoration-2 overflow-hidden"
                onClick={(e) => navigate("/surveys")}
              >
                See all
              </p>
            </div>
            <div className=" mx-5 w-[95%]  max-h-[340px] overflow-y-auto containerScroll ">
              <table className=" w-full  ">
                <thead className="">
                  <tr className=" border-b border-[#E0E0E0]">
                    {[
                      "Surveys",
                      "Progress",
                      "Completes",
                      "AVG. LOI",
                      "Conv. Rate",
                      "I. R",
                      "Avg. CPI",
                      "Drop-off",
                      "EPC",
                    ]?.map((data, ind) => (
                      <td
                        className="text-[#999999] pb-3 text-[12px] font-[500]"
                        key={ind}
                      >
                        {data}
                      </td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {allSurvey?.slice(0, 5)?.map((data) => (
                    <>
                      {allSurveyStats?.map((stats) => {
                        if (data?._id === stats?.survey?._id) {
                          return (
                            <tr className="">
                              <td
                                className="text-[#333333] font-[400] text-[12px] py-4 cursor-pointer"
                                onClick={(e) =>
                                  navigate(`/surveys/${data?._id}`)
                                }
                              >
                                <Tooltip title={data?.surveyName}>
                                  <h1 className="text-[#333333] font-[400] text-[12px] text-ellipsis w-[100px] whitespace-nowrap hover:text-primary hover:font-[500] hover:underline underline-offset-[3px] hover:transition-all duration-500 ease-linear decoration-2 overflow-hidden">
                                    {data?.surveyName || "-"}
                                  </h1>
                                </Tooltip>
                              </td>
                              <td className="text-[#333333] font-[400] text-[12px] py-4 ">
                                {" "}
                                {stats?.completes || 0} /{" "}
                                {data?.expectedMatrix?.expectedCompletes || 0}
                              </td>
                              <td className="text-[#333333] font-[400] text-[12px] py-4 ">
                                {" "}
                                {stats?.completes || 0}
                              </td>
                              <td className="text-[#333333] font-[400] text-[12px] py-4 ">
                                {" "}
                                {stats?.avgLoi
                                  ? `${stats?.avgLoi} min`
                                  : "0 min"}
                              </td>
                              <td className="text-[#333333] font-[400] text-[12px] py-4 ">
                                {" "}
                                {stats?.conversion
                                  ? `${Number(stats?.conversion)?.toFixed(2)} %`
                                  : "0 %"}
                              </td>
                              <td className="text-[#333333] font-[400] text-[12px] py-4 ">
                                {" "}
                                {stats?.IR
                                  ? `${Number(stats?.IR)?.toFixed(2)} %`
                                  : `0 %`}
                              </td>
                              <td className="text-[#333333] font-[400] text-[12px] py-4 ">
                                {" "}
                                {stats?.avgCPI || 0}
                              </td>
                              <td className="text-[#333333] font-[400] text-[12px] py-4 ">
                                {" "}
                                {stats?.dropRate
                                  ? `${stats?.dropRate} %`
                                  : `0 `}
                              </td>
                              <td className="text-[#333333] font-[400] text-[12px] py-4 ">
                                {" "}
                                {Number(stats?.epc) || 0}
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-[40%] dashboardShadow h-[360px]">
            <div className="mx-5 py-6  flex items-center justify-between">
              <h1 className="text-[#333] text-[16px] font-[500] inter">
                Invoices List
              </h1>
              <p
                className="text-[#ACACAC] text-[14px] font-[400] cursor-pointer hover:text-primary hover:font-[500] hover:underline underline-offset-[3px] hover:transition-all duration-500 ease-linear decoration-2 overflow-hidden"
                onClick={(e) => navigate("/invoice-billing")}
              >
                See all
              </p>
            </div>
            <div className=" mx-5 w-[95%]  max-h-[340px] overflow-y-auto containerScroll ">
              <table className=" w-full  ">
                <thead className="">
                  <tr className=" border-b border-[#E0E0E0]">
                    {[
                      "Invoice No.",
                      " Completes",
                      " Avg CPI",
                      " Revenue",
                      "  Amount",
                      " Status",
                      " Date",
                    ]?.map((data, ind) => (
                      <td
                        className="text-[#999999] pb-3 text-[10px] font-[500]"
                        key={ind}
                      >
                        {data}
                      </td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {allInvoices?.slice(0, 5)?.map((data) => (
                    <tr className="">
                      <td
                        className="text-[#333333] font-[400] text-[12px] py-4 cursor-pointer hover:text-primary hover:font-[500] hover:underline underline-offset-[3px] hover:transition-all duration-500 ease-linear decoration-2 overflow-hidden"
                        onClick={(e) =>
                          navigate(`/invoice-billing/${data?._id}`)
                        }
                      >
                        {data?.invoiceNumber || "-"}
                      </td>
                      <td className="text-[#333333] font-[400] text-[12px] py-4 text-center">
                        {data?.invoiceTable?.reduce(
                          (acc, cur) => (acc += cur?.totalCompletes),
                          0
                        )}
                      </td>
                      <td className="text-[#333333] font-[400] text-[12px] py-4 ">
                        {(
                          data?.invoiceTable?.reduce(
                            (acc, cur) => (acc += cur?.totalRevenue),
                            0
                          ) /
                          data?.invoiceTable?.reduce(
                            (acc, cur) => (acc += cur?.totalCompletes),
                            0
                          )
                        )?.toFixed(2)}
                      </td>
                      <td className="text-[#333333] font-[400] text-[12px] py-4 ">
                        {data?.invoiceTable?.reduce(
                          (acc, cur) => (acc += cur?.totalRevenue),
                          0
                        )}
                      </td>
                      <td className="text-[#333333] font-[400] text-[12px] py-4 ">
                        USD{" "}
                        {data?.invoiceTable?.reduce(
                          (acc, cur) => (acc += cur?.totalRevenue),
                          0
                        )}
                      </td>
                      <td className="text-[#333333] font-[400] text-[12px] py-4 ">
                        {data?.paymentStats === "paid" ? (
                          <p className="bg-[#EDFBF4] rounded-[11px] w-[51px] h-[20px] flex items-center justify-center text-[#2FBB85] inter text-[10px] capitalize">
                            Paid
                          </p>
                        ) : (
                          <p className="bg-[#bbad2f37] rounded-[11px] w-[51px] h-[20px] flex items-center justify-center text-[#bbad2f] inter text-[10px] capitalize">
                            Unpaid
                          </p>
                        )}
                      </td>
                      <td className="text-[#333333] font-[400] text-[10px] py-4 ">
                        {new Date(data?.invoiceDate)?.toLocaleDateString(
                          "en-in",
                          { day: "numeric", month: "short", year: "numeric" }
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="w-full flex  gap-[27px] mt-3">
          <div className="w-[40%] flex flex-col gap-[20px] ">
            <div className="w-full dashboardShadow h-[360px] ">
              <div className="mx-5 py-6  flex items-center justify-between">
                <h1 className="text-[#333] text-[16px] font-[500] inter">
                  List Of Survey Group
                </h1>
                <p
                  className="text-[#ACACAC] text-[14px] font-[400] cursor-pointer hover:text-primary hover:font-[500] hover:underline underline-offset-[3px] hover:transition-all duration-500 ease-linear decoration-2 overflow-hidden"
                  onClick={(e) => navigate("/survey-groups")}
                >
                  See all
                </p>
              </div>
              <div className=" mx-5 w-[95%]  max-h-[320px] overflow-y-auto containerScroll ">
                <table className=" w-full  ">
                  <thead className="">
                    <tr className=" border-b border-[#E0E0E0]">
                      {[
                        "Group Name",
                        "Description",
                        "Survey ID",
                        "Status",
                      ]?.map((data, ind) => (
                        <td
                          className="text-[#999999] pb-3 text-[12px] font-[500]"
                          key={ind}
                        >
                          {data}
                        </td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {allSurveyGroup?.slice(0, 5)?.map((data, ind) => (
                      <tr className="" key={ind}>
                        <td
                          className="text-[#333333] font-[400] text-[12px] py-3 cursor-pointer hover:text-primary hover:font-[500] hover:underline underline-offset-[3px] hover:transition-all duration-500 ease-linear decoration-2 overflow-hidden"
                          onClick={(e) =>
                            navigate(`/survey-groups/${data?._id}`)
                          }
                        >
                          <Tooltip title={data?.groupName}>
                            <p className="text-[#333333] font-[400] text-[12px]  w-[100px]  text-ellipsis overflow-hidden whitespace-nowrap">
                              {data?.groupName}
                            </p>
                          </Tooltip>
                        </td>
                        <td className="text-[#333333] font-[400] text-[12px] py-3 ">
                          <Tooltip title={data?.description}>
                            <h1 className="text-[#333333] font-[400] text-[12px] w-[140px]  text-ellipsis overflow-hidden whitespace-nowrap">
                              {data?.description}
                            </h1>
                          </Tooltip>
                        </td>
                        <td className="text-[#333333] font-[400] text-[12px] py-3 ">
                          {data?.surveys?.length > 1
                            ? `QID#${data?.surveys[0]?.surveyNumber}, ${
                                data?.surveys?.length - 1
                              } More`
                            : `QID#${data?.surveys[0]?.surveyNumber}`}
                        </td>
                        <td className="text-[#333333] font-[400] text-[12px] py-3 ">
                          {data?.isActive ? (
                            <div className="px-[12px] py-[4px] bg-[#EDFBF4] text-[#2FBB85] text-[10px] font-[500]  rounded-[11px] text-center">
                              Active
                            </div>
                          ) : (
                            <div className="px-[12px] py-[4px] bg-[#FFFAF1] rounded-[11px] text-[#CB8A14] text-[10px] font-[500] text-center">
                              Inactive
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="w-[60%] flex flex-col gap-[20px]">
            <div className="w-full dashboardShadow h-[360px]">
              <div className="mx-5 py-6  flex items-center justify-between">
                <h1 className="text-[#333] text-[16px] font-[500] inter">
                  List Of Questions
                </h1>
                <p
                  className="text-[#ACACAC] text-[14px] font-[400] cursor-pointer hover:text-primary hover:font-[500] hover:underline underline-offset-[3px] hover:transition-all duration-500 ease-linear decoration-2 overflow-hidden"
                  onClick={(e) => navigate("/qualification-library")}
                >
                  See all
                </p>
              </div>
              <div className=" mx-5 w-[95%] max-h-[280px]  overflow-y-auto containerScroll ">
                <table className=" w-full  ">
                  <thead className="">
                    <tr className=" border-b border-[#E0E0E0]">
                      {[
                        "Qualifications Name",
                        "Question",
                        "Qualification Type",
                        "Status",
                      ]?.map((data, ind) => (
                        <td
                          className="text-[#999999] pb-3 text-[12px] font-[500]"
                          key={ind}
                        >
                          {data}
                        </td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      ...myQuestion?.slice(0, 2),
                      ...questionLibrary?.slice(0, 3),
                    ]?.map((data) => (
                      <tr className="">
                        <td className="text-[#333333] font-[400] w-[200px] text-[12px] py-3 ">
                          <Tooltip title={data?.questionName || "-"}>
                            <p className="w-[85%]   overflow-hidden text-ellipsis  whitespace-nowrap">
                              {data?.questionName || "-"}
                            </p>
                          </Tooltip>
                        </td>
                        <td className="text-[#333333]  font-[400] w-[270px] text-[12px] py-3  ">
                          <Tooltip title={data?.questionText || "-"}>
                            <p className="w-[250px]  overflow-hidden text-ellipsis  whitespace-nowrap">
                              {data?.questionText || "-"}
                            </p>
                          </Tooltip>
                        </td>
                        <td className="text-[#333333] font-[400] text-[12px] py-3 ">
                          {data?.questionType || "-"}
                        </td>

                        <td className="text-[#333333] font-[400] text-[12px] py-3 capitalize">
                          {data?.questionStatus || "-"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
