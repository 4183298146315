export const countryToContinent = {
  Europe: [
    "Åland Islands",
    "Albania",
    "Andorra",
    "Armenia",
    "Austria",
    "Azerbaijan",
    "Belarus",
    "Belgium",
    "Bosnia and Herzegovina",
    "Bulgaria",
    "Croatia",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Estonia",
    "Faroe Islands",
    "Finland",
    "France",
    "Georgia",
    "Germany",
    "Gibraltar",
    "Greece",
    "Guernsey",
    "Hungary",
    "Iceland",
    "Ireland",
    "Isle of Man",
    "Italy",
    "Jersey",
    "Kazakhstan",
    "Kosovo",
    "Latvia",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Malta",
    "Moldova",
    "Monaco",
    "Montenegro",
    "Netherlands",
    "North Macedonia",
    "Norway",
    "Poland",
    "Portugal",
    "Romania",
    "Russia",
    "San Marino",
    "Serbia",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Svalbard and Jan Mayen",
    "Sweden",
    "Switzerland",
    "Turkey",
    "Ukraine",
    "United Kingdom",
    "Vatican City",
  ],
  NorthAmerica: [
    "Anguilla",
    "Antigua and Barbuda",
    "Bahamas",
    "Barbados",
    "Belize",
    "Bermuda",
    "Canada",
    "Cayman Islands",
    "Costa Rica",
    "Cuba",
    "Curaçao",
    "Dominica",
    "Dominican Republic",
    "El Salvador",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guatemala",
    "Haiti",
    "Honduras",
    "Jamaica",
    "Martinique",
    "Mexico",
    "Montserrat",
    "Nicaragua",
    "Panama",
    "Puerto Rico",
    "Saint Barthélemy",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (French part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Sint Maarten (Dutch part)",
    "Trinidad and Tobago",
    "Turks and Caicos Islands",
    "United States",
    "United States Minor Outlying Islands",
    "Virgin Islands (British)",
    "Virgin Islands (U.S.)",
  ],
  SouthAmerica: [
    "Argentina",
    "Bolivia",
    "Brazil",
    "Chile",
    "Colombia",
    "Ecuador",
    "Falkland Islands",
    "French Guiana",
    "Guyana",
    "Paraguay",
    "Peru",
    "Suriname",
    "Uruguay",
    "Venezuela",
  ],
  Africa: [
    "Algeria",
    "Angola",
    "Benin",
    "Botswana",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cameroon",
    "Central African Republic",
    "Chad",
    "Comoros",
    "Congo",
    "Democratic Republic of the Congo",
    "Djibouti",
    "Egypt",
    "Equatorial Guinea",
    "Eritrea",
    "Eswatini",
    "Ethiopia",
    "Gabon",
    "Gambia",
    "Ghana",
    "Guinea",
    "Guinea-Bissau",
    "Ivory Coast",
    "Kenya",
    "Lesotho",
    "Liberia",
    "Libya",
    "Madagascar",
    "Malawi",
    "Mali",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Morocco",
    "Mozambique",
    "Namibia",
    "Niger",
    "Nigeria",
    "Réunion",
    "Rwanda",
    "Saint Helena",
    "Sao Tome and Principe",
    "Senegal",
    "Seychelles",
    "Sierra Leone",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Sudan",
    "Tanzania",
    "Togo",
    "Tunisia",
    "Uganda",
    "Western Sahara",
    "Zambia",
    "Zimbabwe",
  ],
  MENA: [
    "Bahrain",
    "Iran",
    "Iraq",
    "Israel",
    "Jordan",
    "Kuwait",
    "Lebanon",
    "Oman",
    "Palestine",
    "Qatar",
    "Saudi Arabia",
    "Syria",
    "United Arab Emirates",
    "Yemen",
  ],
  APAC: [
    "Afghanistan",
    "American Samoa",
    "Australia",
    "Bangladesh",
    "Bhutan",
    "Brunei",
    "Cambodia",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Cook Islands",
    "Fiji",
    "French Polynesia",
    "Guam",
    "Heard Island and McDonald Islands",
    "Hong Kong",
    "India",
    "Indonesia",
    "Japan",
    "Kiribati",
    "North Korea",
    "South Korea",
    "Laos",
    "Macao",
    "Malaysia",
    "Maldives",
    "Marshall Islands",
    "Micronesia",
    "Mongolia",
    "Myanmar",
    "Nauru",
    "Nepal",
    "New Caledonia",
    "New Zealand",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Pakistan",
    "Palau",
    "Papua New Guinea",
    "Philippines",
    "Pitcairn",
    "Samoa",
    "Singapore",
    "Solomon Islands",
    "Sri Lanka",
    "Taiwan",
    "Tajikistan",
    "Thailand",
    "Timor-Leste",
    "Tokelau",
    "Tonga",
    "Turkmenistan",
    "Tuvalu",
    "Uzbekistan",
    "Vanuatu",
    "Vietnam",
    "Wallis and Futuna",
  ],
  Antarctica: [
    "Antarctica",
    "Bouvet Island",
    "French Southern Territories",
    "Heard Island and McDonald Islands",
    "South Georgia and the South Sandwich Islands",
  ],
};

export function getContinentByCountry(country) {
  for (const [continent, countries] of Object.entries(countryToContinent)) {
    if (countries.includes(country)) {
      return continent;
    }
  }
  return "Unknown";
}
const rateDataB2C = {
  Europe: {
    "0-5": {
      "100-90": "5.00",
      "89-80": "5.63",
      "79-71": "6.25",
      "70-61": "6.56",
      "60-51": "6.88",
      "50-41": "7.50",
      "40-31": "7.81",
      "30-21": "8.13",
      "20-16": "8.56",
      "15-11": "8.75",
      "10-5": "9.06",
    },
    "6-10": {
      "100-90": "5.63",
      "89-80": "6.25",
      "79-71": "6.56",
      "70-61": "6.88",
      "60-51": "7.31",
      "50-41": "7.50",
      "40-31": "7.81",
      "30-21": "8.13",
      "20-16": "8.56",
      "15-11": "6.88",
      "10-5": "8.13",
    },
    "11-15": {
      "100-90": "6.88",
      "89-80": "7.81",
      "79-71": "6.88",
      "70-61": "7.31",
      "60-51": "7.50",
      "50-41": "7.81",
      "40-31": "8.13",
      "30-21": "8.56",
      "20-16": "8.75",
      "15-11": "7.50",
      "10-5": "8.75",
    },
    "16-20": {
      "100-90": "8.13",
      "89-80": "7.50",
      "79-71": "7.50",
      "70-61": "7.50",
      "60-51": "7.81",
      "50-41": "8.13",
      "40-31": "8.56",
      "30-21": "8.75",
      "20-16": "9.06",
      "15-11": "8.75",
      "10-5": "10.00",
    },
    "21-25": {
      "100-90": "8.56",
      "89-80": "8.13",
      "79-71": "8.13",
      "70-61": "8.13",
      "60-51": "8.31",
      "50-41": "8.56",
      "40-31": "8.75",
      "30-21": "9.06",
      "20-16": "9.38",
      "15-11": "9.38",
      "10-5": "10.63",
    },
    "26-30": {
      "100-90": "8.75",
      "89-80": "10.63",
      "79-71": "9.81",
      "70-61": "8.75",
      "60-51": "8.75",
      "50-41": "9.38",
      "40-31": "9.81",
      "30-21": "9.81",
      "20-16": "9.38",
      "15-11": "10.63",
      "10-5": "11.88",
    },
  },
  NorthAmerica: {
    "0-5": {
      "100-90": "5.20",
      "89-80": "5.85",
      "79-71": "6.50",
      "70-61": "6.83",
      "60-51": "7.15",
      "50-41": "7.80",
      "40-31": "8.13",
      "30-21": "8.45",
      "20-16": "8.91",
      "15-11": "9.10",
      "10-5": "9.43",
    },
    "6-10": {
      "100-90": "5.85",
      "89-80": "6.50",
      "79-71": "6.83",
      "70-61": "7.15",
      "60-51": "7.61",
      "50-41": "7.80",
      "40-31": "8.13",
      "30-21": "8.45",
      "20-16": "8.91",
      "15-11": "7.15",
      "10-5": "8.45",
    },
    "11-15": {
      "100-90": "7.15",
      "89-80": "8.13",
      "79-71": "7.15",
      "70-61": "7.61",
      "60-51": "7.80",
      "50-41": "8.13",
      "40-31": "8.45",
      "30-21": "8.91",
      "20-16": "9.10",
      "15-11": "7.80",
      "10-5": "9.10",
    },
    "16-20": {
      "100-90": "8.45",
      "89-80": "7.80",
      "79-71": "7.80",
      "70-61": "7.80",
      "60-51": "8.13",
      "50-41": "8.45",
      "40-31": "8.91",
      "30-21": "9.10",
      "20-16": "9.43",
      "15-11": "9.10",
      "10-5": "10.40",
    },
    "21-25": {
      "100-90": "8.91",
      "89-80": "8.45",
      "79-71": "8.45",
      "70-61": "8.45",
      "60-51": "8.68",
      "50-41": "8.91",
      "40-31": "9.10",
      "30-21": "9.43",
      "20-16": "9.75",
      "15-11": "9.75",
      "10-5": "11.05",
    },
    "26-30": {
      "100-90": "9.10",
      "89-80": "11.05",
      "79-71": "10.21",
      "70-61": "9.10",
      "60-51": "9.10",
      "50-41": "9.75",
      "40-31": "10.21",
      "30-21": "10.21",
      "20-16": "9.75",
      "15-11": "11.05",
      "10-5": "12.35",
    },
  },
  SouthAmerica: {
    "0-5": {
      "100-90": "4.40",
      "89-80": "4.95",
      "79-71": "5.50",
      "70-61": "5.78",
      "60-51": "6.05",
      "50-41": "6.60",
      "40-31": "6.88",
      "30-21": "7.15",
      "20-16": "7.54",
      "15-11": "7.70",
      "10-5": "7.98",
    },
    "6-10": {
      "100-90": "4.95",
      "89-80": "5.50",
      "79-71": "5.78",
      "70-61": "6.05",
      "60-51": "6.44",
      "50-41": "6.60",
      "40-31": "6.88",
      "30-21": "7.15",
      "20-16": "7.54",
      "15-11": "6.05",
      "10-5": "7.15",
    },
    "11-15": {
      "100-90": "6.05",
      "89-80": "6.88",
      "79-71": "6.05",
      "70-61": "6.44",
      "60-51": "6.60",
      "50-41": "6.88",
      "40-31": "7.15",
      "30-21": "7.54",
      "20-16": "7.70",
      "15-11": "6.60",
      "10-5": "7.70",
    },
    "16-20": {
      "100-90": "7.15",
      "89-80": "6.60",
      "79-71": "6.60",
      "70-61": "6.60",
      "60-51": "6.88",
      "50-41": "7.15",
      "40-31": "7.54",
      "30-21": "7.70",
      "20-16": "7.98",
      "15-11": "7.70",
      "10-5": "8.80",
    },
    "21-25": {
      "100-90": "7.54",
      "89-80": "7.15",
      "79-71": "7.15",
      "70-61": "7.15",
      "60-51": "7.32",
      "50-41": "7.54",
      "40-31": "7.70",
      "30-21": "7.98",
      "20-16": "8.25",
      "15-11": "8.25",
      "10-5": "9.35",
    },
    "26-30": {
      "100-90": "7.70",
      "89-80": "9.35",
      "79-71": "8.64",
      "70-61": "7.70",
      "60-51": "7.70",
      "50-41": "8.25",
      "40-31": "8.64",
      "30-21": "8.64",
      "20-16": "8.25",
      "15-11": "9.35",
      "10-5": "10.45",
    },
  },
  Africa: {
    "0-5": {
      "100-90": "4.60",
      "89-80": "5.18",
      "79-71": "5.75",
      "70-61": "6.04",
      "60-51": "6.33",
      "50-41": "6.90",
      "40-31": "7.19",
      "30-21": "7.48",
      "20-16": "7.88",
      "15-11": "8.05",
      "10-5": "8.34",
    },
    "6-10": {
      "100-90": "5.18",
      "89-80": "5.75",
      "79-71": "6.04",
      "70-61": "6.33",
      "60-51": "6.73",
      "50-41": "6.90",
      "40-31": "7.19",
      "30-21": "7.48",
      "20-16": "7.88",
      "15-11": "6.33",
      "10-5": "7.48",
    },
    "11-15": {
      "100-90": "6.33",
      "89-80": "7.19",
      "79-71": "6.33",
      "70-61": "6.73",
      "60-51": "6.90",
      "50-41": "7.19",
      "40-31": "7.48",
      "30-21": "7.88",
      "20-16": "8.05",
      "15-11": "6.90",
      "10-5": "8.05",
    },
    "16-20": {
      "100-90": "7.48",
      "89-80": "6.90",
      "79-71": "6.90",
      "70-61": "6.90",
      "60-51": "7.19",
      "50-41": "7.48",
      "40-31": "7.88",
      "30-21": "8.05",
      "20-16": "8.34",
      "15-11": "8.05",
      "10-5": "9.20",
    },
    "21-25": {
      "100-90": "7.88",
      "89-80": "7.48",
      "79-71": "7.48",
      "70-61": "7.48",
      "60-51": "7.65",
      "50-41": "7.88",
      "40-31": "8.05",
      "30-21": "8.34",
      "20-16": "8.63",
      "15-11": "8.63",
      "10-5": "9.78",
    },
    "26-30": {
      "100-90": "8.05",
      "89-80": "9.78",
      "79-71": "9.03",
      "70-61": "8.05",
      "60-51": "8.05",
      "50-41": "8.63",
      "40-31": "9.03",
      "30-21": "9.03",
      "20-16": "8.63",
      "15-11": "9.78",
      "10-5": "10.93",
    },
  },
  MENA: {
    "0-5": {
      "100-90": "4.80",
      "89-80": "5.40",
      "79-71": "6.00",
      "70-61": "6.30",
      "60-51": "6.60",
      "50-41": "7.20",
      "40-31": "7.50",
      "30-21": "7.80",
      "20-16": "8.22",
      "15-11": "8.40",
      "10-5": "8.70",
    },
    "6-10": {
      "100-90": "5.40",
      "89-80": "6.00",
      "79-71": "6.30",
      "70-61": "6.60",
      "60-51": "7.02",
      "50-41": "7.20",
      "40-31": "7.50",
      "30-21": "7.80",
      "20-16": "8.22",
      "15-11": "6.60",
      "10-5": "7.80",
    },
    "11-15": {
      "100-90": "6.60",
      "89-80": "7.50",
      "79-71": "6.60",
      "70-61": "7.02",
      "60-51": "7.20",
      "50-41": "7.50",
      "40-31": "7.80",
      "30-21": "8.22",
      "20-16": "8.40",
      "15-11": "7.20",
      "10-5": "8.40",
    },
    "16-20": {
      "100-90": "7.80",
      "89-80": "7.20",
      "79-71": "7.20",
      "70-61": "7.20",
      "60-51": "7.50",
      "50-41": "7.80",
      "40-31": "8.22",
      "30-21": "8.40",
      "20-16": "8.70",
      "15-11": "8.40",
      "10-5": "9.60",
    },
    "21-25": {
      "100-90": "8.22",
      "89-80": "7.80",
      "79-71": "7.80",
      "70-61": "7.80",
      "60-51": "7.98",
      "50-41": "8.22",
      "40-31": "8.40",
      "30-21": "8.70",
      "20-16": "9.00",
      "15-11": "9.00",
      "10-5": "10.20",
    },
    "26-30": {
      "100-90": "8.40",
      "89-80": "10.20",
      "79-71": "9.43",
      "70-61": "8.40",
      "60-51": "8.40",
      "50-41": "9.00",
      "40-31": "9.43",
      "30-21": "9.43",
      "20-16": "9.00",
      "15-11": "10.20",
      "10-5": "11.40",
    },
  },
  APAC: {
    "0-5": {
      "100-90": "4.00",
      "89-80": "4.50",
      "79-71": "5.00",
      "70-61": "5.25",
      "60-51": "5.50",
      "50-41": "6.00",
      "40-31": "6.25",
      "30-21": "6.50",
      "20-16": "6.85",
      "15-11": "7.00",
      "10-5": "7.25",
    },
    "6-10": {
      "100-90": "4.50",
      "89-80": "5.00",
      "79-71": "5.25",
      "70-61": "5.50",
      "60-51": "5.85",
      "50-41": "6.00",
      "40-31": "6.25",
      "30-21": "6.50",
      "20-16": "6.85",
      "15-11": "5.50",
      "10-5": "6.50",
    },
    "11-15": {
      "100-90": "5.50",
      "89-80": "6.25",
      "79-71": "5.50",
      "70-61": "5.85",
      "60-51": "6.00",
      "50-41": "6.25",
      "40-31": "6.50",
      "30-21": "6.85",
      "20-16": "7.00",
      "15-11": "6.00",
      "10-5": "7.00",
    },
    "16-20": {
      "100-90": "6.50",
      "89-80": "6.00",
      "79-71": "6.00",
      "70-61": "6.00",
      "60-51": "6.25",
      "50-41": "6.50",
      "40-31": "6.85",
      "30-21": "7.00",
      "20-16": "7.25",
      "15-11": "7.00",
      "10-5": "8.00",
    },
    "21-25": {
      "100-90": "6.85",
      "89-80": "6.50",
      "79-71": "6.50",
      "70-61": "6.50",
      "60-51": "6.65",
      "50-41": "6.85",
      "40-31": "7.00",
      "30-21": "7.25",
      "20-16": "7.50",
      "15-11": "7.50",
      "10-5": "8.50",
    },
    "26-30": {
      "100-90": "7.00",
      "89-80": "8.50",
      "79-71": "7.85",
      "70-61": "7.00",
      "60-51": "7.00",
      "50-41": "7.50",
      "40-31": "7.85",
      "30-21": "7.85",
      "20-16": "7.50",
      "15-11": "8.50",
      "10-5": "9.50",
    },
  },
};
const rateDataB2B = {
  Europe: {
    "1-4": {
      "5-0": 13.5,
      "12-6": 12.75,
      "19-13": 12.75,
      "29-20": 12.0,
      "39-30": 12.0,
      "49-40": 11.25,
      "59-50": 11.25,
      "69-60": 10.5,
      "79-70": 10.5,
      "89-80": 9.75,
      "100-90": 9.75,
    },
    "5-10": {
      "5-0": 15.3,
      "12-6": 14.85,
      "19-13": 14.85,
      "29-20": 14.1,
      "39-30": 14.1,
      "49-40": 13.35,
      "59-50": 13.35,
      "69-60": 12.6,
      "79-70": 12.6,
      "89-80": 11.85,
      "100-90": 11.85,
    },
    "11-15": {
      "5-0": 16.53,
      "12-6": 16.03,
      "19-13": 16.03,
      "29-20": 15.3,
      "39-30": 15.3,
      "49-40": 14.63,
      "59-50": 14.63,
      "69-60": 13.88,
      "79-70": 13.88,
      "89-80": 13.13,
      "100-90": 13.13,
    },
    "16-20": {
      "5-0": 18.48,
      "12-6": 17.94,
      "19-13": 17.94,
      "29-20": 17.1,
      "39-30": 17.1,
      "49-40": 16.29,
      "59-50": 16.29,
      "69-60": 15.45,
      "79-70": 15.45,
      "89-80": 14.58,
      "100-90": 14.58,
    },
    "21-25": {
      "5-0": 24.53,
      "12-6": 23.8,
      "19-13": 23.8,
      "29-20": 22.95,
      "39-30": 22.95,
      "49-40": 22.05,
      "59-50": 22.05,
      "69-60": 20.93,
      "79-70": 20.93,
      "89-80": 19.95,
      "100-90": 19.95,
    },
    "26-30": {
      "5-0": 27.84,
      "12-6": 27.84,
      "19-13": 27.84,
      "29-20": 26.64,
      "39-30": 26.64,
      "49-40": 25.5,
      "59-50": 25.5,
      "69-60": 24.75,
      "79-70": 24.75,
      "89-80": 23.4,
      "100-90": 23.4,
    },
    "31-35": {
      "5-0": 33.68,
      "12-6": 33.06,
      "19-13": 33.06,
      "29-20": 32.4,
      "39-30": 32.4,
      "49-40": 31.5,
      "59-50": 31.5,
      "69-60": 29.7,
      "79-70": 29.7,
      "89-80": 28.8,
      "100-90": 28.8,
    },
  },
  NorthAmerica: {
    "1-4": {
      "5-0": 14.04,
      "12-6": 13.26,
      "19-13": 13.26,
      "29-20": 12.48,
      "39-30": 12.48,
      "49-40": 11.7,
      "59-50": 11.7,
      "69-60": 10.92,
      "79-70": 10.92,
      "89-80": 10.14,
      "100-90": 10.14,
    },
    "5-10": {
      "5-0": 15.96,
      "12-6": 15.66,
      "19-13": 15.66,
      "29-20": 14.88,
      "39-30": 14.88,
      "49-40": 14.1,
      "59-50": 14.1,
      "69-60": 13.32,
      "79-70": 13.32,
      "89-80": 12.54,
      "100-90": 12.54,
    },
    "11-15": {
      "5-0": 17.16,
      "12-6": 16.88,
      "19-13": 16.88,
      "29-20": 16.08,
      "39-30": 16.08,
      "49-40": 15.3,
      "59-50": 15.3,
      "69-60": 14.52,
      "79-70": 14.52,
      "89-80": 13.74,
      "100-90": 13.74,
    },
    "16-20": {
      "5-0": 19.2,
      "12-6": 18.9,
      "19-13": 18.9,
      "29-20": 17.82,
      "39-30": 17.82,
      "49-40": 16.74,
      "59-50": 16.74,
      "69-60": 15.66,
      "79-70": 15.66,
      "89-80": 14.58,
      "100-90": 14.58,
    },
    "21-25": {
      "5-0": 25.34,
      "12-6": 24.96,
      "19-13": 24.96,
      "29-20": 24.12,
      "39-30": 24.12,
      "49-40": 23.28,
      "59-50": 23.28,
      "69-60": 22.44,
      "79-70": 22.44,
      "89-80": 21.6,
      "100-90": 21.6,
    },
    "26-30": {
      "5-0": 28.8,
      "12-6": 28.8,
      "19-13": 28.8,
      "29-20": 27.72,
      "39-30": 27.72,
      "49-40": 26.64,
      "59-50": 26.64,
      "69-60": 25.56,
      "79-70": 25.56,
      "89-80": 24.48,
      "100-90": 24.48,
    },
    "31-35": {
      "5-0": 34.71,
      "12-6": 34.32,
      "19-13": 34.32,
      "29-20": 33.48,
      "39-30": 33.48,
      "49-40": 32.64,
      "59-50": 32.64,
      "69-60": 31.8,
      "79-70": 31.8,
      "89-80": 30.96,
      "100-90": 30.96,
    },
  },
  SouthAmerica: {
    "1-4": {
      "5-0": 10.8,
      "12-6": 10.2,
      "19-13": 10.2,
      "29-20": 9.6,
      "39-30": 9.6,
      "49-40": 9.0,
      "59-50": 9.0,
      "69-60": 8.4,
      "79-70": 8.4,
      "89-80": 7.8,
      "100-90": 7.8,
    },
    "5-10": {
      "5-0": 11.4,
      "12-6": 10.8,
      "19-13": 10.8,
      "29-20": 10.2,
      "39-30": 10.2,
      "49-40": 9.6,
      "59-50": 9.6,
      "69-60": 9.0,
      "79-70": 9.0,
      "89-80": 8.4,
      "100-90": 8.4,
    },
    "11-15": {
      "5-0": 13.2,
      "12-6": 12.6,
      "19-13": 12.6,
      "29-20": 12.0,
      "39-30": 12.0,
      "49-40": 11.4,
      "59-50": 11.4,
      "69-60": 10.8,
      "79-70": 10.8,
      "89-80": 10.2,
      "100-90": 10.2,
    },
    "16-20": {
      "5-0": 17.4,
      "12-6": 16.8,
      "19-13": 16.8,
      "29-20": 16.2,
      "39-30": 16.2,
      "49-40": 15.6,
      "59-50": 15.6,
      "69-60": 15.0,
      "79-70": 15.0,
      "89-80": 13.8,
      "100-90": 13.8,
    },
    "21-25": {
      "5-0": 19.2,
      "12-6": 19.2,
      "19-13": 19.2,
      "29-20": 18.0,
      "39-30": 18.0,
      "49-40": 16.8,
      "59-50": 16.8,
      "69-60": 15.6,
      "79-70": 15.6,
      "89-80": 14.4,
      "100-90": 14.4,
    },
    "26-30": {
      "5-0": 23.4,
      "12-6": 22.8,
      "19-13": 22.8,
      "29-20": 21.6,
      "39-30": 21.6,
      "49-40": 21.0,
      "59-50": 21.0,
      "69-60": 19.8,
      "79-70": 19.8,
      "89-80": 19.2,
      "100-90": 19.2,
    },
    "31-35": {
      "5-0": 26.4,
      "12-6": 25.2,
      "19-13": 25.2,
      "29-20": 24.0,
      "39-30": 24.0,
      "49-40": 22.8,
      "59-50": 22.8,
      "69-60": 21.6,
      "79-70": 21.6,
      "89-80": 21.0,
      "100-90": 21.0,
    },
  },
  Africa: {
    "1-4": {
      "5-0": 12.42,
      "12-6": 11.73,
      "19-13": 11.73,
      "29-20": 11.04,
      "39-30": 11.04,
      "49-40": 10.35,
      "59-50": 10.35,
      "69-60": 9.66,
      "79-70": 9.66,
      "89-80": 8.97,
      "100-90": 8.97,
    },
    "5-10": {
      "5-0": 13.11,
      "12-6": 12.42,
      "19-13": 12.42,
      "29-20": 11.73,
      "39-30": 11.73,
      "49-40": 11.04,
      "59-50": 11.04,
      "69-60": 10.35,
      "79-70": 10.35,
      "89-80": 9.66,
      "100-90": 9.66,
    },
    "11-15": {
      "5-0": 15.18,
      "12-6": 14.49,
      "19-13": 14.49,
      "29-20": 13.8,
      "39-30": 13.8,
      "49-40": 13.11,
      "59-50": 13.11,
      "69-60": 12.42,
      "79-70": 12.42,
      "89-80": 11.73,
      "100-90": 11.73,
    },
    "16-20": {
      "5-0": 19.99,
      "12-6": 19.29,
      "19-13": 19.29,
      "29-20": 18.6,
      "39-30": 18.6,
      "49-40": 17.9,
      "59-50": 17.9,
      "69-60": 17.21,
      "79-70": 17.21,
      "89-80": 15.81,
      "100-90": 15.81,
    },
    "21-25": {
      "5-0": 22.07,
      "12-6": 22.07,
      "19-13": 22.07,
      "29-20": 20.7,
      "39-30": 20.7,
      "49-40": 19.8,
      "59-50": 19.8,
      "69-60": 18.9,
      "79-70": 18.9,
      "89-80": 17.01,
      "100-90": 17.01,
    },
    "26-30": {
      "5-0": 26.9,
      "12-6": 26.2,
      "19-13": 26.2,
      "29-20": 24.5,
      "39-30": 24.5,
      "49-40": 23.8,
      "59-50": 23.8,
      "69-60": 23.1,
      "79-70": 23.1,
      "89-80": 22.4,
      "100-90": 22.4,
    },
    "31-35": {
      "5-0": 30.38,
      "12-6": 28.98,
      "19-13": 28.98,
      "29-20": 27.58,
      "39-30": 27.58,
      "49-40": 25.8,
      "59-50": 25.8,
      "69-60": 24.61,
      "79-70": 24.61,
      "89-80": 24.19,
      "100-90": 24.19,
    },
  },
  MENA: {
    "1-4": {
      "5-0": 13.14,
      "12-6": 12.45,
      "19-13": 12.45,
      "29-20": 11.76,
      "39-30": 11.76,
      "49-40": 11.07,
      "59-50": 11.07,
      "69-60": 10.38,
      "79-70": 10.38,
      "89-80": 9.69,
      "100-90": 9.69,
    },
    "5-10": {
      "5-0": 13.86,
      "12-6": 13.17,
      "19-13": 13.17,
      "29-20": 12.48,
      "39-30": 12.48,
      "49-40": 11.79,
      "59-50": 11.79,
      "69-60": 11.1,
      "79-70": 11.1,
      "89-80": 10.41,
      "100-90": 10.41,
    },
    "11-15": {
      "5-0": 16.26,
      "12-6": 15.57,
      "19-13": 15.57,
      "29-20": 14.88,
      "39-30": 14.88,
      "49-40": 14.19,
      "59-50": 14.19,
      "69-60": 13.5,
      "79-70": 13.5,
      "89-80": 12.81,
      "100-90": 12.81,
    },
    "16-20": {
      "5-0": 21.42,
      "12-6": 20.73,
      "19-13": 20.73,
      "29-20": 20.04,
      "39-30": 20.04,
      "49-40": 19.35,
      "59-50": 19.35,
      "69-60": 18.66,
      "79-70": 18.66,
      "89-80": 17.37,
      "100-90": 17.37,
    },
    "21-25": {
      "5-0": 23.64,
      "12-6": 23.64,
      "19-13": 23.64,
      "29-20": 22.77,
      "39-30": 22.77,
      "49-40": 21.9,
      "59-50": 21.9,
      "69-60": 21.03,
      "79-70": 21.03,
      "89-80": 20.16,
      "100-90": 20.16,
    },
    "26-30": {
      "5-0": 28.83,
      "12-6": 28.14,
      "19-13": 28.14,
      "29-20": 26.64,
      "39-30": 26.64,
      "49-40": 25.83,
      "59-50": 25.83,
      "69-60": 25.02,
      "79-70": 25.02,
      "89-80": 24.21,
      "100-90": 24.21,
    },
    "31-35": {
      "5-0": 32.59,
      "12-6": 31.19,
      "19-13": 31.19,
      "29-20": 29.79,
      "39-30": 29.79,
      "49-40": 27.99,
      "59-50": 27.99,
      "69-60": 26.79,
      "79-70": 26.79,
      "89-80": 25.49,
      "100-90": 25.49,
    },
  },
  APAC: {
    "1-4": {
      "5-0": 10.8,
      "12-6": 10.2,
      "19-13": 10.2,
      "29-20": 9.6,
      "39-30": 9.6,
      "49-40": 9.0,
      "59-50": 9.0,
      "69-60": 8.4,
      "79-70": 8.4,
      "89-80": 7.8,
      "100-90": 7.8,
    },
    "5-10": {
      "5-0": 11.4,
      "12-6": 10.8,
      "19-13": 10.8,
      "29-20": 10.2,
      "39-30": 10.2,
      "49-40": 9.6,
      "59-50": 9.6,
      "69-60": 9.0,
      "79-70": 9.0,
      "89-80": 8.4,
      "100-90": 8.4,
    },
    "11-15": {
      "5-0": 13.2,
      "12-6": 12.6,
      "19-13": 12.6,
      "29-20": 12.0,
      "39-30": 12.0,
      "49-40": 11.4,
      "59-50": 11.4,
      "69-60": 10.8,
      "79-70": 10.8,
      "89-80": 10.2,
      "100-90": 10.2,
    },
    "16-20": {
      "5-0": 17.4,
      "12-6": 16.8,
      "19-13": 16.8,
      "29-20": 16.2,
      "39-30": 16.2,
      "49-40": 15.6,
      "59-50": 15.6,
      "69-60": 15.0,
      "79-70": 15.0,
      "89-80": 13.8,
      "100-90": 13.8,
    },
    "21-25": {
      "5-0": 19.2,
      "12-6": 19.2,
      "19-13": 19.2,
      "29-20": 18.0,
      "39-30": 18.0,
      "49-40": 16.8,
      "59-50": 16.8,
      "69-60": 15.6,
      "79-70": 15.6,
      "89-80": 14.4,
      "100-90": 14.4,
    },
    "26-30": {
      "5-0": 23.4,
      "12-6": 22.8,
      "19-13": 22.8,
      "29-20": 21.6,
      "39-30": 21.6,
      "49-40": 21.0,
      "59-50": 21.0,
      "69-60": 19.8,
      "79-70": 19.8,
      "89-80": 19.2,
      "100-90": 19.2,
    },
    "31-35": {
      "5-0": 26.4,
      "12-6": 25.2,
      "19-13": 25.2,
      "29-20": 24.0,
      "39-30": 24.0,
      "49-40": 22.8,
      "59-50": 22.8,
      "69-60": 21.6,
      "79-70": 21.6,
      "89-80": 21.0,
      "100-90": 21.0,
    },
  },
};
const loiKeysB2C = ["26-30", "21-25", "16-20", "11-15", "6-10", "0-5"];
const incidenceKeysB2C = [
  "100-90",
  "89-80",
  "79-71",
  "70-61",
  "60-51",
  "50-41",
  "40-31",
  "30-21",
  "20-16",
  "15-11",
  "10-5",
];
const loiKeysB2B = ["31-35", "26-30", "21-25", "16-20", "11-15", "5-10", "1-4"];
const incidenceKeysB2B = [
  "100-90",
  "89-80",
  "79-70",
  "69-60",
  "59-50",
  "49-40",
  "39-30",
  "29-20",
  "19-13",
  "12-6",
  "5-0",
];

export function getValueByCountryRateAndLOI(
  country,
  incidenceRate,
  loi,
  TA = "B2C"
) {
  // console.log({ country, incidenceRate, loi, TA });

  if (!country) return `Missing Country`;
  if (!incidenceRate) return `Missing Expected Incidence Rate`;
  if (!loi) return `Missing Expected Completion LOI`;
  if (TA === "Healthcare") return `Contact support team for the best pricing`;

  const continent = getContinentByCountry(country);
  if (continent === "Unknown") return 0.0;

  const incidenceKeys = TA === "B2C" ? incidenceKeysB2C : incidenceKeysB2B;
  const loiKeys = TA === "B2C" ? loiKeysB2C : loiKeysB2B;
  const rateData = TA === "B2C" ? rateDataB2C : rateDataB2B;

  const incidenceKey =
    incidenceKeys.find((key) => {
      const [start, end] = key.split("-").map(Number);
      return incidenceRate >= end && incidenceRate <= start;
    }) || incidenceKeys[incidenceKeys.length - 1];

  const loiKey =
    loiKeys.find((key) => {
      const [start, end] = key.split("-").map(Number);
      return loi >= start && loi <= end;
    }) || loiKeys[loiKeys.length - 1];

  // console.log({ rateData, continent, loiKey, incidenceKey });

  let value = rateData[continent]?.[loiKey]?.[incidenceKey] || 0.0;
  return TA === "B2C"
    ? Number(value / 3)?.toFixed(2)
    : Number(value / 2)?.toFixed(2);
}

const miratsStatusDetails = {
  10: "Completed",
  20: "Terminated",
  40: "Quota Full",
  42: "Quota Full",
  41: "Quota Full",
  3: "Redirected to client",
  "-1": "Error",
  1: "Currently in Screener or Drop",
  0: "Did Not Answer a Question",
  38: " Fraud Profile",
  36: "Cookie Duplication",
  37: "GEO IP Mismatch",
  35: "RID Duplicate",
  23: "Qualification Failure",
  30: "Unique IP Failure",
  236: "No GDPR Consent",
};
const clientStatusDetails = {
  "-1": "Never Reached Client",
  1: "Currently in Client Survey or Drop",
  10: "Returned as Complete",
  20: "Returned as Terminate",
  30: "Returned as Quality Terminate",
  40: "Client Survey Quota Full",
};
export const getStatsDetail = (statusType, status) => {
  if (statusType == "mirats") {
    return miratsStatusDetails[status] || "Unknown";
  } else {
    return clientStatusDetails[status] || "Unknown";
  }
};
