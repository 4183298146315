import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { utils, writeFile } from "xlsx";
import { useGlobalContext } from "../../../../Contexts/GlobalContext";
import { BASE_URL } from "../../../../Utlils";

const TerminationData = ({ data }) => {
  const { id } = useParams();
  const { setSnackbar } = useGlobalContext();
  const [filters, setFilters] = useState({
    startDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 1,
      new Date().getDate()
    )?.toLocaleDateString("en-CA"),

    endDate: new Date().toLocaleDateString("en-CA"),
  });

  //DOWNLOAD TO EXCEL:
  const DownloadToExcel = () => {
    var elt = document.getElementById("table-to-xls-DATA");
    var wb = utils.table_to_book(elt, { sheet: "MiratsQuanto" });
    const worksheet = wb.Sheets[wb.SheetNames[0]];
    const jsonData = utils.sheet_to_json(worksheet);
    const fieldsToRemove = ["RID", "Supplier Account", "SRC ID"];
    const filteredData = jsonData.map((row) => {
      fieldsToRemove.forEach((field) => delete row[field]);
      return row;
    });
    const newWorksheet = utils.json_to_sheet(filteredData);
    const newWorkbook = utils.book_new();
    utils.book_append_sheet(newWorkbook, newWorksheet, "MiratsQuanto");
    writeFile(
      newWorkbook,
      `Mirats Quanto - SN#${data?.surveyNumber} - Termination Data.xlsx`
    );
    setSnackbar({
      open: true,
      severity: "success",
      msg: "Excel Sheet Downloaded!",
    });
  };

  //GET TERMINATED SESSIONS:
  const [terminatedSessions, setTerminatedSessions] = useState([]);
  const getTerminatedSessions = async (id, start, end) => {
    try {
      const sessionData = await axios.get(
        `${BASE_URL}/session/terminated-sessions?surveyID=${id}&startDate=${start}&endDate=${end}`
      );
      // console.log(sessionData?.data);
      setTerminatedSessions(sessionData?.data);
    } catch (error) {
      console.log(error, error.message);
    }
  };

  useEffect(() => {
    getTerminatedSessions(id, filters?.startDate, filters?.endDate);
  }, [id]);

  // console.log(data);

  // console.log(terminatedSessions);

  return (
    <div className="w-full flex flex-col gap-[30px] py-6">
      <div className="w-full flex items-center justify-between ">
        <div className="flex flex-col gap-[26px]">
          <h1 className="text-[#000] text-[18px] font-[500] inter">
            Field Date
          </h1>
          <div className="flex items-center gap-[28px]">
            <input
              type="date"
              className="border h-[40px] px-4 rounded-[8px] text-[#666] text-[14px]"
              value={filters?.startDate}
              onChange={(e) => {
                setFilters((prev) => ({ ...prev, startDate: e.target.value }));
              }}
            />
            <p className="text-[#333] text-[16px] font-[400] inter">To</p>
            <input
              type="date"
              className="border h-[40px] px-4 rounded-[8px] text-[#666] text-[14px]"
              value={filters?.endDate}
              onChange={(e) => {
                setFilters((prev) => ({ ...prev, endDate: e.target.value }));
              }}
            />
            <button
              className="border border-[#0226BE] text-[#0226BE] py-2 px-3 text-[13.3px] inter font-[500] rounded-[8px]  cursor-pointer"
              onClick={(e) =>
                getTerminatedSessions(id, filters?.startDate, filters?.endDate)
              }
            >
              Search
            </button>
            <p
              className="text-[14px] underline underline-offset-4 cursor-pointer"
              onClick={(e) => {
                const start = new Date(
                  new Date().getFullYear(),
                  new Date().getMonth() - 1,
                  new Date().getDate()
                )?.toLocaleDateString("en-CA");
                const end = new Date().toLocaleDateString("en-CA");
                setFilters({
                  startDate: start,

                  endDate: end,
                });
                getTerminatedSessions(id, start, end);
              }}
            >
              Clear filters
            </p>
          </div>
        </div>
        <button
          className="bg-[#0226BE] text-[#fff] py-2 px-3 text-[13.3px] inter font-[500] rounded-[8px] btnShadow"
          onClick={(e) => DownloadToExcel()}
        >
          Export To Excel
        </button>
      </div>
      {/* table */}
      <div>
        <div className="w-full tableBorder flex flex-col">
          <div className="w-full overflow-x-auto containerScroll">
            <table
              className="whitespace-nowrap text-left w-full"
              id="table-to-xls-DATA"
            >
              <thead className=" h-[45px] ">
                <tr className="py-3 ">
                  {[
                    "Ref ID",
                    "Survey No.",
                    "Project No.",
                    "Client Status",
                    "Mirats Status",

                    "Date",
                  ]?.map((d) => (
                    <td className="text-[#666] text-[14px] font-[600] pl-4 text-center">
                      {d}
                    </td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {terminatedSessions?.map((da) => (
                  <tr className=" tableBorder transition-all cursor-pointer hover:bg-[#f6f9ff] ">
                    <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] text-center">
                      {da?.ref_id}
                    </td>
                    <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] text-center">
                      {data?.surveyNumber}
                    </td>
                    <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] text-center">
                      {data?.projectNumber}
                    </td>
                    <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] text-center">
                      {da?.client_status}
                    </td>
                    <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] text-center">
                      {da?.mirats_status}
                    </td>
                    <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] text-center">
                      {new Date(da?.date)?.toLocaleDateString("en-CA", {
                        month: "2-digit",
                        year: "numeric",
                        day: "2-digit",
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TerminationData;
