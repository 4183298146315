import React from "react";
import PaymentNavbar from "./comp/PaymentNavbar";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import { useNavigate } from "react-router-dom";

const Payment = () => {
  const { user, allInvoices, organization } = useGlobalContext();
  //   console.log(allInvoices);
  const navigate = useNavigate();
  return (
    <div>
      <PaymentNavbar />
      <div className="flex items-center justify-center space-y-3 py-8">
        <div className="w-[92%]  space-y-3">
          <h1 className="text-[28px] font-[#333] font-[600]">
            {user?.organizationName}
          </h1>
          <div className=" grid grid-cols-2 py-4 gap-[20px]">
            <div className="border rounded-lg px-3 py-4 ">
              <div className="flex items-center justify-between text-[22px] font-[600]">
                <h1 className="">Invoices</h1>
                <h2>
                  $
                  {allInvoices
                    ?.filter((d) => d?.paymentStats == "unpaid")
                    ?.map((d) =>
                      d?.invoiceTable?.reduce(
                        (acc, cur) => (acc += cur?.totalRevenue),
                        0
                      )
                    )
                    ?.reduce((acc, curr) => acc + curr, 0)}
                </h2>
              </div>
              <div className="py-5 max-h-[60vh] overflow-y-auto containerScroll space-y-3 ">
                {allInvoices
                  ?.filter((d) => d?.paymentStats == "unpaid")
                  ?.map((d, i) => (
                    <div
                      className="px-4 py-5 grid grid-cols-4 border rounded-xl bg-[#d9d9d9]"
                      key={i}
                    >
                      <h1 className="font-[600]">{d?.invoiceNumber}</h1>
                      <p>{new Date(d?.createdAt)?.toLocaleDateString()}</p>
                      <p>
                        $
                        {d?.invoiceTable?.reduce(
                          (acc, cur) => (acc += cur?.totalRevenue),
                          0
                        )}
                      </p>
                      <p className="capitalize">{d?.paymentStats}</p>
                    </div>
                  ))}
              </div>
            </div>
            <div className="border rounded-lg px-3 py-4 ">
              <h1 className="text-[22px] font-[600]">Billing Details</h1>
              <div className="py-3 space-y-1">
                <h1 className="font-[500]">{user?.organizationName}</h1>
                <p> 
                    {organization?.addresses?.billingAddress?.street1 || ""}{" "}
                    {organization?.addresses?.billingAddress?.street2 || ""}{" "} 
                    {organization?.addresses?.billingAddress?.state || ""}, {organization?.addresses?.billingAddress?.country || ""}
                </p>
              </div>
              <div>
                <p>{organization?.finance?.taxIDType || ""}: {organization?.finance?.taxID || ""}</p>
                <p>Currency - USD </p>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end">
            <button
              onClick={(e) => navigate("/payment/account")}
              className="bg-[#0226BE] py-2 px-4 text-[#fff] font-[500] rounded-lg text-[14px]"
            >
              {" "}
              Proceed To Pay
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
