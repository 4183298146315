import React from "react";

const NoData = () => {
  return (
    <div className="w-full text-center py-6 text-[#666] font-[500] text-[18px]">
      No Data
    </div>
  );
};

export default NoData;
