import React from "react";
import Skeleton from "./Skeleton";
import { hideNav } from "../Utlils";

const GlobalSkeleton = () => {
  return (
    <div
      className={`flex flex-col items-center ${
        hideNav?.some((data) => window.location.href?.includes(data))
          ? "hidden"
          : ""
      }`}
    >
      <div className="w-full flex items-center justify-center border h-[70px]">
        <div className="w-[95%]  flex justify-between items-center">
          <Skeleton className="w-[150px] h-[40px]" />
          <div className="flex items-center gap-[20px]">
            {[1, 2, 3, 4, 5]?.map((d, i) => (
              <Skeleton className="w-[100px] h-[25px]" />
            ))}
          </div>
          <div className="flex items-center justify-between gap-4">
            <div className="flex flex-col items-end gap-[5px]">
              <Skeleton className="w-[100px] h-[25px]" />
              <Skeleton className="w-[180px] h-[18px]" />
            </div>
            <Skeleton className="w-[55px] h-[55px] rounded-full" />
          </div>
        </div>
      </div>

      <div className="w-[95%] flex flex-col gap-[35px] py-6">
        <Skeleton className="w-[200px] h-[40px]" />
        <div className="">
          <Skeleton className="w-[200px] h-[20px]" />
        </div>
        <div className="grid grid-cols-3 items-center gap-[20px] justify-between">
          <div className="border min-h-[350px] col-span-2 rounded-[25px] shadow-sm p-5 space-y-4">
            <div className="flex items-center justify-between">
              <Skeleton className="w-[170px] h-[25px]" />
              <div className="flex items-center justify-between gap-[20px]">
                <Skeleton className="w-[100px] h-[25px]" />
                <Skeleton className="w-[100px] h-[25px]" />
              </div>
            </div>
            <div className="flex items-end gap-[10px] h-[260px] justify-between">
              {[
                13, 42, 33, 54, 25, 212, 12, 51, 31, 2, 23, 46, 56, 27, 11,
              ]?.map((d, i) => (
                <Skeleton
                  key={i}
                  style={{
                    height: `${String(
                      Math.floor(Math.random() * (99 - 60 + 1)) + 60
                    )}%`,
                  }}
                  className={`w-[30px] `}
                />
              ))}
            </div>
          </div>
          <div className="border min-h-[350px]  rounded-[25px] shadow-sm p-5 space-y-4">
            <div className="flex items-center justify-between">
              <Skeleton className="w-[170px] h-[25px]" />
              <div className="flex items-center justify-between gap-[20px]">
                <Skeleton className="w-[100px] h-[25px]" />
              </div>
            </div>
            <div className="flex items-center justify-center pt-4">
              <div className="bg-gray-200 w-[250px] h-[250px] rounded-full flex items-center justify-center animate animate-pulse">
                <div className="w-[150px] h-[150px] rounded-full bg-white"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-5 items-center gap-[20px] justify-between">
          <div className="border min-h-[300px] col-span-3 rounded-[25px] shadow-sm p-5 space-y-8">
            <div className="flex items-center justify-between">
              <Skeleton className="w-[170px] h-[25px]" />
              <Skeleton className="w-[100px] h-[25px]" />
            </div>
            <div className="space-y-8">
              {[1, 2, 3, 4, 5]?.map((op) => (
                <div className="flex items-center justify-between">
                  {[1, 2, 3, 4, 5, 6]?.map((d, i) => (
                    <Skeleton className="w-[100px] h-[20px]" />
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div className="border min-h-[300px] col-span-2 rounded-[25px] shadow-sm p-5 space-y-8">
            <div className="flex items-center justify-between">
              <Skeleton className="w-[170px] h-[25px]" />
              <Skeleton className="w-[100px] h-[25px]" />
            </div>
            <div className="space-y-8">
              {[1, 2, 3, 4, 5]?.map((op) => (
                <div className="flex items-center justify-between">
                  {[1, 2, 3, 4]?.map((d, i) => (
                    <Skeleton className="w-[100px] h-[20px]" />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalSkeleton;
