import React, { useState } from "react";
import MiratsLogo2 from "../../assets/MiratsLogo2.svg";
import DashboardImage from "../../assets/DashboardImage.svg";
import axios from "axios";
import { BASE_URL } from "../../Utlils";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../Contexts/GlobalContext";

const ResetPassword = () => {
  const { setSnackbar } = useGlobalContext();
  const [passwordData, setPasswordData] = useState({});
  const urlSearchParams = new URLSearchParams(window.location.search);
  const queryParams = Object.fromEntries(urlSearchParams.entries());
  const navigate = useNavigate();

  const SubmitResetPassword = async (e) => {
    e.preventDefault();
    try {
      if (passwordData?.password == passwordData?.confirmPassword) {
        await axios
          .post(`${BASE_URL}/reset-password`, {
            password: passwordData?.password,
            id: queryParams?.id,
            token: queryParams?.token,
          })
          .then((res) => {
            setSnackbar({
              open: true,
              severity: "success",
              msg: "Password Changed Successfully!",
            });
            navigate(`/login`);
          });
      } else {
        setSnackbar({
          open: true,
          severity: "error",
          msg: "New password and confirm password don't match!",
        });
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        open: true,
        msg: `${error?.response.data?.error}`,
        severity: "error",
      });
    }
  };

  return (
    <div className="w-full  h-screen flex ">
      <div className="w-[45%]   flex items-center justify-center ">
        <form
          onSubmit={SubmitResetPassword}
          className="w-[90%]  flex flex-col items-center  gap-[25px] pl-2"
        >
          <img src={MiratsLogo2} alt="CompanyLogo" className=" " />
          <div className="w-full flex flex-col gap-[25px] ">
            <div>
              <h1 className="text-[#333] text-[38px] font-[600] inter">
                Reset Password
              </h1>
            </div>
            <div className="flex flex-col gap-[14px]">
              <h2 className="text-[#333] text-[14px] font-[600]">Password</h2>
              <input
                type="text"
                name="password"
                required
                onChange={(e) => {
                  setPasswordData((prev) => ({
                    ...prev,
                    password: e.target.value,
                  }));
                }}
                className="pl-2 border border-[#E2E2E5] w-full h-[40px] text-[14px]  focus:outline-[#325FFF] focus:text-[#325FFF]"
                placeholder="Enter Email id"
              />
            </div>
            <div className="flex flex-col gap-[14px]">
              <h2 className="text-[#333] text-[14px] font-[600]">
                Confirm Password
              </h2>
              <input
                type="password"
                name="password"
                onChange={(e) => {
                  setPasswordData((prev) => ({
                    ...prev,
                    confirmPassword: e.target.value,
                  }));
                }}
                className="pl-2 border border-[#E2E2E5] w-full h-[40px] text-[14px]  focus:outline-[#325FFF] focus:text-[#325FFF]"
                placeholder="Enter Password"
                autoComplete="off"
              />
            </div>
            <button
              className="bg-[#0F0F0F] text-white font-[700] text-[14px] py-3"
              type="submit"
            >
              Reset Password
            </button>
          </div>
        </form>
      </div>
      <div className="w-[55%]  signupBg h-full flex items-center justify-center ">
        <div className="w-[85%] h-[92%]  flex flex-col gap-[20px] pl-2">
          <h1 className="text-[44px] text-[#fff] font-[600] leading-[1.35]">
            The simplest way to handle your survey
          </h1>
          <p className="text-[16px] text-[#fff] font-[400] ">
            Enter your credentials to access your account.
          </p>
          <div className="w-full flex items-center justify-center mt-[35px]">
            <img src={DashboardImage} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
