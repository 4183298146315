import React from "react";

const Skeleton = ({ className, style }) => {
  return (
    <div
      style={style}
      className={`bg-gray-200 animate-pulse  min-h-1 min-w-1  ${className}`}
    />
  );
};

export default Skeleton;
