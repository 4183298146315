import React from "react";
import MiratsLogo from "../assets/MiratsLogo.svg";
import { NavLink, useNavigate } from "react-router-dom";
import dummyUserProfilePicture from "../assets/dummy.png";
import { useGlobalContext } from "../Contexts/GlobalContext";
const Navbar = () => {
  const navigate = useNavigate();
  const { user } = useGlobalContext();
  const navData = [
    { name: "Dashboard", path: "/" },
    { name: "Surveys", path: "/surveys" },
    { name: "Survey Groups", path: "/survey-groups" },
    { name: "Invoices & Billing", path: "/invoice-billing" },
    { name: "Feasibility", path: "/feasibility" },
    { name: "Qualification Library", path: "/qualification-library" },
  ];
  const hideNav = [
    "sign-up",
    "login",
    "DIY",
    "error",
    "verify-change",
    "reset-password",
    "7e08091a73b14e034889265e41ba796f91c766ad",
    "21df0c3543ff3bd23cecdaf921ff4a704a113d06dc1ec21045a186ad0f2f9a42",
    "payment",
    "terms-and-condition",
    "privacy-policy",
    "refund-policy",
    "onboarding",
    "e-invoice",
    "reconciliation-verification",
  ];
  // console.log(hideNav?.some((data) => window.location.href?.includes(data)));
  // console.log(window.location.pathname);
  return (
    <nav
      className={`w-full  print:hidden  navShadow flex z-10 items-center justify-center h-[64px] sticky top-0 ${
        hideNav?.some((data) => window.location.href?.includes(data))
          ? "hidden"
          : ""
      }`}
    >
      <div className="w-[92%]  flex items-center justify-between">
        <img
          src={MiratsLogo}
          alt="Logo"
          className=" w-[180px] h-[50px] object-contain"
        />
        <div className="flex items-center gap-[34px]">
          {navData?.map((data, ind) => (
            <h1
              className="inter text-[14px] font-[400] text-[##353935]"
              key={ind}
            >
              <NavLink
                to={data?.path}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <span
                  className={({ isActive }) => (isActive ? "nav_name" : "")}
                >
                  {data?.name}
                </span>
              </NavLink>
            </h1>
          ))}
        </div>
        <div
          className=" flex items-center gap-[15px] cursor-pointer"
          onClick={(e) => navigate("/profile/my-profile")}
        >
          <div className="flex flex-col items-end">
            <h1 className="text-[16px] text-end font-[500] inter text-[#000] w-[200px] overflow-hidden whitespace-nowrap text-ellipsis">
              {user?.personalDetails?.fullName}
            </h1>
            <p className="text-[12px] inter font-[400]">{user?.email}</p>
          </div>
          <img
            src={user?.avatar ? user?.avatar : dummyUserProfilePicture}
            alt="user"
            className="w-[52px] h-[52px] rounded-[50%]"
          />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
